import { isIOS } from "react-device-detect";

import { PkApiNet } from "../api/net";
import { Pk } from "../index";
import { PkStore } from "../redux/store";
import { PkStorage } from "../storage";
import { PkReduxActions } from "../redux/actions";
import { PkCommons } from "../commons";
import { PkApiContacts, AVAILABILITY } from "../api/contacts";
import { PkCordova } from "../cordova";
import { PkLog } from "../log";
import { PkApiAppCompany } from "../api/appCompany";
import { PkApiRooms } from "../api/rooms/rooms";
import { PkApiAddressBook } from "../api/addressbook";

export class PkApiAuth {
  static getToken = () => {
    let token = null;
    try {
      token = PkStorage.getToken();
      if (token) {
        return JSON.parse(token);
      }
    } catch { }
    return null;
  };

  static isAuthenticated = () => {
    if (
      PkApiAuth.getToken() &&
      PkStorage.getProfile() &&
      PkStorage.getPrivateKey() &&
      PkStore.getState().loggedIn === true
    )
      return true;
    else return false;
  };

  static doLogout = () => {
    return new Promise((resolve, reject) => {
      PkApiContacts.doLogout()
        .then(function (result) { })
        .catch(function () { })
        .finally(function () {
          PkStorage.doLogout();
          PkStore.get().dispatch(PkReduxActions.setUserInfo(null));
          resolve();
        });
    });
  };

  static doLogin = (email, password, deviceId, customer, deviceModel) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("customCode", customer.Code);
      bodyFormData.set("email", email); // massimiliano.marsiglietti@pikkart.com
      bodyFormData.set("password", password); // 420752
      bodyFormData.set("deviceId", deviceId); // TODO implementare riconoscimento DUID
      bodyFormData.set(
        "appOs",
        PkCordova.isCordova() ? (isIOS ? "ios" : "android") : "web"
      );
      bodyFormData.set("model", deviceModel);

      PkApiNet.callApi({
        method: "post",
        url: Pk.getConfig().apiUrl + "/services/auth?token",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            const token = response.data.Token;
            const pk = response.data.PrivateKey;

            PkApiAuth.saveAuthInfo(token, pk, customer)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                PkApiAuth.doLogout().then(() => {
                  resolve(response);
                });
              });
          } else resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static registerFromInvitation = (
    email,
    invitationCode,
    deviceId,
    appOS,
    deviceModel
  ) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);
      bodyFormData.set("invitationCode", invitationCode);
      bodyFormData.set("deviceId", deviceId);
      bodyFormData.set("appOS", appOS);
      bodyFormData.set("model", deviceModel);

      PkApiNet.callApi({
        method: "post",
        url: Pk.getConfig().apiUrl + "/services/auth?register",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            const token = response.data.LoginResult.LoginData.Token;
            const pk = response.data.LoginResult.LoginData.PrivateKey;

            PkApiAuth.saveAuthInfo(token, pk, response.data.Customer)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                PkApiAuth.doLogout().then(() => {
                  resolve(response);
                });
              });
          } else resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static saveAuthInfo = (token, privateKey, customer) => {
    return new Promise((resolve, reject) => {
      PkStorage.setToken(JSON.stringify(token));
      if (!privateKey) {
        PkLog.error("privateKey not received");
      }
      PkStorage.setPrivateKey(privateKey);
      PkStorage.setLoginCompany(customer);

      PkApiAuth.getProfile()
        .then((response) => {
          if (PkApiAuth.isAuthenticated()) {
            PkApiAuth.loadAllUserData()
              .then(function (result) { })
              .catch(function (errorMessage) {
                PkLog.error(errorMessage);
              })
              .finally(() => {
                if (!Pk.getConfig().callsEnabled) {
                  PkApiContacts.updateAvailability(AVAILABILITY.OFFLINE)
                    .then()
                    .catch(function (errorMessage) {
                      PkLog.error(errorMessage);
                    })
                    .finally(() => {
                      resolve(response);
                    });
                } else {
                  resolve(response);
                }
              });
          } else {
            //TODO: stringa di errore
            reject(new Error("[saveAuthInfo] your not authenticated"));
          }
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  //TODO: da testare
  static changeLoginCustomer = (deviceId, customer, deviceModel) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("deviceId", deviceId);
      bodyFormData.set("customerCode", customer.Code);
      bodyFormData.set("appOS",
        PkCordova.isCordova() ? (isIOS ? "ios" : "android") : "web"
      );
      bodyFormData.set("model", deviceModel);

      PkApiNet.callApi({
        method: "post",
        url: Pk.getConfig().apiUrl + "/services/auth?changeCustomer",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then((response) => {
          if (response.result.success === true) {
            const token = response.data.Token;
            const pk = response.data.PrivateKey;

            PkStorage.doLogout();

            PkApiAuth.saveAuthInfo(token, pk, customer)
              .then((response) => {
                resolve(response);
              })
              .catch((errorMessage) => {
                //TODO: verificare i vari codici di risposta
                reject(new Error(errorMessage));
                /* PkApiAuth.doLogout().then(() => {
                                 resolve(response);
                             });*/
              });
          } else {
            reject(new Error(response.result.message));
          }
        })
        .catch((errorMessage) => {
          reject(new Error(errorMessage));
        });
    });
  };

  static _executingRefreshToken = false;
  static refreshToken = () => {
    return new Promise((resolve, reject) => {
      const finish = (success) => {
        PkApiAuth._executingRefreshToken = false;
        resolve(success);
      };

      console.log(
        "executingRefreshToken value: ",
        PkApiAuth._executingRefreshToken
      );

      if (!PkApiAuth._executingRefreshToken) {
        PkApiAuth._executingRefreshToken = true;
        let token = PkApiAuth.getToken();
        if (!token) {
          finish(false);
        }
        const bodyFormData = new FormData();
        bodyFormData.set("refreshToken", token.RefreshToken);
        bodyFormData.set("deviceId", token.DeviceId);

        PkApiNet.callApi({
          method: "post",
          url: Pk.getConfig().apiUrl + "/services/auth?refreshToken",
          data: bodyFormData,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
          .then(function (response) {
            if (response.result.success === true) {
              let token = response.data;
              PkStorage.setToken(JSON.stringify(token));

              PkApiAuth.getProfile()
                .then()
                .catch(function (errorMessage) {
                  PkLog.error(errorMessage);
                })
                .finally(() => {
                  if (PkApiAuth.isAuthenticated()) {
                    PkApiAuth.loadAllUserData()
                      .then()
                      .catch(function (errorMessage) {
                        PkLog.error(errorMessage);
                      })
                      .finally(() => {
                        finish(true);
                      });
                  } else {
                    finish(true);
                  }
                });
            } else {
              PkLog.error(response.result.message);
              finish(false);
            }
          })
          .catch(function (errorMessage) {
            PkLog.error(errorMessage);
            finish(false);
          });
      } else {
        PkCommons.waitUntil(
          () => {
            console.log("waiting... executingRefreshToken");
            return PkApiAuth._executingRefreshToken === false;
          },
          50,
          10
        )
          .then(() => {
            console.log("executingRefreshToken finished");
            const token = PkStorage.getToken();
            if (token) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((errorMsg) => {
            reject(errorMsg);
          });
      }
    });
  };

  static _loadingAllUserData = false;
  static loadAllUserData = () => {
    let result = { appCompany: false, rooms: false, addressBook: false };
    return new Promise((resolve, reject) => {
      if (PkApiAuth._loadingAllUserData === false) {
        PkApiAuth._loadingAllUserData = true;

        //dopo la prima chiamata l'utente potrebbe risultare non loggato (token salvato non più valido),
        //quindi prima di proseguire controllo la sua autenticazione
        if (PkApiAuth.isAuthenticated()) {
          //aggiorno i dati della società che esegue assistenza
          PkApiAppCompany.getAppCompany()
            .then(function (response) {
              if (response.result.success === false) {
                PkLog.error(
                  "[loadAllUserData]getAppCompany: " + response.result.message
                );
              } else {
                result.appCompany = true;
              }
            })
            .catch(function (response) {
              PkLog.error(
                "[loadAllUserData]getAppCompany: " + response.message
              );
            })
            .then(() => {
              //aggiorno le chat
              PkApiAddressBook.getAddressBook()
                .then(function (response) {
                  if (response.result.success === false) {
                    PkLog.error(
                      "[loadAllUserData]getAddressBook: " +
                      response.result.message
                    );
                  } else {
                    result.addressBook = true;
                  }
                })
                .catch(function (response) {
                  PkLog.error(
                    "[loadAllUserData]getAddressBook: " + response.message
                  );
                })
                .then(() => {
                  //aggiorno la rubrica
                  PkApiRooms.getContactRooms()
                    .then(function (response) {
                      if (response.result.success === false) {
                        PkLog.error(
                          "[loadAllUserData]getContactRooms: " +
                          response.result.message
                        );
                      } else {
                        result.rooms = true;
                      }
                    })
                    .catch(function (response) {
                      PkLog.error(
                        "[loadAllUserData]getContactRooms: " + response.message
                      );
                    })
                    .then(() => {
                      PkApiAuth._loadingAllUserData = false;
                      resolve(result);
                    });
                });
            });
        } else {
          PkApiAuth._loadingAllUserData = false;
          resolve(result);
        }
      }
    });
  };

  static getProfile = () => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url: Pk.getConfig().apiUrl + "/services/contacts?profile",
      })
        .then(function (response) {
          if (response.result.success === true) {
            PkStorage.setProfile(response.data);
            PkStore.get().dispatch(PkReduxActions.setUserInfo(response.data));
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          //handle error
          reject(new Error(errorMessage));
        });
    });
  };

  static sendNewCode = (email, customerCode, deviceId) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);
      bodyFormData.set("customCode", customerCode);
      if (deviceId) bodyFormData.set("deviceId", deviceId);

      PkApiNet.callApi({
        method: "post",
        url: Pk.getConfig().apiUrl + "/services/auth?confirmCode",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static checkEmail = (email, deviceId) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);
      if (deviceId) bodyFormData.set("deviceId", deviceId); // TODO implementare riconoscimento DUID
      bodyFormData.set("appCode", Pk.getConfig().appCode);
      if (Pk.getConfig().customerCode) {
        bodyFormData.set("companyCode", Pk.getConfig().customerCode);
      }

      PkApiNet.callApi({
        method: "post",
        url: Pk.getConfig().apiUrl + "/services/auth?checkEmail",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static serviceAvailable = () => {
    let profile = PkStorage.getProfile();
    if (profile) {
      if (
        !profile.PaidService ||
        (profile.PurchasedMinutes - profile.ServiceUsageMinutes > 0 &&
          (!profile.ExpirationDate ||
            new Date(profile.ExpirationDate) - new Date() > 0))
      ) {
        return true;
      }
    }
    return false;
  };

  static allowedToCall = () => {
    let profile = PkStorage.getProfile();
    return Pk.getConfig().callsEnabled && profile && profile.Role.AllowedToCall;
  };
}

export default PkApiAuth;
