import React from 'react';
import { connect } from 'react-redux';
import { f7, Link, Block, Button, Popup, View, Page, Navbar, NavRight, NavLeft } from 'framework7-react';
import MapView from '../content-elements/map-view';
import ContactList from '../content-elements/contact-list';
import ImageGallery from '../content-elements/image-gallery';
import localeStrings from './ar-scene-view-local';
import * as actions from '../../redux/actions';
import { PkBabylon } from 'pikkart-cordova';
import { PkLog } from '../../pikkart-cms/log';
import AnalyticsRegister, { ANALYTICS_EVENT } from '../../analytics-register';
import TutorialHelper from '../../helpers/tutorial-helper';
import { isMobile } from 'react-device-detect';
import { AR_TYPE } from '../../utils/AR_TYPE';

class ArSceneView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTutorial: true,
        };
    }

    render() {

        const content = this.props.content;

        let arSceneButton = <Button
            className='start-content-experience-button'
            large
            fill
            popupOpen={".popup-tutorial-ar-scene" + content.id}>
            {this.props.arSceneButtonText}
        </Button>
        if (this.props.skippedArSceneTutorial || !JSON.parse(process.env.REACT_APP_SHOW_TUTORIAL_BEFORE_AR)) {
            arSceneButton = <Button className='start-content-experience-button'
                large
                fill
                onClick={(e) => { this.startArScene(content.id) }}>
                {this.props.arSceneButtonText}
            </Button>
        }

        const shareLink = (content.googleUrl && content.googleUrl !== "" ?
            <Link
                className='material-icons'
                iconIos="material:share"
                iconMd="material:share"
                color='black'
                onClick={() => { this.props.shareContentHandler(content.googleUrl) }} /> :
            //onClick={() => { this.shareContent(this.props.contentItem.content); }} /> :
            "");
        const arPopup = (
            <Popup className={"popup-tutorial-ar-scene" + content.id}
                tabletFullscreen={true} closeOnEscape={true}>
                <View>
                    <Page>
                        <Navbar transparent={false}>
                            <NavLeft>
                                <Link popupClose iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
                            </NavLeft>
                            <NavRight>
                                {JSON.parse(process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR) ?
                                    <img slot="media" src={'./img/logo/logo.png'}
                                        className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' /> :

                                    <img slot="media" src='./img/spacing_icon.png'
                                        className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />}
                            </NavRight>
                        </Navbar>

                        {TutorialHelper.getSceneViewTutorial(this.toggleClicked, () => this.startArScene(content.id))}

                    </Page>
                </View>
            </Popup>
        )

        let contentDescr = "";
        contentDescr = content.descr.split("\n").map(function (item, idx) {
            return (
                <span key={idx}>
                    {item}
                    <br />
                </span>
            )
        })

        return (
            <Block style={{ marginTop: 0 }}>

                <div className="video-container">

                    <div style={{ textAlign: 'right' }}>
                        {shareLink}
                        {arSceneButton}
                    </div>
                </div>

                <div>
                    <p>{contentDescr}</p>

                    {((content.lat !== 0 && content.long !== 0) ? <MapView content={content} /> : null)}

                    <ContactList content={content} />

                </div>


                <ImageGallery
                    imageArray={content.imageUrls}
                    galleryTitle={this.props.galleryTitle} />

                {arPopup}
            </Block>
        )
    }


    toggleClicked = (selected) => {
        this.props.setSkippedArSceneTutorial(selected);
        localStorage.setItem('skip_ar_scene_tutorial', selected);
    }

    closeTutorial = () => {
        this.props.router.app.popup.close()
    }

    startArScene = (contentId) => {
        this.closeTutorial();

        if (JSON.parse(process.env.REACT_APP_ENABLE_AR_WEB)) {
            console.log('startArScene')
            this.props.router.navigate('/ar-web', {
                props: {
                    arType: AR_TYPE.AR_INVOLVE,
                    arId: contentId
                }
            });
        }
        else {
            this.startNativeArScene(contentId);
        }
    }

    startNativeArScene = (contentId) => {
        PkLog.log('Start ar scene', this.props.content.id);
        let apiUrl = process.env.REACT_APP_CMS_API_URL;
        apiUrl = apiUrl.replace(/(^\w+:|^)\/\//, '');
        //const sceneContentId = this.props.content.id;
        const loadingTime = 6000;
        const loadingText = localeStrings.loading_text;
        const sceneLoadErrorMessage = localeStrings.scene_loading_error_message;
        const mediaLoadErrorMessage = localeStrings.media_loading_error_message;
        let lockRotationType = 1;//portrait = 1, landscape = 0
        if (isMobile) {
            lockRotationType = 1;
        }
        else {
            lockRotationType = 0;
        }
        const loadingScreenBackgroundColor = "#4D4D4D";
        const loadingBarColor = "#FF7900";
        const emptyLoadingBarColor = "#FFFFFF";

        const screenshotDir = process.env.REACT_APP_SCREENSHOT_DIR;
        const screenshotName = process.env.REACT_APP_SCREENSHOT_NAME;

        this.props.setActivityPaused(true);

        PkBabylon.startBabylon(
            apiUrl,
            contentId,
            loadingTime,
            loadingText,
            sceneLoadErrorMessage,
            mediaLoadErrorMessage,
            lockRotationType,
            loadingScreenBackgroundColor,
            loadingBarColor,
            emptyLoadingBarColor,
            screenshotDir,
            screenshotName);

        AnalyticsRegister.logEvent(ANALYTICS_EVENT.START_AR_SCENE,
            {
                SceneName: this.props.content.title,
                SceneContentId: contentId,
                CompanyName: this.props.currentCompany ? this.props.currentCompany.Name : "",
                CompanyId: this.props.currentCompany ? this.props.currentCompany.Id : "",
                AdminName: this.props.currentCompany ? this.props.currentCompany.Admin.Name : "",
                AdminId: this.props.currentCompany ? this.props.currentCompany.Admin.Id : "",
            });
    }

    componentDidMount = () => {

        /*const skippedArSceneTutorial = JSON.parse(localStorage.getItem('skip_ar_scene_tutorial'));
        if (skippedArSceneTutorial) {
            this.props.setSkippedArSceneTutorial(skippedArSceneTutorial);
        }
        else {
            this.props.setSkippedArSceneTutorial(false);
        }*/
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        profile: state.cms.profile,
        appCompany: state.cms.appCompany,
        menu: state.app.menu,
        skippedArSceneTutorial: state.app.skippedArSceneTutorial,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSkippedArSceneTutorial: (skippedArSceneTutorial) => dispatch(actions.setSkippedArSceneTutorial(skippedArSceneTutorial)),
        setActivityPaused: (activityPaused) => dispatch(actions.setActivityPaused(activityPaused)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ArSceneView);
