import React from 'react';
import { connect } from 'react-redux';
import { f7, Link, Block, Button, Popup, View, Page, Navbar, NavRight, NavLeft } from 'framework7-react';
import MapView from '../content-elements/map-view';
import ContactList from '../content-elements/contact-list';
import ImageGallery from '../content-elements/image-gallery';
import localeStrings from './discover-view-local';
import * as actions from '../../redux/actions';
import { PkBabylon } from 'pikkart-cordova';
import { PkLog } from '../../pikkart-cms/log';
import TutorialHelper from '../../helpers/tutorial-helper';
import { PkCordovaARSdk, PkCordovaPermissions } from 'pikkart-cordova';
import { isMobile, isTablet } from 'react-device-detect';
import PkArDiscover from '../../pikkart-cms/ar/discover';
import { AR_TYPE } from '../../utils/AR_TYPE';

class DiscoverView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTutorial: true,
            dialogClosingTime: 200,
        };
    }

    render() {

        const content = this.props.content;

        let discoverButton = <Button
            className='start-content-experience-button'
            large
            fill
            popupOpen={".popup-tutorial-discover" + content.id}>
            {this.props.discoverButtonText}
        </Button>
        if (this.props.skippedDiscoverTutorial || !JSON.parse(process.env.REACT_APP_SHOW_TUTORIAL_BEFORE_AR)) {
            discoverButton = <Button className='start-content-experience-button'
                large
                fill
                onClick={(e) => { this.startDiscoverExperience(content.extlId) }}>
                {this.props.discoverButtonText}
            </Button>
        }

        const shareLink = (content.googleUrl && content.googleUrl !== "" ?
            <Link
                className='material-icons'
                iconIos="material:share"
                iconMd="material:share"
                color='black'
                onClick={() => { this.props.shareContentHandler(content.googleUrl) }} /> :
            "");
        const discoverPopup = (
            <Popup push className={"popup-tutorial-discover" + content.id}
                tabletFullscreen={true} closeOnEscape={true}>
                <View>
                    <Page>
                        <Navbar transparent={false}>
                            <NavLeft>
                                <Link popupClose iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
                            </NavLeft>
                            <NavRight>
                                {JSON.parse(process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR) ?
                                    <img slot="media" src={'./img/logo/logo.png'}
                                        className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' /> :

                                    <img slot="media" src='./img/spacing_icon.png'
                                        className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />}

                            </NavRight>
                        </Navbar>

                        {TutorialHelper.getDiscoverContentDetailTutorial(this.toggleClicked, () => this.startDiscoverExperience(content.extlId))}

                    </Page>
                </View>
            </Popup>
        )

        let contentDescr = "";
        contentDescr = content.descr.split("\n").map(function (item, idx) {
            return (
                <span key={idx}>
                    {item}
                    <br />
                </span>
            )
        })

        return (
            <Block style={{ marginTop: 0 }}>

                <div className="video-container">

                    <div style={{ textAlign: 'right' }}>
                        {shareLink}
                        {discoverButton}
                    </div>
                </div>

                <div>
                    <p>{contentDescr}</p>

                    {((content.lat !== 0 && content.long !== 0) ? <MapView content={content} /> : null)}

                    <ContactList content={content} />

                </div>


                <ImageGallery
                    imageArray={content.imageUrls}
                    galleryTitle={this.props.galleryTitle} />

                {discoverPopup}
            </Block>
        )
    }


    toggleClicked = (selected) => {
        this.props.setSkippedDiscoverTutorial(selected);
        localStorage.setItem('skip_discover_tutorial', selected);
    }

    startArScene = (contentId) => {
        PkLog.log('Start ar scene', this.props.content.id);
        let apiUrl = process.env.REACT_APP_CMS_API_URL;
        apiUrl = apiUrl.replace(/(^\w+:|^)\/\//, '');
        //const sceneContentId = this.props.content.id;
        const loadingTime = 6000;
        const loadingText = localeStrings.loading_text;
        const sceneLoadErrorMessage = localeStrings.scene_loading_error_message;
        const mediaLoadErrorMessage = localeStrings.media_loading_error_message;
        let lockRotationType = 1;//portrait = 1, landscape = 0
        if (isTablet) {
            lockRotationType = 0;
        }
        else if (isMobile) {
            lockRotationType = 1;
        }
        const loadingScreenBackgroundColor = "#4D4D4D";
        const loadingBarColor = "#FF7900";
        const emptyLoadingBarColor = "#FFFFFF";

        const screenshotDir = process.env.REACT_APP_SCREENSHOT_DIR;
        const screenshotName = process.env.REACT_APP_SCREENSHOT_NAME;


        PkBabylon.startBabylon(
            apiUrl,
            contentId,
            loadingTime,
            loadingText,
            sceneLoadErrorMessage,
            mediaLoadErrorMessage,
            lockRotationType,
            loadingScreenBackgroundColor,
            loadingBarColor,
            emptyLoadingBarColor,
            screenshotDir,
            screenshotName);

        /*AnalyticsRegister.logEvent(ANALYTICS_EVENT.START_AR_DISCOVER,
            {
                SceneName: this.props.content.title,
                SceneContentId: contentId,
                CompanyName: this.props.currentCompany.Name,
                CompanyId: this.props.currentCompany.Id,
                AdminName: this.props.currentCompany.Admin.Name,
                AdminId: this.props.currentCompany.Admin.Id,
            });*/
    }

    closeTutorial = () => {
        this.props.router.app.popup.close()
    }

    startDiscoverExperience = (modelId) => {
        this.closeTutorial();

        if (JSON.parse(process.env.REACT_APP_ENABLE_AR_WEB)) {
            console.log('startDiscoverExperience')

            this.props.router.navigate('/ar-web', {
                props: {
                    arType: AR_TYPE.AR_VIEW,
                    arId: modelId,
                }
            });
        }
        else {
            this.startNativeDiscoverExperience(modelId);
        }
    }

    startNativeDiscoverExperience = (modelId) => {
        let _dialog = null;
        const startDiscoverFragment = (modelId, contentKeypointDictionary) => {
            // this.props.setActivityPaused(true);
            let showMaskOverCamera = JSON.parse(process.env.REACT_APP_SHOW_MASK_OVER_CAMERA_ON_AR);
            PkCordovaARSdk.startDiscover(process.env.REACT_APP_CMS_API_URL, modelId, '?contentid=', "?keypointid=", contentKeypointDictionary, process.env.REACT_APP_DISCOVER_KEYPOINTS_COLOR, showMaskOverCamera);
        }

        const downloadDiscoverModel = (modelId, contentKeypointDictionary) => {
            PkCordovaPermissions.askStoragePermission().then((status) => {
                openDeterminedProgress();
                PkCordovaARSdk.downloadDiscoverModel(process.env.REACT_APP_CMS_API_URL, modelId, true, finished, error, progress);
            }).catch((err) => {
                PkLog.error('Download discover model', err);
                openErrorDialog(err);
            });

            const finished = () => {
                PkLog.log('download models finished');
                _dialog.setText(localeStrings.loading_model_complete);

                closeDialog()
                    .then(() => {
                        //const integerModelId = modelId.substring(modelId.lastIndexOf("_") + 1, modelId.lenght);
                        startDiscoverFragment(modelId, contentKeypointDictionary);
                    });
            };

            const progress = (progressValue) => {
                PkLog.log('download model progress ' + progressValue);
                if (_dialog)
                    _dialog.setProgress(progressValue);
                _dialog.setText(localeStrings.formatString(localeStrings.loading_model_percentage, Math.round(progressValue)));
            };

            const error = () => {
                _dialog.setText(localeStrings.loading_model_error);
                closeDialog();
            };

            const openDeterminedProgress = () => {
                const app = f7;
                let progress = 0;
                _dialog = app.dialog.progress(localeStrings.download_title, progress);
                _dialog.setText(localeStrings.download_model_text);
            }
        }

        const openErrorDialog = (error) => {
            PkLog.error(error);
            const app = f7;
            _dialog = app.dialog.alert(localeStrings.ar_error_text);
            _dialog.setTitle(localeStrings.ar_error_title);
        }

        const closeDialog = () => {
            const self = this;
            return new Promise((resolve, reject) => {
                const success = () => {
                    resolve();
                }

                setTimeout(function () {
                    _dialog.close();
                    success();
                }, self.state.dialogClosingTime)
            })
        }

        const openInfiniteProgress = () => {
            const app = f7;
            _dialog = app.dialog.progress(localeStrings.download_title);
            _dialog.setText(localeStrings.download_contents_text);
        }

        openInfiniteProgress();
        if (this.props.content) {
            PkArDiscover.getKeypointContentsArray(modelId, this.props.content.ownerId).then((response) => {
                console.log('response', response);
                closeDialog().then(() => {
                    if (response.result.success === true) {
                        if (response.data && Array.isArray(response.data)) {
                            const contentKeypointDictionary = response.data.reverse();
                            downloadDiscoverModel(modelId, contentKeypointDictionary);
                        }
                        else {
                            closeDialog();
                            PkLog.error(response);
                        }
                    } else {
                        closeDialog();
                        PkLog.error(response);
                    }
                });
            })
                .catch((error) => {
                    closeDialog();
                    PkLog.error(error);
                })
        }
    }

    componentDidMount = () => {

        const skippedDiscoverTutorial = JSON.parse(localStorage.getItem('skip_discover_tutorial'));
        if (skippedDiscoverTutorial) {
            this.props.setSkippedDiscoverTutorial(skippedDiscoverTutorial);
        }
        else {
            this.props.setSkippedDiscoverTutorial(false);
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        skippedDiscoverTutorial: state.app.skippedDiscoverTutorial,
        appCompany: state.cms.appCompany,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSkippedDiscoverTutorial: (skippedDiscoverTutorial) => dispatch(actions.setSkippedDiscoverTutorial(skippedDiscoverTutorial)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverView);