import { getDataUri, isMinimumConstraints } from './helper';
import ImageUtils from '../image-utils/image-utils.js'

import {
  SUPPORTED_FACING_MODES,
  FACING_MODES,
  IMAGE_TYPES,
  MINIMUM_CONSTRAINTS,
  VIDEO_ADVANCED_CONSTRANTS_FACING_MODE_USER,
  VIDEO_ADVANCED_CONSTRANTS_FACING_MODE_ENVIRONMENT
} from './constants';

class MediaServices {
  static getDataUri(videoElement, config) {
    let { /*sizeFactor,*/ imageType, imageCompression/*, isImageMirror*/ } = config;
    let { clientWidth, clientHeight } = videoElement;
    if (config.portionToGetWidth) {
      clientWidth = Math.round(config.portionToGetWidth);
    }
    if (config.portionToGetHeigth) {
      clientHeight = Math.round(config.portionToGetHeigth);
    }
    //let { imageWidth, imageHeight } = getImageSize(clientWidth, clientHeight, sizeFactor);

    // Build the canvas size et draw the image to context from videoElement
    let canvas = document.createElement('canvas');
    //let dataUri = getDataUri(canvas, imageType, imageCompression);

    canvas.id = "canvas_id_1"
    canvas.width = clientWidth;
    canvas.height = clientHeight;

    let ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, videoElement.clientWidth, videoElement.clientHeight);

    //let canvas = document.getElementById("opencv_canvas");
    //let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let imgData = ctx.getImageData(0, 0, videoElement.clientWidth, videoElement.clientHeight);
    let mat_generated = window.cv.matFromImageData(imgData);

    /**
     * test
     */
    window.cv.imshow('canvas_opencv', mat_generated);

    let mat_cropped = mat_generated;
    if (videoElement.clientWidth > clientWidth) {
      mat_cropped = ImageUtils.cropImage(mat_generated, 0, clientHeight, clientWidth);
    }

    let mat_squared = mat_cropped;
    if (videoElement.clientWidth > clientWidth) {
      mat_squared = ImageUtils.cropSquaredImage(mat_cropped, clientHeight, clientWidth);
    } else {
      mat_squared = ImageUtils.cropSquaredImage(mat_cropped, videoElement.clientHeight, videoElement.clientWidth);
    }

    const resizeWidth = parseInt(process.env.REACT_APP_RESIZE_IMAGE_RESOLUTION_WIDTH);
    const resizeHeight = parseInt(process.env.REACT_APP_RESIZE_IMAGE_RESOLUTION_HEIGHT);
    const mat_resized = ImageUtils.resizeImage(mat_squared, resizeWidth, resizeHeight);

    window.cv.imshow('canvas_opencv', mat_resized);

    const canvas_opencv = document.getElementById('canvas_opencv');
    const output = getDataUri(canvas_opencv, imageType, imageCompression);

    mat_resized.delete();
    mat_squared.delete();

    var opencvContext = canvas_opencv.getContext('2d');
    opencvContext.clearRect(0, 0, canvas_opencv.width, canvas_opencv.height);
    return output;
  }

  static getFullResolutionDataUri(videoElement, config) {
    let { /*sizeFactor,*/ imageType, imageCompression/*, isImageMirror*/ } = config;
    let { clientWidth, clientHeight } = videoElement;
    if (config.portionToGetWidth) {
      clientWidth = Math.round(config.portionToGetWidth);
    }
    if (config.portionToGetHeigth) {
      clientHeight = Math.round(config.portionToGetHeigth);
    }
    //let { imageWidth, imageHeight } = getImageSize(clientWidth, clientHeight, sizeFactor);

    // Build the canvas size et draw the image to context from videoElement
    let canvas = document.createElement('canvas');
    //let dataUri = getDataUri(canvas, imageType, imageCompression);

    canvas.id = "canvas_id_1"
    canvas.width = clientWidth;
    canvas.height = clientHeight;

    let ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, videoElement.clientWidth, videoElement.clientHeight);

    //let canvas = document.getElementById("opencv_canvas");
    //let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let imgData = ctx.getImageData(0, 0, videoElement.clientWidth, videoElement.clientHeight);
    let mat_generated = window.cv.matFromImageData(imgData);

    /**
     * test
     */
    window.cv.imshow('canvas_opencv', mat_generated);

    let mat_cropped = mat_generated;
    if (videoElement.clientWidth > clientWidth) {
      mat_cropped = ImageUtils.cropImage(mat_generated, 0, clientHeight, clientWidth);
    }

    let mat_squared = mat_cropped;
    if (videoElement.clientWidth > clientWidth) {
      mat_squared = ImageUtils.cropSquaredImage(mat_cropped, clientHeight, clientWidth);
    } else {
      mat_squared = ImageUtils.cropSquaredImage(mat_cropped, videoElement.clientHeight, videoElement.clientWidth);
    }

    window.cv.imshow('canvas_opencv', mat_squared);

    const canvas_opencv = document.getElementById('canvas_opencv');
    const output = getDataUri(canvas_opencv, imageType, imageCompression);

    mat_squared.delete();

    var opencvContext = canvas_opencv.getContext('2d');
    opencvContext.clearRect(0, 0, canvas_opencv.width, canvas_opencv.height);
    return output;
  }

  /*static getDataUri(videoElement, config) {
    let { sizeFactor, imageType, imageCompression, isImageMirror } = config;

    let { videoWidth, videoHeight } = videoElement;
    let { imageWidth, imageHeight } = getImageSize(videoWidth, videoHeight, sizeFactor);

    // Build the canvas size et draw the image to context from videoElement
    let canvas = document.createElement('canvas');
    canvas.id = "canvas_id_1"
    canvas.width = imageWidth;
    canvas.height = imageHeight;
    let context = canvas.getContext('2d');

    // Flip horizontally (as css transform: rotateY(180deg))
    if (isImageMirror) {
      context.setTransform(-1, 0, 0, 1, canvas.width, 0);
    }

    if (process.env.REACT_APP_IS_FOR_STRETCHING_TABLET && videoHeight > 800) {
      context.transform(0.9, 0, 0, 1, 0, 0);
    }

    context.drawImage(canvas, 0, 0, imageWidth, imageHeight);

    // Get dataUri from canvas
    let dataUri = getDataUri(canvas, imageType, imageCompression);

    let ctx = canvas.getContext('2d')

    let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let src = window.cv.matFromImageData(imgData)

    window.cv.imshow("canvas_opencv", src);

    //const mat = window.cv.imread(canvas);
    const mat_squared = ImageUtils.cropSquaredImage(src, imageHeight, imageWidth);
    let canv = document.createElement('canvas');
    canv.id = "squared_canvas";//String(idx);
    canvas.appendChild(canv);
    window.cv.imshow(canv, mat_squared);

    dataUri = getDataUri(canvas, imageType, imageCompression);

    //console.log('transformation data uri', dataUri);
    return dataUri;
  }*/

  static getWindowURL() {
    let windowURL = window.URL || window.webkitURL || window.mozURL || window.msURL;
    return windowURL;
  }

  /*
  Inspiration : https://github.com/jhuckaby/webcamjs/blob/master/webcam.js
  */
  static getNavigatorMediaDevices() {
    let NMDevice = null;
    let isNewAPI = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    let isOldAPI = !!(navigator.mozGetUserMedia || navigator.webkitGetUserMedia);

    if (isNewAPI) {
      NMDevice = navigator.mediaDevices;
    } else if (isOldAPI) {
      let NMDeviceOld = navigator.mozGetUserMedia || navigator.webkitGetUserMedia;
      // Setup getUserMedia, with polyfill for older browsers
      // Adapted from: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

      let polyfillGetUserMedia = {
        getUserMedia: function (constraint) {
          return new Promise(function (resolve, reject) {
            NMDeviceOld.call(navigator, constraint, resolve, reject);
          });
        }
      };

      // Overwrite getUserMedia() with the polyfill
      NMDevice = Object.assign(NMDeviceOld,
        polyfillGetUserMedia
      );
    }

    // If is no navigator.mediaDevices || navigator.mozGetUserMedia || navigator.webkitGetUserMedia
    // then is not supported so return null
    return NMDevice;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Constraints
  static isSupportedFacingMode() {
    // navigator.mediaDevices
    return MediaServices.getNavigatorMediaDevices().getSupportedConstraints().facingMode;
  }

  static getIdealConstraints(idealFacingMode, idealResolution) {
    // default idealConstraints
    let idealConstraints = {
      audio: false,
      video: {}
    };

    if (isMinimumConstraints(idealFacingMode, idealResolution)) {
      return MINIMUM_CONSTRAINTS;
    }

    const supports = navigator.mediaDevices.getSupportedConstraints();
    /* eslint-env browser */
    // alert(JSON.stringify(supports));
    if (!supports.width || !supports.height || !supports.facingMode) {
      console.log('Constraint width height or facingMode not supported!');
      return MINIMUM_CONSTRAINTS;
    }

    // If is valid facingMode
    if (idealFacingMode && SUPPORTED_FACING_MODES.includes(idealFacingMode)) {
      // idealConstraints.video.facingMode = { ideal: idealFacingMode };
      idealConstraints.video.facingMode = idealFacingMode;
    }

    if (idealResolution && idealResolution.width) {
      idealConstraints.video.width = idealResolution.width;
    }

    if (idealResolution && idealResolution.height) {
      idealConstraints.video.height = idealResolution.height;
    }

    return idealConstraints;
  }

  static getMaxResolutionConstraints(idealFacingMode = {}, numberOfMaxResolutionTry) {
    let constraints = MediaServices.getIdealConstraints(idealFacingMode, {
      width: { min: 640, ideal: 3840 },
      height: { min: 480, ideal: 2160 }
    });
    const facingMode = constraints.video.facingMode;

    let videoAdvancedConstraints = VIDEO_ADVANCED_CONSTRANTS_FACING_MODE_ENVIRONMENT;

    switch (facingMode) {
      case FACING_MODES.ENVIRONMENT:
        videoAdvancedConstraints = VIDEO_ADVANCED_CONSTRANTS_FACING_MODE_ENVIRONMENT;
        break;
      case FACING_MODES.USER:
        videoAdvancedConstraints = VIDEO_ADVANCED_CONSTRANTS_FACING_MODE_USER;
        break;
      default:
        break;
    }

    if (numberOfMaxResolutionTry >= videoAdvancedConstraints.length) {
      return null;
    }

    // each number of try, we remove the last value of the array (the bigger minim width)
    let advanced = videoAdvancedConstraints.slice(0, -numberOfMaxResolutionTry);
    constraints.video.advanced = advanced;

    console.log('max constraints', constraints);

    return constraints;
  }

  static get FACING_MODES() {
    return FACING_MODES;
  }

  static get IMAGE_TYPES() {
    return IMAGE_TYPES;
  }
}

export default MediaServices;
