import { PkLog } from "../log";
import { PkCordovaPermissions } from "../cordova/permissions";
import { PkNotifications } from "../notifications";
import { Pk } from "../index";

export class PkCordova {
  static isCordova = () => {
    if (window.cordova && window.cordova.version) {
      //PkLog.log("we are in cordova");
      return true;
    }
    //PkLog.log("we are NOT in cordova");
    return false;
  };

  static getPlatform = () => {
    return window.cordova.platformId;
  }

  static initCordova = () => {
    return new Promise((resolve, reject) => {
      // PkNotifications.initCordovaNotifications();

      if (Pk.getConfig().callsEnabled) {
        PkCordovaPermissions.askCallPermissions()
          .then((response) => {
            PkLog.log(response);
          })
          .catch((e) => {
            PkLog.log(e);
          })
          .finally(() => {
            resolve();
            //}
          });
      } else {
        resolve();
      }
    });
  };

  static getAppOpenerMessage = () => {
    return new Promise((resolve, reject) => {
      function error(errorMessage) {
        reject(errorMessage);
      }

      function success(message) {
        if (message) {
          resolve(message);
        } else {
          resolve(null);
        }
      }

      const notificationManager = window.cordova.plugins.NotificationManager;
      if (notificationManager) {
        window.cordova.plugins.NotificationManager.getLatestMessage(
          success,
          error
        );
      } else {
        error("plugin not found");
      }
    });
  };

  static getDeviceInfo = () => {
    return new Promise((resolve, reject) => {
      function error(errorMessage) {
        reject(errorMessage);
      }

      function success(deviceInfo) {
        if (deviceInfo) {
          resolve(deviceInfo);
        } else {
          reject("error retrieving device info");
        }
      }

      var permissions = window.cordova.plugins.permissions;
      permissions.getDeviceInfo(success, error);
    });
  };
}
export default PkCordova;
