import React from 'react';
import { connect } from 'react-redux';
import { f7, Page, Navbar } from 'framework7-react';
import TutorialHelper from '../../helpers/tutorial-helper';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register'
import Commons from '../../commons'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';

class RefarmTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
        const title = menuItem ? menuItem.title : (this.props.title ? this.props.title : "")

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={title}
                        logo='./img/logo/logo.png'
                        showNavRight={this.props.showNavRight ? this.props.showNavRight : process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={this.props.showArrowAsNavRight ? this.props.showArrowAsNavRight : false}
                        showMenu={Commons.isSideMenuAllowed(f7)} />
                </Navbar>
                {this.props.tutorialToShow ? this.props.tutorialToShow : TutorialHelper.getCustomTutorial()}
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.TUTORIAL);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RefarmTutorial);