import React from 'react';
import { connect } from 'react-redux';
import { f7, Card, Page, Navbar, Link, Row, Col, Block } from 'framework7-react';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register'
import Commons from '../../commons'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import localeStrings from './pre-tutorial-local';
import TutorialHelper from '../../helpers/tutorial-helper';

class PreTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        let firstTutorialText = "";
        firstTutorialText = localeStrings.first_tutorial_text.split("\n").map(function (item, idx) {
            return (
                <span key={idx} style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {item}
                    <br />
                </span>
            )
        })

        let secondTutorialText = "";
        secondTutorialText = localeStrings.second_tutorial_text.split("\n").map(function (item, idx) {
            return (
                <span key={idx} style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {item}
                    <br />
                </span>
            )
        })

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false}
                        showMenu={Commons.isSideMenuAllowed(f7)} />
                </Navbar>
                <div style={{ height: '80vh' }}>
                    <Card style={{ position: 'relative', height: '50%' }}>
                        <Block textAlign="center" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                            <Link style={{ width: '100%', height: '100%' }} onClick={() => { this.goToArInvolveTutorial() }}>
                                <Row style={{ width: '100%', border: "#125321" }}>
                                    <Col>
                                        <img src={'./img/view_in_ar_black.png'} alt="logo"
                                            width="80" style={{ margin: 'auto', display: 'flex' }} />

                                        <Block style={{ textAlign: 'center', marginTop: '23px' }}>
                                            {firstTutorialText}
                                        </Block>
                                    </Col>
                                </Row>
                            </Link>
                        </Block>
                    </Card>

                    <Card style={{ position: 'relative', height: '50%' }}>
                        <Block textAlign="center" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                            <Link style={{ width: '100%', height: '100%' }} onClick={() => { this.goToArPlanarTutorial() }}>
                                <Row style={{ width: '100%' }}>
                                    <Col>
                                        <img src={'./img/compare_black.png'} alt="logo"
                                            width="80" style={{ margin: 'auto', display: 'flex' }} />

                                        <Block style={{ textAlign: 'center', marginTop: '23px' }}>
                                            {secondTutorialText}
                                        </Block>
                                    </Col>
                                </Row>
                            </Link>
                        </Block>
                    </Card>
                </div>
            </Page >
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.TUTORIAL);
    }

    goToArInvolveTutorial = () => {
        if (this.props.f7router.currentRoute.url !== "/tutorial") {
            this.props.f7router.navigate('/tutorial', {
                animate: true,
                props: {
                    showNavRight: true,
                    showArrowAsNavRight: true,
                    title: localeStrings.ar_involve_title,
                    tutorialToShow: TutorialHelper.getMenuSceneViewTutorial(),
                }
            });
        }
    }

    goToArPlanarTutorial = () => {
        if (this.props.f7router.currentRoute.url !== "/tutorial") {
            this.props.f7router.navigate('/tutorial', {
                animate: true,
                props: {
                    showNavRight: true,
                    showArrowAsNavRight: true,
                    title: localeStrings.ar_planar_title,
                    tutorialToShow: TutorialHelper.getMenuPlanarAndDiscoverTutorial(),
                }
            });
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(PreTutorial);