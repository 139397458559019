import React, { useEffect, useRef, useState } from "react";
import {
    Block,
    Button,
    Toolbar,
    Navbar,
    Page,
    Messages,
    MessagesTitle,
    Message,
    Messagebar,
    Link,
    MessagebarAttachments,
    MessagebarAttachment,
    MessagebarSheet,
    MessagebarSheetImage,
    f7ready,
    f7,
    NavRight,
    Actions,
    ActionsGroup,
    ActionsButton,
    ActionsLabel,
} from "framework7-react";
import OpenAiGenerate from "../../pikkart-cms/ai-chat/generate.js";
import localeStrings from "./chat-local";
import { useDispatch, useSelector } from "react-redux";
import MessageUtils from "./messageUtils.js";

import {
    setSplashRequestedMessage,
} from "../../redux/actions.js";
import Commons from "../../commons.js";

export const CHAT_SUBJECT = {
    TOURISM: 0,
    RESTAURANTS: 1,
    HOTELS: 2,
};

//durante lo sviluppo delle parti grafiche o comunque
//che non riguardano chatGPT metto la variabile a true
//per non fare richieste alle api e consumare credito
export const UI_DEBUG_DESIGN = false;

export default (props) => {

    //immagini di esempio da usare in chat
    const images = [
        "https://cdn.framework7.io/placeholder/cats-300x300-1.jpg",
        "https://cdn.framework7.io/placeholder/cats-200x300-2.jpg",
        "https://cdn.framework7.io/placeholder/cats-400x300-3.jpg",
        "https://cdn.framework7.io/placeholder/cats-300x150-4.jpg",
        "https://cdn.framework7.io/placeholder/cats-150x300-5.jpg",
        "https://cdn.framework7.io/placeholder/cats-300x300-6.jpg",
        "https://cdn.framework7.io/placeholder/cats-300x300-7.jpg",
        "https://cdn.framework7.io/placeholder/cats-200x300-8.jpg",
        "https://cdn.framework7.io/placeholder/cats-400x300-9.jpg",
        "https://cdn.framework7.io/placeholder/cats-300x150-10.jpg",
    ];
    //una serie di domande di default (non usato)
    const answers = [
        "Yes!",
        "No",
        "Hm...",
        "I am not sure",
        "And what about you?",
        "May be ;)",
        "Lorem ipsum dolor sit amet, consectetur",
        "What?",
        "Are you sure?",
        "Of course",
        "Need to think about it",
        "Amazing!!!",
    ];
    //aggiunta di un'immagine tra gli allegati da mandare nel messaggio
    const [imageAttachments, setImageAttachments] = useState([]);
    //mostra o nasconde il pannello per la selezione dell'allegato (non usato)
    const [sheetVisible, setSheetVisible] = useState(false);
    //mostra o nasconde il pannello per la selezione del tipo di allegato da mandare (audio|doc)
    const [actionsGridOpened, setActionsGridOpened] = useState(false);
    //mostra o nasconde il pannello per la selezione del soggetto della chat (CHAT_SUBJECT)
    const [chatSubjectActionOpened, setChatSubjectActionOpened] = useState(false);
    //riferimento al doc caricato dall'utente
    const inputFile = useRef(null);
    //riferimento al toast che mostra l'errore di chatGPT
    const toastWithButton = useRef(null);
    //contiene l'oggetto dell'utente tra quelli nell'array people che sta scrivendo
    const [typingMessage, setTypingMessage] = useState(null);
    //stringa che l'utente ha inserito nella barra di scrittura. viene aggiornato ogni volta che viene modificato un carattere
    const [messageText, setMessageText] = useState("");
    //array con tutti i messaggi della chat. quando si mandano i messaggi a chatGPT si parte da questa lista
    //per poi rimuovere i primi messaggi in più
    const [messagesData, setMessagesData] = useState([
        // {
        //   type: "sent",
        //   text: "Hi, PikkartBOT",
        // },
        // {
        //   type: "sent",
        //   text: "How are you?",
        // },
        // {
        //   name: "PikkartBOT",
        //   type: "received",
        //   text: "Hi, I am good!",
        //   avatar:
        //     "https://www.innovup.net/wp-content/uploads/2022/08/pikkart_pmi_innovativa_innovup.jpg.jpg",
        // },
        // {
        //   type: "sent",
        //   text: "Hey, look, cutest kitten ever!",
        // },
        // {
        //   type: "sent",
        //   text: "Foto",
        //   // image: "https://cdn.framework7.io/placeholder/cats-200x260-4.jpg",
        // },
        // {
        //   name: "PikkartBOT",
        //   type: "received",
        //   text: "Nice!",
        //   avatar:
        //     "https://www.innovup.net/wp-content/uploads/2022/08/pikkart_pmi_innovativa_innovup.jpg.jpg",
        // },
        // {
        //   name: "PikkartBOT",
        //   type: "received",
        //   text: "Like it very much!",
        //   avatar:
        //     "https://www.innovup.net/wp-content/uploads/2022/08/pikkart_pmi_innovativa_innovup.jpg.jpg",
        // },
    ]);
    //stringa con la lingua dell'utente nel formato locale en-US
    const [userLang, setUserLang] = useState("");
    //abilita o disabilità la possibilità di scrivere nella barra di scrittura
    const [textBarEnabled, setTextBarEnabled] = useState(false);
    //CHAT_SUBJECT attualmente selezionato per la chat
    const [chatSubject, setChatSubject] = useState(CHAT_SUBJECT.TOURISM);

    //riferimento all'oggetto che controlla se c'è una risposta in corso, nel caso si mandino altri messaggi
    //mentre chatGPT sta risponendo non vengono considerati
    const responseInProgress = useRef(false);
    //riferimento all'elemento html della barra di scrittura
    //al momento non viene usata ma può essere comoda
    const messagebar = useRef(null);
    //valore che indica il massimo dei messaggi della lista messagesData può essere
    //mandata a chatGPT (questione riduzione uso dei tokens)
    const maxMessagesHistory = 11;

    //#region REDUX
    const splashRequestedMessage = useSelector(
        (state) => state.app.splashRequestedMessage
    );
    const isSplashRequestMessagePending = useSelector(
        (state) => state.app.isSplashRequestMessagePending
    );
    const dispatch = useDispatch();

    //chiamato quando redux aggiorna lo stato di splashRequestedMessage
    useEffect(() => {
        if (splashRequestedMessage) {
            const messagesToSend = [];
            onOpenAiResponseReceived(
                [],
                messagesToSend,
                splashRequestedMessage,
                MessageUtils.getBotPerson()
            );

            dispatch(setSplashRequestedMessage(null));
        }
    }, [splashRequestedMessage]);

    useEffect(() => {
        if (isSplashRequestMessagePending) {
            setMessagesData([]);
        }
        MessageUtils.showTypingMessage(isSplashRequestMessagePending, setTypingMessage);
    }, [isSplashRequestMessagePending]);
    //#endregion

    // #region GESTIONE IMMAGINI NELLA CHAT
    const imageAttachmentsVisible = () => {
        return imageAttachments.length > 0;
    };
    const placeholder = () => {
        return imageAttachments.length > 0
            ? localeStrings.attachment_bar_text_placeholder
            : localeStrings.message_bar_placeholder;
    };
    const deleteImageAttachment = (image) => {
        const index = imageAttachments.indexOf(image);
        imageAttachments.splice(index, 1);
        setImageAttachments([...imageAttachments]);
    };
    const handleImageAttachment = (e) => {
        const index = f7.$(e.target).parents("label.checkbox").index();
        const image = images[index];
        if (e.target.checked) {
            // Add to attachments
            imageAttachments.unshift(image);
        } else {
            // Remove from attachments
            imageAttachments.splice(imageAttachments.indexOf(image), 1);
        }
        setImageAttachments([...imageAttachments]);
    };
    // #endregion
    // #region FUNZIONI DI UTILITIES PER I MESSAGGI
    const isFirstMessage = (message, index) => {
        const previousMessage = messagesData[index - 1];
        if (message.isTitle) return false;
        if (
            !previousMessage ||
            previousMessage.type !== message.type ||
            previousMessage.name !== message.name
        )
            return true;
        return false;
    };
    const isLastMessage = (message, index) => {
        const nextMessage = messagesData[index + 1];
        if (message.isTitle) return false;
        if (
            !nextMessage ||
            nextMessage.type !== message.type ||
            nextMessage.name !== message.name
        )
            return true;
        return false;
    };
    const isTailMessage = (message, index) => {
        const nextMessage = messagesData[index + 1];
        if (message.isTitle) return false;
        if (
            !nextMessage ||
            nextMessage.type !== message.type ||
            nextMessage.name !== message.name
        )
            return true;
        return false;
    };
    // #endregion

    const recordAudio = () => {
        console.log("start record audio");
    };
    const clickInputDocument = () => {
        inputFile.current.click();
    };
    const onDocumentSelected = (e) => {
        console.log("doc selected", e.target.files[0]);
    };

    const changeSubject = (subject) => {
        setChatSubject(subject);
        if (props.onChangeSubject) {
            props.onChangeSubject(subject);
        }
    }

    const sendChoice = (item) => {
        const choicesMessage = messagesData[0];
        setMessagesData([choicesMessage]);
        const text = localeStrings.formatString(
            localeStrings.choice_message,
            item
        );
        sendMessage(text, [choicesMessage]);
    }

    const sendMessage = (systemMessage, overrideMessageData) => {
        const newMessagesData = [...(overrideMessageData || messagesData)];
        let text;
        if (systemMessage) {
            text = systemMessage.replace(/\n/g, "<br>").trim();
        } else {
            text = messageText.replace(/\n/g, "<br>").trim();
        }
        let messagesToSend = [];
        if (text.length) {
            messagesToSend.push({
                text,
            });
        }
        if (messagesToSend.length === 0) {
            return;
        }
        scrollToLastMessage();

        if (!systemMessage) {
            //aggiungo il messaggio alla lista solo se non è un messaggio di sistema
            setMessagesData([...newMessagesData, ...messagesToSend]);
        }
        setMessageText("");

        // Focus area
        // if (text.length) messagebar.current.focus();

        if (responseInProgress.current) return;
        responseInProgress.current = true;

        MessageUtils.showTypingMessage(true, setTypingMessage);

        let toProcessMessagesData = [...newMessagesData];

        if (toProcessMessagesData.length >= maxMessagesHistory) {
            const numberMessagesToDelete =
                toProcessMessagesData.length - maxMessagesHistory + 1; //il +1 indica il messaggio che si sta mandando adesso che non è all'interno di messagesData
            toProcessMessagesData.splice(0, numberMessagesToDelete);
        }

        /**
         * processo solo un numero limitato di messaggi ma nello storico della chat li tengo tutti
         */
        onSubmit(toProcessMessagesData, messagesToSend).then((chatGptResponse) => {
            if (systemMessage) {
                messagesToSend = [];
            }
            onOpenAiResponseReceived(
                newMessagesData,
                messagesToSend,
                chatGptResponse,
                MessageUtils.getBotPerson()
            );
        });
    };

    const onOpenAiResponseReceived = (
        messagesData,
        messagesToSend,
        chatGptResponse,
        person
    ) => {
        /*{
                "role":"assistant",
                "content":"Certo, Modena è famosa per la sua cucina deliziosa! Ecco alcuni ristoranti che potresti provare:\\n\\n1. Osteria 
              Francescana - un ristorante stellato Michelin con una cucina innovativa e creativa.\\n\\n2. Da Enzo - un ristorante tradizionale che serve piatti 
              classici della cucina modenese.\\n\\n3. Hosteria Giusti - un ristorante storico con una vasta selezione di salumi e formaggi locali.\\n\\n4. 
              Trattoria Aldina - un ristorante accogliente con piatti della cucina casalinga modenese.\\n\\n5. Ristorante Cocchi - un ristorante di pesce che 
              serve piatti freschi e di alta qualità.\\n\\nSpero che questi suggerimenti ti siano utili!"
            }'*/

        const messageContent = chatGptResponse.chatGptMessage.message.content;
        const messageList = chatGptResponse.chatGptMessage.message.list;
        const usage = chatGptResponse.usage;

        MessageUtils.showTypingMessage(false, setTypingMessage);
        console.log({ chatGptResponse, messageContent });

        setMessagesData([
            ...messagesData,
            ...messagesToSend,
            {
                text: messageContent,
                list: messageList,
                type: "received",
                name: person.name,
                avatar: person.avatar,
            },
        ]);
        responseInProgress.current = false;
        scrollToLastMessage();
        setTextBarEnabled(true);
    };

    const scrollToLastMessage = () => {
        if (document.getElementsByClassName("messages-content").length > 0) {
            Commons.scrollTo(
                document.getElementsByClassName("messages-content")[0],
                document.getElementsByClassName("messages-content")[0].scrollHeight,
                500
            );
        }
    };

    const resetMessages = () => {
        const text = localeStrings.formatString(
            localeStrings.after_reset,
            `${props.userCity}`,
        )
        const bot = MessageUtils.getBotPerson();
        setMessagesData([{
            text: text,
            type: "received",
            name: bot.name,
            avatar: bot.avatar,
        }]);
    }

    const showToastWithButton = () => {
        // Create toast
        if (!toastWithButton.current) {
            toastWithButton.current = f7.toast.create({
                text: localeStrings.toast_message,
                closeButton: true,
            });
        }
        // Open it
        toastWithButton.current.open();
    };

    /**
     * OPENAI GENERATION
     */
    async function onSubmit(messagesData, messagesToSend) {
        return new Promise((resolve, reject) => {
            /**
             * SOLO L'ULTIMO MESSAGGIO
             */
            // const chatGptMessages = messagesToSend;
            // const messagesArrayToSend = chatGptMessages.map((message, index) => {
            //   if (!message.type) {
            //     return { role: "user", content: message.text };
            //   }
            // });

            /**
             * TUTTI I MESSAGGI
             */
            const chatGptMessages = messagesData.concat(messagesToSend);
            const messagesArrayToSend = chatGptMessages.map((message, index) => {
                if (message.type && message.type === "received") {
                    return { role: "assistant", content: message.text };
                } else {
                    const text = localeStrings.formatString(
                        localeStrings.user_chat_message,
                        `${props.userRoad} ${props.userCity}`,

                        message.text
                    )
                    return { role: "user", content: text };
                }
            });

            if (!UI_DEBUG_DESIGN) {
                OpenAiGenerate.createCmsChatCompletion(messagesArrayToSend)
                    .then((response) => {
                        resolve({
                            chatGptMessage: response.chatGptMessage,
                            usage: response.usage,
                        });
                    })
                    .catch((err) => {
                        console.log("err", err);
                        MessageUtils.showTypingMessage(false, setTypingMessage);
                        showToastWithButton();
                    });
            }
        });
    }

    useEffect(() => {
        f7ready(() => {
            messagebar.current = f7.messagebar.get(".messagebar");
        });
    });

    useEffect(() => {
        const node = document.getElementsByClassName("messagebar-area")[0];
        if (node) {
            node.addEventListener("keyup", ({ key }) => {
                if (key === "Enter") {
                    document.getElementById("sendMessageLink").click();
                }
            });
        }

        const userLang = navigator.language || navigator.userLanguage;
        setUserLang(userLang);
    }, []);

    /**
     * chiamato quando cambia chatSubject
     * se cambia resetto i messaggi se ce ne sono già stati
     * */
    /*
    useEffect(() => {
      if (messagesData.length !== 0) setMessagesData([]);
    }, [chatSubject]);
    */

    return (<>
        <Page className='omg_chatPage'>
            <Navbar title="OhMyGuide!">
                <NavRight>
                    <Link
                        href="https://www.ai4smartcity.ai/servizi/notizie/notizie_homepage.aspx"
                        external
                        target="_blank"
                        icon="ai4-smartcity"
                        iconSize={24}
                    ></Link>
                    <Link
                        iconOnly={true}
                        iconIos="material:menu"
                        iconAurora="material:menu"
                        iconMd="material:menu"
                        onClick={() => setChatSubjectActionOpened(true)}
                    />
                </NavRight>
            </Navbar>
            <div slot="fixed">
                <footer className="omg_footerBanner">
                    <Button
                        fill
                        onClick={resetMessages}
                    >
                        {localeStrings.reset_label}
                    </Button>
                </footer>
            </div>
            <Messagebar
                placeholder={placeholder()}
                attachmentsVisible={imageAttachmentsVisible()}
                sheetVisible={sheetVisible}
                value={messageText}
                onInput={(e) => {
                    setMessageText(e.target.value);
                }}
                disabled={!textBarEnabled}
            >
                {/* <Link
          iconIos="f7:ellipsis_circle_fill"
          iconMd="material:pending"
          slot="inner-start"
          onClick={() => setActionsGridOpened(true)}
        /> */}

                <Link
                    id="sendMessageLink"
                    iconIos="f7:arrow_up_circle_fill"
                    iconMd="material:send"
                    slot="inner-end"
                    onClick={() => sendMessage()}
                />
                {/*
        ACTIONS GRID
        */}
                {/* Grid */}
                <Actions
                    grid={true}
                    opened={actionsGridOpened}
                    onActionsClosed={() => setActionsGridOpened(false)}
                >
                    <ActionsGroup>
                        <ActionsButton onClick={recordAudio}>
                            <img
                                slot="media"
                                src="https://cdn.icon-icons.com/icons2/933/PNG/512/record-voice-microphone-button_icon-icons.com_72608.png"
                                width="48"
                                style={{ maxWidth: "100%", borderRadius: "8px" }}
                            />
                            <span>Audio</span>
                        </ActionsButton>
                        <ActionsButton onClick={clickInputDocument}>
                            <input
                                type="file"
                                id="file"
                                ref={inputFile}
                                style={{ display: "none" }}
                                onChange={onDocumentSelected}
                            />
                            <img
                                slot="media"
                                src="https://cdn.icon-icons.com/icons2/2518/PNG/512/file_upload_icon_151357.png"
                                width="48"
                                style={{ maxWidth: "100%", borderRadius: "8px" }}
                            />
                            <span>Document</span>
                        </ActionsButton>
                    </ActionsGroup>
                </Actions>
                <MessagebarAttachments>
                    {imageAttachments.map((image, index) => (
                        <MessagebarAttachment
                            key={index}
                            image={image}
                            onAttachmentDelete={() => deleteImageAttachment(image)}
                        />
                    ))}
                </MessagebarAttachments>
                <MessagebarSheet>
                    {images.map((image, index) => (
                        <MessagebarSheetImage
                            key={index}
                            image={image}
                            checked={imageAttachments.indexOf(image) >= 0}
                            onChange={handleImageAttachment}
                        />
                    ))}
                </MessagebarSheet>
            </Messagebar>

            <Messages>
                {/* <MessagesTitle>
          <b>{localeStrings.preview_info}</b>
        </MessagesTitle> */}

                {messagesData.map((message, index) => (
                    <Message
                        key={index}
                        type={message.type}
                        image={message.image}
                        name={message.name}
                        avatar={message.avatar}
                        first={isFirstMessage(message, index)}
                        last={isLastMessage(message, index)}
                        tail={isTailMessage(message, index)}
                    >
                        {message.text && (
                            <span
                                slot="text"
                                dangerouslySetInnerHTML={{ __html: message.text }}
                            />
                        )}
                        {message.list && (<ol className="omg_messageList">
                            {message.list.map((item, k) => (
                                <li key={k} onClick={() => sendChoice(item)}>{item}</li>
                            ))}
                        </ol>)}
                    </Message>
                ))}
                {typingMessage && (
                    <Message
                        type="received"
                        typing={true}
                        first={true}
                        last={true}
                        tail={true}
                        header={`${typingMessage.name}` + localeStrings.is_typing}
                        avatar={typingMessage.avatar}
                    />
                )}
            </Messages>

            {/**
       * CHANGE CHAT SUBJECT ACTIONS
       */}
            <Actions
                opened={chatSubjectActionOpened}
                onActionsClosed={() => setChatSubjectActionOpened(false)}
            >
                <ActionsGroup>
                    <ActionsLabel>{localeStrings.options_label}</ActionsLabel>
                    <ActionsButton
                        strong={chatSubject === CHAT_SUBJECT.TOURISM}
                        onClick={() => changeSubject(CHAT_SUBJECT.TOURISM)}
                    >
                        {localeStrings.tourism_option_item}
                    </ActionsButton>
                    <ActionsButton
                        strong={chatSubject === CHAT_SUBJECT.RESTAURANTS}
                        onClick={() => changeSubject(CHAT_SUBJECT.RESTAURANTS)}
                    >
                        {localeStrings.restaurants_option_item}
                    </ActionsButton>
                    <ActionsButton
                        strong={chatSubject === CHAT_SUBJECT.HOTELS}
                        onClick={() => changeSubject(CHAT_SUBJECT.HOTELS)}
                    >
                        {localeStrings.hotels_option_item}
                    </ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                    <ActionsButton
                        onClick={() => {
                            props.f7router.navigate("/ai-info", {
                            });
                        }}>{localeStrings.info}</ActionsButton>
                    <ActionsButton
                        onClick={() => {
                            window.open(encodeURI(process.env.REACT_APP_PRIVACY_URL), '_blank')
                        }}
                    >{localeStrings.disclaimer}</ActionsButton>
                </ActionsGroup>
            </Actions>
        </Page>

    </>);
};
