import Pk from '../index';
import PkContents, { CONTENT_VIEW } from '../contents';
import PkApiNet from '../api/net';


export class PkArDiscover {

    static getModel = (modelUniqueId) => {
        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/discover/models/' + modelUniqueId,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static processModel = (modelUniqueId) => {
        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/discover/models/' + modelUniqueId + '?process',
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getModelViews = (modelUniqueId) => {
        return new Promise((resolve, reject) => {
            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/discover/modelviews?m=' + modelUniqueId,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static setModelViewMask = (viewUniqueId, maskBase64) => {
        return new Promise((resolve, reject) => {

            const bodyFormData = new FormData();
            bodyFormData.set('maskBase64', maskBase64);

            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'put',
                data: bodyFormData,
                url: Pk.getConfig().apiUrl + '/api/discover/modelviews/' + viewUniqueId,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static addModelView = (modelUniqueId, imageBase64) => {
        return new Promise((resolve, reject) => {

            const bodyFormData = new FormData();
            bodyFormData.set('modelUniqueId', modelUniqueId);
            bodyFormData.set('imageBase64', imageBase64);

            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'post',
                data: bodyFormData,
                url: Pk.getConfig().apiUrl + '/api/discover/modelviews',
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static deleteModelView = (viewUniqueId) => {
        return new Promise((resolve, reject) => {
            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'delete',
                url: Pk.getConfig().apiUrl + '/api/discover/modelviews/' + viewUniqueId,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getModelViewImage = (viewUniqueId) => {
        return new Promise((resolve, reject) => {
            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/discover/modelviews/' + viewUniqueId + '?getImage',
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getModelViewLayer = (viewUniqueId) => {
        return new Promise((resolve, reject) => {
            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/discover/modelviews/' + viewUniqueId + '?getLayer',
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getModelViewKeyPoints = (viewUniqueId) => {
        return new Promise((resolve, reject) => {
            //PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/discover/keypoint?mvId=' + viewUniqueId,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static saveModelViewKeyPoint = (viewUniqueId, contentId, posX, posY, color) => {
        return new Promise((resolve, reject) => {

            const bodyFormData = new FormData();
            bodyFormData.set('mvId', viewUniqueId);
            bodyFormData.set('cId', contentId);
            bodyFormData.set('posx', posX);
            bodyFormData.set('posy', posY);
            bodyFormData.set('color', color);

            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'post',
                data: bodyFormData,
                url: Pk.getConfig().apiUrl + '/api/discover/keypoint',
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static deleteModelViewKeyPoint = (viewUniqueId, contentId) => {
        return new Promise((resolve, reject) => {

            const bodyFormData = new FormData();
            bodyFormData.set('mvId', viewUniqueId);
            bodyFormData.set('cId', contentId);

            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'delete',
                url: Pk.getConfig().apiUrl + '/api/discover/keypoint',
                data: bodyFormData,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getKeypointContentsData = (modelContentId) => {
        return new Promise((resolve, reject) => {
            let filters = {};

            filters.v = 9;
            filters.groupId = modelContentId;

            PkArDiscover.getDiscoverContentsDataPage(filters, Pk.getConfig().apiUrl + '/api/infocontents')
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getModelsDataPage = (filters) => {
        return new Promise((resolve, reject) => {

            if (!filters) {
                filters = {};
            }

            filters.v = CONTENT_VIEW.DISCOVER_MODEL;

            PkArDiscover.getDiscoverContentsDataPage(filters, Pk.getConfig().apiUrl + '/api/discover/models')
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };

    static getKeypointContentData = (keypointId) => {
        return new Promise((resolve, reject) => {

            // PkApiNet.callApi({
            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/discover/keypoint/' + keypointId + '?l=' + Pk.getLocale(),
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };

    static getDiscoverContentsDataPage = (filters, url) => {
        return new Promise((resolve, reject) => {

            let queryParams = [];
            if (!filters) {
                filters = {
                    page: 1,
                    pageSize: 10
                };
            }
            if (!filters.page) {
                filters.page = 1;
            }
            if (!filters.pageSize) {
                filters.pageSize = 10;
            }


            if (filters.nearFilters) queryParams.push('near');
            if (filters.page) queryParams.push('page=' + filters.page);
            if (filters.pageSize) queryParams.push('pageSize=' + filters.pageSize);
            if (filters.v) queryParams.push('v=' + filters.v);
            if (filters.query) queryParams.push('query=' + filters.query);
            if (filters.date) queryParams.push('date=' + filters.date);
            if (filters.c1) queryParams.push('c1=' + filters.c1);
            if (filters.c2) queryParams.push('c2=' + filters.c2);
            if (filters.c3) queryParams.push('c3=' + filters.c3);
            if (filters.groupId) queryParams.push('g=' + filters.groupId);
            if (filters.rel) queryParams.push('rel=' + filters.rel);
            if (filters.ow) queryParams.push('ow=' + filters.ow);
            queryParams.push('l=' + Pk.getLocale());



            PkApiNet.callApi({
                method: filters.nearFilters ? 'post' : 'get',
                url: (url ? url : Pk.getConfig().apiUrl + '/api/infocontents') + '?' + queryParams.join('&'),
                data: filters.nearFilters ? filters.nearFilters : null,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };

    static deleteModelContent = (contentId) => {
        return new Promise((resolve, reject) => {
            PkContents.deleteInfoContent(contentId)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static addModelContent = (modelId, infoContent) => {
        return new Promise((resolve, reject) => {

            infoContent.ctnView = 9;
            infoContent.groupId = modelId;

            PkContents.addInfoContent(infoContent)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static setModelContent = (contentId, infoContent) => {
        return new Promise((resolve, reject) => {

            infoContent.ctnView = 9;
            //infoContent.groupId = contentId;

            PkContents.setInfoContent(contentId, infoContent)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getKeypointContentsArray = (modelUniqueId, customerId) => {
        return new Promise((resolve, reject) => {
            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/discover/keypoint?mvId=&mId=' + modelUniqueId + '&ow=' + customerId,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }
}

export default PkArDiscover;
