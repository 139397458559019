import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        result_block_title: "Control outcome",
    },
    it: {
        result_block_title: "Esito controllo",
    }
});

export default localeStrings;