import { Card, CardFooter, CardHeader } from 'framework7-react';
import React from 'react';

class ImageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { spans: 0 };
        this.imageRef = React.createRef();
    }

    setSpans = () => {
        if (this.imageRef.current) {
            let height = this.imageRef.current.clientHeight + 70;
            let spans = Math.ceil(height / 10);
            this.setState({ spans: spans });
        }
    }

    render() {
        const imgUrl = this.props.image ? this.props.image : './img/discover_img_placeholder.jpg';
        return (
            <Card className="discover-card-header-pic">
                <span onClick={this.props.modifyView}>

                    <CardHeader
                        className="no-border"
                        valign="bottom"
                        style={{
                            backgroundImage: 'url(' + imgUrl + ')',
                        }}
                    >
                    </CardHeader>

                    <CardFooter>
                        <div className="grid-container-element">
                            <div className="grid-child-element-left">
                                <span className="material-icons">star</span>
                                <p style={{ margin: 0 }}>{this.props.rating}</p>

                            </div>
                            <div className="grid-child-element-right">
                                <p style={{ margin: 0 }}>{this.props.keyPointsCount}</p>
                                <span className="material-icons">adjust</span>
                            </div>
                        </div>
                    </CardFooter>
                </span>
            </Card>
        );
    }
}

export default ImageCard;