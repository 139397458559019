import React from 'react';
import PkArViewInvolve from "../../pikkart-cms/ar-view/involve";
import PkLog from "../../pikkart-cms/log";
import { ListItem, List, BlockTitle, Block } from "framework7-react";

class ArWebIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scenes: [],
            loaded: false,
        };

        this.onSelectScene = this.props.onSelectScene.bind(this);
    }

    componentDidMount = () => {
        // get scenes from server
        PkArViewInvolve.getScenes()
            .then((response) => {
                const { result, data } = response;
                if (result.success) {
                    if (data && data.length) {
                        this.setState({
                            scenes: [...data],
                            loaded: true,
                        });
                    } else {
                        this.setState({
                            loaded: true,
                        });
                    }
                } else {
                    PkLog.error(response);
                }
            });
    }

    render() {
        const { loaded, scenes } = this.state;
        const scenesList = scenes.map((scene, index) => {
            const subScenes = [];
            if (scene.origin !== 'ARLogo') {
                if (scene.subScenes && scene.subScenes.length) {
                    scene.subScenes.sort((a, b) => a.order - b.order);
                    scene.subScenes.forEach((ss) => {
                        subScenes.push({
                            sceneId: scene.id,
                            subSceneId: ss.id,
                            name: ss.name
                        });
                    });
                } else {
                    subScenes.push({
                        sceneId: scene.id,
                        name: scene.name
                    });
                }
            } else {
                scene.codesScenes.forEach((cs) => {
                    subScenes.push({
                        sceneId: cs.id,
                        name: cs.name
                    });
                    if (cs.subScenes && Array.isArray(cs.subScenes)) {
                        cs.subScenes.sort((a, b) => a.order - b.order);
                        cs.subScenes.forEach((ss) => {
                            subScenes.push({
                                sceneId: cs.id,
                                subSceneId: ss.id,
                                name: `- ${ss.name}`
                            });
                        });
                    }
                })
            }
            return <Block key={index}>
                <BlockTitle large={true}>
                    {scene.name || `Scene ${scene.id}`}
                </BlockTitle>
                <List>
                    {subScenes.map((subScene, index) => {
                        return <ListItem key={index}
                            title={subScene.name}
                            link="#"
                            onClick={() => this.onSelectScene(subScene.sceneId, subScene.name, subScene.subSceneId)}></ListItem>
                    })}
                </List>
            </Block>
        });

        return (
            <div>
                {loaded
                    ? scenesList
                    : 'loading...'
                }
            </div>
        )
    }
}

export default ArWebIndex;
