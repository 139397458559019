import React from 'react';
import { connect } from 'react-redux';
import { f7, Page, Navbar, Block, PageContent } from 'framework7-react';
import Commons from '../commons'
import PikkartNavbar from '../components/pk-navbar/pk-navbar';
import ContentListCategoryItem from './content-list-category-item';
import * as actions from '../redux/actions';

class MenuList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        let item = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={item.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={item.catIndex > 1}
                        showMenu={Commons.isSideMenuAllowed(f7)} />
                </Navbar>

                {this.getItemList(item)}
            </Page>
        )
    }

    getItemList = (item) => {

        const itemData = item.items;
        const ret = itemData.map((item, index) => {

            return (<ContentListCategoryItem
                centerTitle={true}
                contentData={{ content: item }}
                key={'info-content-' + index} onCardClickHandler={(item) => {
                    this.onCardClick(item);
                }}></ContentListCategoryItem>);
        });

        return (
            <Block style={{ padding: '0', margin: '0' }}>
                <PageContent style={{ padding: '0' }}>{ret}</PageContent>
            </Block>)
    }

    onCardClick = (item) => {
        this.props.f7router.navigate(Commons.getMenuItemUrl(item.content));
    }

    componentDidMount = () => {
        if (this.props.subMenu) {
            const subMenuToGo = this.props.subMenu;
            const menuItem = Commons.getMenuItemFromCode(subMenuToGo.code, this.props.menu);
            console.log('must go to submenu', menuItem)
            const item = { content: menuItem }
            this.onCardClick(item);
        }

        this.props.setSubMenuToOpen(null);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        subMenu: state.app.subMenu,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSubMenuToOpen: (subMenu) => dispatch(actions.setSubMenuToOpen(subMenu)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);