import React, { useEffect, useState } from "react";
import Chat, { CHAT_SUBJECT, UI_DEBUG_DESIGN } from "./ai-chat";
import { useDispatch, useSelector } from "react-redux";
import {
  setSplashRequestedMessage,
  setIsSplashRequestMessagePending,
} from '../../redux/actions';
import OpenAiGenerate from "../../pikkart-cms/ai-chat/generate";
import localeStrings, { mockData } from "./chat-local";
import { isAndroid, isBrowser, isIOS } from "react-device-detect";
import GeoCodingApi from "../../pikkart-cms/geocoding";
import { Geolocation } from "@capacitor/geolocation";

export default (props) => {
  //oggetto con le coordinate latitudine e longitudine dell'utente
  const [userCoordinates, setUserCoordinates] = useState(null);
  //stringa con la città dell'utente
  const [userCity, setUserCity] = useState("");
  const [userRoad, setUserRoad] = useState("");
  const [geoPermissionStatus, setGeoPermissionStatus] = useState("denied");

  const dispatch = useDispatch();
  //#endregion

  const sendFirstMessage = (chatSubject, city) => {
    console.log("sendFirstMessage");
    const subject =
      chatSubject === CHAT_SUBJECT.TOURISM
        ? localeStrings.tourist_sites
        : chatSubject === CHAT_SUBJECT.RESTAURANTS
          ? localeStrings.restaurants
          : localeStrings.hotels;
    const text = localeStrings.formatString(
      localeStrings.entry_message,
      subject,
      city
    );

    text.replace(/\n/g, "<br>").trim();
    let chatGptMessages = [];
    chatGptMessages.push({
      text,
    });
    const messagesArrayToSend = chatGptMessages.map((message, index) => {
      return { role: "assistant", content: message.text };
    });
    dispatch(setIsSplashRequestMessagePending(true));
    if (UI_DEBUG_DESIGN) {
      setTimeout(() => {
        dispatch(setSplashRequestedMessage(mockData.first_message(subject)));
      }, 2000);
      dispatch(setIsSplashRequestMessagePending(false));
    } else {
      OpenAiGenerate.createCmsChatCompletion(messagesArrayToSend)
        .then((response) => {
          dispatch(setIsSplashRequestMessagePending(false));
          dispatch(
            setSplashRequestedMessage({
              chatGptMessage: response.chatGptMessage,
              usage: response.usage,
            })
          );
        })
        .catch((err) => {
          dispatch(setIsSplashRequestMessagePending(false));
          console.log("err", err);
        });
    }

    // sendMessage(entryMessageText);
    // setTextBarEnabled(true);
  };

  const sendNoLocationMessage = () => {
    dispatch(setIsSplashRequestMessagePending(true));
    setTimeout(() => {
      dispatch(
        setSplashRequestedMessage({
          chatGptMessage: {
            message: {
              role: "assistant",
              content: localeStrings.no_geolocation_entry_message,
            },
          },
        })
      );
    }, 500);
    dispatch(setIsSplashRequestMessagePending(false));
  };

  const onLocationFound = (location) => {
    const { city, road } = location;
    setUserCity(city);
    setUserRoad(road);

    sendFirstMessage(CHAT_SUBJECT.TOURISM, city);
  };

  //chiamato quando per qualsiasi motivo non ho ottenuto la posizione dell'utente
  const onCurrentLocationFailed = () => {
    sendNoLocationMessage();
  };

  const getBrowserLocation = () => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject('navigator not supported');
      }
    });
  };

  const getAppLocation = () => {
    return new Promise((resolve, reject) => {
      console.log("get app location");
      // if (isIOS) {
      //   navigator.permissions
      //     .query({ name: "geolocation" })
      //     .then(function (result) {

      //       const onLocationFetchSuccess = (position) => {
      //         resolve(position);
      //         /*
      //            Consume location coordinates here and proceed as required
      //            using position.coords.latitude and position.coords.longitude
      //         */
      //       };

      //       const onLocationFetchFailure = (error = {}) => {
      //         // Error code 1 corresponds to user denying/blocking the location permission
      //         if (error.code === 1) {
      //           // Respond to failure case as required
      //         }

      //         reject(error);
      //       };

      //       Geolocation.getCurrentPosition()
      //         .then((position) => {
      //           onLocationFetchSuccess(position);
      //         })
      //         .catch((err) => {
      //           onLocationFetchFailure();
      //         })

      //       // This will still work for Chrome
      //       result.onchange = function () {
      //         if (result.state === 'denied') {
      //           onLocationFetchFailure();
      //         }
      //       }
      //     })
      // } else if (isAndroid) {
      //SU ANDROID VALUTO SE PRENDERE PRIMA LA POSIZIONE PER FAR DARE I PERMESSI
      //O USARE UN PLUGIN APPOSTA

      console.log("ask android permissions");
      askAndroidLocationPermissions()
        .then((permissions) => {
          console.log("permissions ", permissions);
          resolve(permissions);
        })
        .catch((err) => {
          console.log("permiss err", err);
          reject(err);
        });
      // }
    });
  };

  const askAndroidLocationPermissions = () => {
    return new Promise((resolve, reject) => {

      const geolocationError = (error) => {
        console.log('geolocation error', error);
        reject(error);
      }
      const geolocationSuccess = (success) => {
        console.log('geolocation success', success);
        resolve(success)
      }
      navigator.geolocation.getCurrentPosition(geolocationSuccess,
        geolocationError);
    });
  };

  useEffect(() => {
    console.log("geoPermissionStatus");
    // if (geoPermissionStatus !== 1) {
    //   getLocation();
    // }
  }, [geoPermissionStatus]);

  const getPlatformLocation = () => {
    return new Promise((resolve, reject) => {
      if (isBrowser) {
        getBrowserLocation()
          .then((position) => {
            resolve(position);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        getAppLocation()
          .then((position) => {
            resolve(position);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  const getLocation = () => {
    getPlatformLocation()
      .then((position) => {
        setUserCoordinates({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });

        GeoCodingApi.reverseCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
          .then((response) => {
            const { city, road } = response;
            onLocationFound({ city, road });
          })
          //.then((city, road) => {
          //  onLocationFound({ city, road });
          //})
          .catch((err) => {
            onCurrentLocationFailed();
          });
      })
      .catch((error) => {
        onCurrentLocationFailed();
      });
  };
  useEffect(() => {
    if (!userCity) {
      getLocation();
    }
  }, [userCity]);

  return (
    <Chat
      {...props}
      userCity={userCity}
      userRoad={userRoad}
      onChangeSubject={(chatSubject) => {
        sendFirstMessage(chatSubject, userCity);
      }}
    />
  );
};
