import React from 'react';
import { connect } from 'react-redux';
import { Navbar, Page } from 'framework7-react';
import { PkCordovaARSdk, PkCordova } from 'pikkart-cordova';
import { AnalyticsRegister, ANALYTICS_EVENT, ANALYTICS_SCREEN } from '../analytics-register'
import DetailView from './detail-view';
import PikkartNavbar from '../components/pk-navbar/pk-navbar';
import Commons from '../commons';

class ContentDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        let customTitleClass;
        if (process.env.REACT_APP_CODE === 'unique') {
            console.log('app is unique', this.props.contentItem);
            if (this.props.contentItem.content.catId === 18569) {
                customTitleClass = 'unique-product-title'
            }
            else {
                customTitleClass = 'unique-content-title'
            }
        }
        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={this.props.contentItem ? this.props.contentItem.content.title : ""}
                        logo='./img/logo/logo.png'
                        showNavRight={true}
                        showArrowAsNavRight={true}
                        customTitleClass={customTitleClass} />
                </Navbar>
                <DetailView
                    isImageVisible={true}
                    isDetailVisible={true}
                    item={this.props.contentItem}
                    showCloseButton={true}
                    showTitle={false}
                    currentCompany={this.props.currentCompany}
                    onInfoClickHandler={this.props.onInfoClickHandler}
                    closeButtonFn={this.closeModalViewClick}
                    router={this.props.f7router} />
            </Page>)

        // TODO: controllare se l'item ha una descrizione altrimenti si avvia direttamente la fotocamera (solo in ar)
    }


    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.CONTENT_DETAIL);
        if (this.props.contentItem) {
            this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_CONTENT_DETAIL, this.props.contentItem)
        }
    }

    closeModalViewClick = () => {
        if (PkCordova.isCordova())
            PkCordovaARSdk.closeModalView();
    }

    registerAnalyticsEvent = (eventName, contentItem) => {
        if (contentItem.content && contentItem.content.title && contentItem.content.id)
            AnalyticsRegister.logEvent(
                eventName,
                {
                    ContentName: contentItem.content.title,
                    ContentId: contentItem.content.id,
                    ContentType: Commons.getStringContentView(contentItem.content.ctnView),
                });
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetail);