import PkApiNet from "./api/net";

export class GeoCodingAPI {
  static reverseCoordinates = (coords) => {
    return new Promise((resolve, reject) => {
      const lat = coords.latitude;
      const lon = coords.longitude;

      PkApiNet.callApi({
        method: "get",
        url:
          "https://geocode.maps.co/reverse?lat=" +
          // "https://nominatim.openstreetmap.org/reverse?lat=" +
          lat +
          "&lon=" +
          lon,
      })
        .then((response) => {
          // const city = response.address.city ? response.address.city : response.address.county;
          // const road = response.address.road ? response.address.road : "";

          // USA SOLO display_name
          const city = response.display_name ? response.display_name : "";
          const road = "";

          resolve({ city, road });
        })
        .catch(function (response) {
          reject(response);
        });
    });
  };
}

export default GeoCodingAPI;
