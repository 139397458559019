import { PkCordova, PkCordovaAnalytics } from 'pikkart-cordova';

export class AnalyticsRegister {
    static logEvent = (name, params) => {
        console.log('log analytics event ' + name, params);
        if (PkCordova.isCordova())
            PkCordovaAnalytics.logEvent(name, params);
    }

    static setCurrentScreen = (name) => {
        console.log('set analytics screen ', name);
        if (PkCordova.isCordova())
            PkCordovaAnalytics.setCurrentScreen(name);
    }
}

export const ANALYTICS_EVENT = {
    OPEN_CONTENT_DETAIL: 'Open_Content_Detail',
    OPEN_AR_SCENE_DETAIL: 'Open_Ar_Scene_Detail',
    OPEN_CONTENT_KML: 'Open_Content_Kml',
    ADD_TO_FAVORITES: 'Add_To_Favorites',
    SAVE_PROFILE_DATA: 'Save_Profile_Data',
    WEB_SITE_URL_CLICK: 'Click_Website_Url',
    PHONE_NUMBER_CLICK: 'Click_Phone_Number',
    EMAIL_CLICK: 'Click_Email',
    ATTACHMENT_CLICK: 'Click_Attachment',
    START_AR_SCENE: 'Start_Ar_Scene',
    SHARE_URL_CLICK: 'Click_Share_Url',
    START_AR_DISCOVER: 'Start_Ar_Discover',
    TAKE_PHOTO: 'Click_Snap_Photo',
    PROCESS_DEEP_MODEL_ERROR: 'Process_Deep_Model_Error',
    OPEN_RESULT_DETAIL: 'Open_Result_Detail',
    OPEN_FAMILY_LIST: 'Open_Family_List',
    OPEN_CATALOG_LIST: 'Open_Catalog_List',
};


export const ANALYTICS_SCREEN = {
    MAIN: 'Main',
    PROFILE: 'Profile',
    CREDITS: 'Credits',
    TUTORIAL: 'Tutorial',
    NOTIFICATIONS: 'Notifications',
    CONTENT_KML: 'Content_KML',
    CONTENT_DETAIL: 'Content_Detail',
    AR_SCENE_LIST: 'Ar_Scene_List',
    MEMBERS_AREA: 'Members_Area',
    LOGIN: 'Login',
    DISCOVER_MODEL_EDITOR: 'Discover_model_editor',
    DISCOVER_VIEW_DETAIL: 'Discover_view_detail',
    DISCOVER_CONTENT_CREATE: 'Discover_content_create',
    SCAN_CAMERA: 'Scan_Camera',
    SCAN_RESULTS: 'Scan_Results',
    NO_CONNECTION: 'No_connection',
    AR_WEB: 'Ar_Web',
};

export default AnalyticsRegister;