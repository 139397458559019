import React from 'react';
import { connect } from 'react-redux';
import { isTablet, isBrowser, isIOS } from "react-device-detect";
import { Card, CardContent, SkeletonBlock } from 'framework7-react';
import localeStrings from './checklist-results-local';

class CheckListListItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            effect: 'blink',
        };
    }

    render() {

        let item = this.props.checkListElement;


        const skel = (this.state.loading) ? `skeleton-text skeleton-effect-${this.state.effect}` : "";

        let image = () => {
            return (
                <LazyBackgroundImage
                    item={item}
                    imageUrl={JSON.parse(item.data).image}
                    component={this}
                    isContentEditable={this.props.isContentEditable}>
                    {this.state.loading ? < SkeletonBlock style={{ width: '40px', height: '40px', 'border-radius': '50%' }} slot="media" /> : null}
                </LazyBackgroundImage>);
        }

        return (
            <Card noShadow={true} noBorder={true}>
                <CardContent className={skel} style={{ padding: '0' }}>
                    <span onClick={(e) => { this.onCardClick(item) }}>

                        {image()}

                        {/* <figure className="lazy lazy-fadeIn" style={{ backgroundImage: 'url(' + item.content.imageUrl + ')', alignContent: 'center', objectFit: 'center/cover', maskPosition: 'no-repeat' }}>
                            <h2 className="title card-content-padding">{item.content.title}</h2>
                        </figure> */}
                    </span>
                </CardContent>
                {/* {contentDescr} */}
            </Card>
        )
    }

    onCardClick = (item) => {
        if (this.props.onCardClickHandler) {
            this.props.onCardClickHandler(item);
        }
    }

    componentDidMount = () => {

    }

    imageLoaded = () => {
        console.log('image loaded');
        this.setState({ loading: false });
    }
};


const LazyBackgroundImage = ({ item, component, isContentEditable, customTitleClass }) => {
    const [source, setSource] = React.useState("preload.jpg")

    React.useEffect(() => {
        const img = new Image()
        img.src = JSON.parse(item.data).image
        img.onload = () => {
            setSource(JSON.parse(item.data).image)
            component.imageLoaded();
        }
    }, [component, JSON.parse(item.data).image])

    const backgroundrepeat = (isTablet || isBrowser) ? 'inherit' : 'no-repeat';

    let titleClass = "title card-content-padding ";
    if (component.props.centerTitle && JSON.parse(process.env.REACT_APP_ENABLE_CENTERED_TITLE_FOR_CATEGORIES)) {
        if (isIOS) {
            titleClass += 'card-title-center-ios';
        }
        else {
            titleClass += 'card-title-center';
        }
    }
    if (customTitleClass)
        titleClass += customTitleClass

    return (
        <figure style={{
            backgroundImage: 'url(' + source + ')',
            alignContent: 'center',
            objectFit: 'center/cover',
            maskPosition: 'no-repeat',
            backgroundRepeat: backgroundrepeat,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }}>
            <div className="content-title-container" style={{ display: "flex", flexDirection: "column" }}>
                <h2 className={titleClass} /*style={{ color: titleTextColor }}*/>{item.workstationName}</h2>
                <h2 className={titleClass} /*style={{ color: titleTextColor }}*/>{localeStrings.result_block_title + ": " + item.result.message}</h2>
            </div>
        </figure>
    )
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CheckListListItem);