import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        name: "Nome",
        name_placeholder: "Il tuo nome",
        surname: "Cognome",
        surname_placeholder: "Il tuo cognome",
        email: "Email",
        email_placeholder: "La tua e-mail",
        city: "Città",
        city_placeholder: "Inserisci la città",
        address_zip: "CAP",
        address_zip_placeholder: "Inserisci il CAP",
        province: "Provincia",
        province_placeholder: "Inserisci la provincia",
        country: "Nazione",
        country_placeholder: "Inserisci la nazione",
        profession: "Professione",
        profession_placeholder: "Inserisci la professione",
        phone: "Telefono",
        phone_placeholder: "Inserisci il numero di telefono",
        mobile_phone: "Cellulare",
        mobile_phone_placeholder: "Inserisci il numero di cellulare",
        privacy: "Privacy",
        privacy_text: "Con questo confermo che ho letto compreso ed accettato i termini dell'Informativa sul trattamento dei dati personali",
        privacy_link_text: "Vai alla pagina della privacy",
        privacy_site: "https://www.irisceramicagroup.com/terms-conditions/",
        privacy_not_checked_error: "Accetta i termini della privacy per continuare",
        newsletter: "Newsletter",
        newsletter_text: "Desidero ricevere la newsletter di Ariostea.",
        subject: "Oggetto",
        subject_placeholder: "Inserisci l'oggetto",
        message: "Messaggio",
        message_placeholder: "Inserisci il tuo messaggio",
        submit_button_text: "Invia",
        info_validation_text: "* campo obbligatorio",
        required_validation_text: "*",
        required_validation_empty_text: "Compila questo campo",
        required_validation_empty_list_text: "Seleziona un elemento della lista",
        email_format_wrong_text: "Formato email non valido",
        format_wrong_text: "Formato non valido",
        not_valid_fields_error: "Alcuni campi non sono stati inseriti correttamente",
        profession_architect: "Architetto/Designer",
        profession_construction_industry: "Impresa Costruzioni",
        profession_dealer: "Rivenditore",
        profession_engineer: "Ingegnere",
        profession_tile_worker: "Marmista/Posatore",
        profession_private_customer: "Consumatore Privato",
        contact_from_error_title: "Invio non riuscito",
        contact_from_error_text: "C'è stato un errore con l'invio dei tuoi dati",
        contact_from_success_title: "Dati inviati",
        contact_from_success_text: "I dati sono stati inviati correttamente",
    },
    it: {
        name: "Nome",
        name_placeholder: "Il tuo nome",
        surname: "Cognome",
        surname_placeholder: "Il tuo cognome",
        email: "Email",
        email_placeholder: "La tua e-mail",
        city: "Città",
        city_placeholder: "Inserisci la città",
        address_zip: "CAP",
        address_zip_placeholder: "Inserisci il CAP",
        province: "Provincia",
        province_placeholder: "Inserisci la provincia",
        country: "Nazione",
        country_placeholder: "Inserisci la nazione",
        profession: "Professione",
        profession_placeholder: "Inserisci la professione",
        phone: "Telefono",
        phone_placeholder: "Inserisci il numero di telefono",
        mobile_phone: "Cellulare",
        mobile_phone_placeholder: "Inserisci il numero di cellulare",
        privacy: "Privacy",
        privacy_text: "Con questo confermo che ho letto compreso ed accettato i termini dell'Informativa sul trattamento dei dati personali",
        privacy_link_text: "Vai alla pagina della privacy",
        privacy_site: "https://www.irisceramicagroup.com/terms-conditions/",
        privacy_not_checked_error: "Accetta i termini della privacy per continuare",
        newsletter: "Newsletter",
        newsletter_text: "Desidero ricevere la newsletter di Ariostea.",
        subject: "Oggetto",
        subject_placeholder: "Inserisci l'oggetto",
        message: "Messaggio",
        message_placeholder: "Inserisci il tuo messaggio",
        submit_button_text: "Invia",
        info_validation_text: "* campo obbligatorio",
        required_validation_text: "*",
        required_validation_empty_text: "Compila questo campo",
        required_validation_empty_list_text: "Seleziona un elemento della lista",
        email_format_wrong_text: "Formato email non valido",
        format_wrong_text: "Formato non valido",
        not_valid_fields_error: "Alcuni campi non sono stati inseriti correttamente",
        profession_architect: "Architetto/Designer",
        profession_construction_industry: "Impresa Costruzioni",
        profession_dealer: "Rivenditore",
        profession_engineer: "Ingegnere",
        profession_tile_worker: "Marmista/Posatore",
        profession_private_customer: "Consumatore Privato",
        contact_from_error_title: "Invio non riuscito",
        contact_from_error_text: "C'è stato un errore con l'invio dei tuoi dati",
        contact_from_success_title: "Dati inviati",
        contact_from_success_text: "I dati sono stati inviati correttamente",
    }
});

export default localeStrings;