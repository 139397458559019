import React from 'react';
import { Link } from 'framework7-react';

function ArWebGUI(props) {
    // const [isIntroVisible, setIsIntroVisible] = React.useState(true);
    // const [isIntroFading, setIsIntroFading] = React.useState(false);

    // useEffect(() => {
    //     if (props.hittest_found) {
    //         setTimeout(() => {
    //             setIsIntroFading(true);
    //         }, 600);

    //         setTimeout(() => {
    //             setIsIntroVisible(false);
    //         }, 1000);
    //     }
    // }, [props.hittest_found]);

    return (
        <div>
            <Link
                back
                className='ar-web-back-button'
                onClick={props.onBackButtonClicked}>
                <img
                    style={{ width: '48px' }}
                    src="./img/ar_resources/back_arrow_icon.png"
                    alt="back_button" />
            </Link>

            <Link
                className='ar-web-screenshot-button'
                onClick={props.onScreenshotButtonClicked}>
                <img
                    style={{ width: '36px' }}
                    src="./img/ar_resources/screenshot_icon.png"
                    alt="screenshot_button" />
            </Link>
            {(props.planeFound && !props.scenePlaced) &&
                <Link
                    className='ar-web-reposition-scene-button'
                    onClick={props.onConfirmPlacementSceneButtonClicked}>
                    <img
                        style={{ width: '38px' }}
                        src="./img/ar_resources/confirm_placement_icon.png"
                        alt="confirm_placement_scene" />
                </Link>}

            {(props.planeFound && props.scenePlaced) &&
                <Link
                    className='ar-web-reposition-scene-button'
                    onClick={props.onRepositionSceneButtonClicked}>
                    <img
                        style={{ width: '48px' }}
                        src="./img/ar_resources/plane_search.png"
                        alt="reposition_scene" />
                </Link>
            }
        </div>
    );
}

export default ArWebGUI;
