import React from 'react';
import { connect } from 'react-redux';
import { Page } from 'framework7-react';
import { v1 as uuid } from 'uuid';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register'
// import localeStrings from './ar-web-local';
import {
    Vector3,
	Vector4,
    Color3,
    PointLight,
    HemisphericLight,
    MeshBuilder,
    StandardMaterial,
    Texture,
    SceneLoader,
    Quaternion,
    WebXRHitTest,
    TransformNode,
    Axis,
    Space,
    Tools,
    WebXRDomOverlay,
    WebXRState,
    PointerEventTypes,
    ArcRotateCamera,
    FreeCamera,
    PhysicsShapeType,
    PhysicsAggregate,
	VideoTexture,
	Effect,
	ShaderMaterial,
	Sound,
} from '@babylonjs/core';
import '@babylonjs/loaders';
import SceneComponent from 'babylonjs-hook';
import { PkLog } from '../../pikkart-cms/log';
import PkArViewInvolve from "../../pikkart-cms/ar-view/involve";
import styles from './ar-web.module.css';
import ArWebIntro from "./ar-web-intro";
import ArWebLoading from "./ar-web-loading";
import { STATUS } from './utils/STATUS.js';
import { TYPE } from './utils/TYPE.js';
import { SCENE_LOADING_STATUS } from './utils/SCENE_LOADING_STATUS.js';
import ArWebGUI from './ar-web-gui';
import { GridMaterial } from '@babylonjs/materials';

// import HavokPhysics from "@babylonjs/havok";

// media type definitions
const MEDIA_TYPE_VIDEO = 'Video';
const MEDIA_TYPE_3D = 'Object3D';
const MEDIA_TYPE_AUDIO = 'Audio';
const MEDIA_TYPE_IMAGE = 'Image';
const MEDIA_TYPE_BUTTON = 'Button';
const MEDIA_TYPE_CONTROL = 'VideoControl';

const MEDIA_ACTION_TYPE_NONE = "NONE";
const MEDIA_ACTION_TYPE_TELEPHONE = "TEL";
const MEDIA_ACTION_TYPE_URL = "URL";

// rotation conversion from cms to editor
const rotFromCmsToEditor = (v) => {
    const q = new Quaternion(0.0, 0.0, 0.0, 0.0);
    const cx = Math.cos(v.x * 0.5);
    const cy = Math.cos(v.y * 0.5);
    const cz = Math.cos(v.z * 0.5);
    const sx = Math.sin(v.x * 0.5);
    const sy = Math.sin(v.y * 0.5);
    const sz = Math.sin(v.z * 0.5);
    q.w = cx * cy * cz + sx * sy * sz;
    q.x = sx * cy * cz - cx * sy * sz;
    q.y = cx * sy * cz + sx * cy * sz;
    q.z = cx * cy * sz - sx * sy * cz;
    return q;
}

const convertFloatV3 = (vect3) => {
	let isString = value => typeof value === 'string' || value instanceof String;
	let { x, y, z } = vect3;
	if(isString(x))
		x = parseFloat(x);
	if(isString(y))
		y = parseFloat(y);
	if(isString(z))
		z = parseFloat(z);
	return new Vector3( x, y, z );
}

// convert media orientation data from cms to editor
const convertOrientation = (orientation, immersiveArSupported) => {
	const { _x, _y, _z } = orientation;
	if (immersiveArSupported == false) {
		const orientationVector = new Vector3(-1.0 * _x, _y, -1.0 * _z);
		const rotQuaternion = rotFromCmsToEditor(orientationVector);
		return rotQuaternion.toEulerAngles();
	} else {
		//let rotQuaternion = rotFromCmsToEditor(new BABYLON.Vector3(-1.0 * element.orientationX, element.orientationY, -1.0 * element.orientationZ));
		const orientationVector = new Vector3(-1.0 * _x, _y, -1.0 * _z);
		const rotQuaternion = rotFromCmsToEditor(orientationVector);
		let rotationQuaternion = Quaternion.FromEulerVector(new Vector3(Math.PI/2, 0.0, 0.0));
		rotationQuaternion = rotationQuaternion.multiply(rotQuaternion);
		return rotationQuaternion.toEulerAngles();
	}
}

const convertPosition = (position, immersiveArSupported) => { 
	const { _x, _y, _z } = position;
	if (immersiveArSupported == false) {
		let yb = _y * -1.0;
		return new Vector3( _x, yb, _z );
	} else {
		let yb = _z * -1.0;
		let zb = _y * -1.0;
		return new Vector3(_x,yb,zb);
	}
}

// scene media object constructor
class SceneMedia {
    constructor(media = {}) {
        this.id = media.id;
		this.media_id = media.media.id;
        this.name = media.media.name;
        this.mediaType = media.media.mediaType;
        this.fileUrl = media.media.fileUrl;
        this.editorPreviewUrl = media.media.editorPreviewUrl;
        this.uuid = uuid();
        this.mediaAction = media.mediaAction || {
            loop: false,
            alwaysRestart: false,
            autoReplay: false,
            actionType: MEDIA_ACTION_TYPE_NONE,
            actionValue: ''
        }
        this.pos =  media.pos ? convertFloatV3(media.pos) : { x: 0.0, y: 0.0, z: 0.0 }; //
        this.scale = media.scale ? convertFloatV3(media.scale) : { x: 0.5, y: 0.5, z: 0.5 };
        this.orientation = media.orientation ? convertFloatV3(media.orientation) : { x: 0.0, y: 0.0, z: 0.0 };//convertedOrientationForEditor(media.orientation)
			
		if(media.media.settings) {
			this.video_greenScreen = media.media.settings.video_greenScreen;
			if (this.video_greenScreen==true) {
				this.video_chromaKeyRgb = media.media.settings.video_chromaKeyRgb.split(",").map(Number);
			} else {
				this.video_chromaKeyRgb = null;
			}
			this.height = media.media.settings.height;
			this.width = media.media.settings.width;
		}
		
		this.meshes = [];
		this.node = undefined;
    }
}

class SceneNode {
	constructor(id, scenemedia=undefined) {
		this.id = id;
		this.scenemedia=scenemedia;
		this.scenemedianode=undefined;
		this.child=[];
		this.parent=undefined;
	}
}

/**
 * Script hooks
 */
 
const findSceneMedia = (id_long_form, media_array) => {
	let id_spl = id_long_form.split('_');
	let id_s=id_spl[id_spl.length-1];
	let id = parseInt(id_s);
	let scene_media = undefined;
	media_array.forEach((media, index) => {
		if(media.id===id) {
			scene_media = media;
		}
	});
	return scene_media;
}

const findSceneNode = (id, nodes_array) => {
	let scene_node = undefined;
	nodes_array.forEach((node, index) => {
		if(node.id==id) {
			scene_node = node;
		}
	});
	return scene_node;
}
 
window._CreateActor = (object_name,object_file,object_desc) => {
	//console.log('_CreateActor');
	if(window.main_scene) {
		let scene_media = findSceneMedia(object_name, window.main_scene_media);
		if(scene_media==undefined) {
			console.log("ERROR!! scene media not found, should never happen!");
			return;
		}
		let scene_node = new SceneNode(object_name, scene_media);
		scene_node.scenemedianode = scene_media.node;
		window.main_scene.sceneNodes.push(scene_node);
	}
};
window._CreateGLTFActor = (object_name,object_file,object_desc) => {
	//console.log('_CreateGLTFActor');
	if(window.main_scene) {
		let scene_media = findSceneMedia(object_name, window.main_scene_media);
		if(scene_media==undefined) {
			console.log("ERROR!! scene media not found, should never happen!");
			return;
		}
		let scene_node = new SceneNode(object_name, scene_media);
		scene_node.scenemedianode = scene_media.node;
		window.main_scene.sceneNodes.push(scene_node);
	}
};
window._CreateVideo = (object_name,video_file,video_material_file,object_desc) => {
	//console.log('_CreateVideo');
	if(window.main_scene) {
		let scene_media = findSceneMedia(object_name, window.main_scene_media);
		if(scene_media==undefined) {
			console.log("ERROR!! scene media not found, should never happen!");
			return;
		}
		let scene_node = new SceneNode(object_name, scene_media);
		scene_node.scenemedianode = scene_media.node;
		window.main_scene.sceneNodes.push(scene_node);
	}
};
window._CreateTextActor = (object_name, text, type) => {
	console.log('TODO: _CreateTextActor not yet implemented');
};
window._CreateAudio = (object_name,audio_file,object_desc) => {
	//console.log('_CreateAudio');
	if(window.main_scene) {
		let scene_media = findSceneMedia(object_name, window.main_scene_media);
		if(scene_media==undefined) {
			console.log("ERROR!! scene media not found, should never happen!");
			return;
		}
		scene_media.autostart=true;
		let scene_node = new SceneNode(object_name, scene_media);
		scene_node.scenemedianode = scene_media.node;
		window.main_scene.sceneNodes.push(scene_node);
	}
};
window._CreateGroup = (group_name,group_desc) => {
	//console.log('_CreateGroup');
	if(window.main_scene) {
		let scene_node = new SceneNode(group_name, undefined);
		scene_node.scenemedianode = new TransformNode(`sceneNode_${group_name}`);
		scene_node.scenemedianode.ignoreNonUniformScaling = true;
		scene_node.scenemedianode.position = new Vector3(0.0, 0.0, 0.0);
		scene_node.scenemedianode.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0.0, 0.0, 0.0));
		scene_node.scenemedianode.scaling = new Vector3(1.0, 1.0, 1.0);
		scene_node.scenemedianode.markAsDirty();
		window.main_scene.sceneNodes.push(scene_node);
	}
};
window._CreateAnimatedActor = (object_name,object_file,skeleton_file,object_desc) => {
	console.log('WARNING _CreateAnimatedActor deprecated, not implemented anymore, use GLTFs instead!');
};
window._CreateSound = (object_name,audio_file,object_desc) => {
	//console.log('_CreateSound');
	if(window.main_scene) {
		let scene_media = findSceneMedia(object_name, window.main_scene_media);
		if(scene_media==undefined) {
			console.log("ERROR!! scene media not found, should never happen!");
			return;
		}
		scene_media.autostart=false;
		let scene_node = new SceneNode(object_name, scene_media);
		scene_node.scenemedianode = scene_media.node;
		window.main_scene.sceneNodes.push(scene_node);
	}
};
window._CreateParticleSystem = (object_name,object_desc,numParticles,energy_variance,energy_drag,fade_drag,fade_variance,starting_size,size_variance,size_drag,starting_positionX,starting_positionY,starting_positionZ,position_variance,gravityX,gravityY,gravityZ,emitter_directionPitch,emitter_directionYaw,speed,dir_variance,speed_variance,starting_momentumX,momentum_variance,velocity_drag,momentum_drag,starting_colorR,starting_colorG,starting_colorB,final_colorR,final_colorG,final_colorB,generation_rate,vertex_shader_file, fragment_shader_file, texture_file) => {
	console.log('TODO: _CreateParticleSystem not yet implemented!');
};
window._PlaySound = (object_name, start_pos_seconds) => {
	//console.log('_PlaySound');
	let node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(node!=undefined) {
		if(start_pos_seconds==undefined)
			node.scenemedia.meshes[0].play();
		else node.scenemedia.meshes[0].play(0, start_pos_seconds);
	}
};
window._GetSoundTimestamp = (object_name) => {
	//console.log('_GetSoundTimestamp');
	let node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(node!=undefined) 
		return node.scenemedia.meshes[0]._offset;
	return 0;
};
window._GetSoundLength = (object_name) => {
	//console.log('_GetSoundLength');
	let node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(node!=undefined) 
		return node.scenemedia.meshes[0]._length;
	return 0;
};
window._StopSound  = (object_name) => {
	let node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(node!=undefined) {
		node.scenemedia.meshes[0].stop();
	}
}
window._CloseFloatingScene = () => {
	console.log('TODO: _CloseFloatingScene not yet implemented!');
};
window._AddAnimation = (object_name,anim_name,anim_file) => {
	console.log('WARNING _AddAnimation deprecated, not implemented anymore, USE GLTFs instead and _UpdateAnimation!');
};
//window._PlayAnimation = (object_name,anim_name,frame) => {
window._PlayAnimation = (object_name,anim_id) => {
	//console.log('_PlayAnimation');
	let node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(node!=undefined && node.scenemedia!=undefined) {
		let num_anims = node.scenemedia.meshes[0].animationGroups.length;
		if(anim_id>num_anims) return;
		let anim = node.scenemedia.meshes[0].animationGroups[anim_id]
		anim.start(true, 1.0, anim.from, anim.to, false);
	}
};
window._UpdateAnimation = (object_name,anim_id,deltaT_millis) => {
	//console.log('_UpdateAnimation');
	console.log('WARNING _UpdateAnimation deprecated, not implemented anymore for Web AR, use _PlayAnimation instead');
	//for compatibility with old script
	let node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(node!=undefined && node.scenemedia!=undefined) {
		let num_anims = node.scenemedia.meshes[0].animationGroups.length;
		if(anim_id>num_anims) return;
		let anim = node.scenemedia.meshes[0].animationGroups[anim_id]
		anim.start(true, 1.0, anim.from, anim.to, false);
	}
};
window._AddToGroup = (group_name,asset_name) => {
	//console.log('_AddToGroup');
	let group_node = findSceneNode(group_name, window.main_scene.sceneNodes);
	let asset_node = findSceneNode(asset_name, window.main_scene.sceneNodes);
	if(group_node==undefined || asset_node==undefined || group_node.scenemedianode==undefined || asset_node.scenemedianode==undefined) return;
	group_node.child.push(asset_node);
	asset_node.parent=group_node;
	asset_node.scenemedianode.setParent(group_node.scenemedianode);
	group_node.scenemedianode.markAsDirty();
	asset_node.scenemedianode.markAsDirty();
	asset_node.scenemedianode.setEnabled(true);
};
window._AddToScene = (asset_name) => {
	//console.log('_AddToScene');
	let asset_node = findSceneNode(asset_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	asset_node.scenemedianode.setParent(window.main_scene.arSceneNode);
	window.main_scene.sceneNodesRoot = asset_node;
	asset_node.scenemedianode.setEnabled(true);
};
window._SetPosition = (object_name,px,py,pz) => {
	//console.log('_SetPosition');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	let pos = convertFloatV3({ x: px, y: py, z: pz });
	asset_node.position = pos.clone();
	asset_node.scenemedianode.position = convertPosition(pos, window.immersiveArSupported); ;
	asset_node.scenemedianode.markAsDirty();
};
window._SetOrientation = (object_name,rx,ry,rz) => {
	//console.log('_SetOrientation');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;	
	let rot = convertFloatV3({ x: rx, y: ry, z: rz });
	asset_node.rotation = rot.clone();
	rot = convertOrientation(rot, window.immersiveArSupported);
	asset_node.scenemedianode.rotationQuaternion = Quaternion.FromEulerVector(rot);
	asset_node.scenemedianode.markAsDirty();
};
window._SetScale = (object_name,sx,sy,sz) => {
	//console.log('_SetScale');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	let scl = convertFloatV3({ x: sx, y: sy, z: sz });
	asset_node.scaling = scl.clone();
	asset_node.scenemedianode.scaling = scl;
	asset_node.scenemedianode.markAsDirty();
};
window._Move = (object_name,tx,ty,tz) => {
	//console.log('_Move');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	let pos = convertFloatV3({ x: tx, y: ty, z: tz });
	asset_node.position.addInPlace(pos);
	pos = convertPosition(pos, window.immersiveArSupported); 
	asset_node.scenemedianode.position = asset_node.scenemedianode.position.add(pos); 
	asset_node.scenemedianode.markAsDirty();
};
window._Rotate = (object_name,rx,ry,rz) => {
	//console.log('_Rotate');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	let rot = convertFloatV3({ x: rx, y: ry, z: rz });
	asset_node.rotation.addInPlace(rot);
	rot = convertOrientation(rot, window.immersiveArSupported);
	let rot2 = asset_node.scenemedianode.rotationQuaternion.toEulerAngles();
	asset_node.scenemedianode.rotationQuaternion = Quaternion.FromEulerVector(rot2.add(rot));
	asset_node.scenemedianode.markAsDirty();
};
window._Scale = (object_name,sx,sy,sz) => {
	//console.log('_Scale');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	let scl = convertFloatV3({ x: sx, y: sy, z: sz });
	asset_node.scaling._x *= scl._x;
	asset_node.scaling._y *= scl._y;
	asset_node.scaling._z *= scl._z;
	let old_scl = asset_node.scenemedianode.scaling;
	asset_node.scenemedianode.scaling = new Vector3(scl._x * old_scl._x, scl._y  * old_scl._y, scl._z * old_scl._z);
	asset_node.scenemedianode.markAsDirty();
};
window._ViewDependentPitch = (object_name,r) => {
	console.log('_ViewDependentPitch deprecated, not implemented anymore!');
};
window._GetPositionX = (object_name) => {
	//console.log('_GetPositionX');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.position._x;
};
window._GetPositionY = (object_name) => {
	//console.log('_GetPositionY');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.position._y;
};
window._GetPositionZ = (object_name) => {
	//console.log('_GetPositionZ');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.position._z;
};
window._GetOrientationRX = (object_name) => {
	//console.log('_GetOrientationRX');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.rotation._x;
};
window._GetOrientationRY = (object_name) => {
	//console.log('_GetOrientationRY');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.rotation._y;
};
window._GetOrientationRZ = (object_name) => {
	//console.log('_GetOrientationRZ');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.rotation._z;
};
window._GetScaleX = (object_name) => {
	//console.log('_GetScaleX');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.scaling._x;
};
window._GetScaleY = (object_name) => {
	//console.log('_GetScaleY');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.scaling._y;
};
window._GetScaleZ = (object_name) => {
	//console.log('_GetScaleZ');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	return asset_node.scaling._z;
};
window._Highlight = (object_name,numFrames) => {
	console.log('TODO: _Highlight not yet implemented!');
};
window._ChangeMaterialColor = (material_name,param_name,val1,val2,val3) => {
	console.log('_ChangeMaterialColor deprecated, not implemented anymore!');
};
window._ChangeMaterialParam4 = (material_name,param_name,val1,val2,val3,val4) => {
	console.log('_ChangeMaterialParam4 deprecated, not implemented anymore!');
};
window._ChangeMaterialParam3 = (material_name,param_name,val1,val2,val3) => {
	console.log('_ChangeMaterialParam3 deprecated, not implemented anymore!');
};
window._ChangeMaterialParam2 = (material_name,param_name,val1,val2) => {
	console.log('_ChangeMaterialParam2 deprecated, not implemented anymore!');
};
window._ChangeMaterialParam1 = (material_name,param_name,val1) => {
	console.log('_ChangeMaterialParam1 deprecated, not implemented anymore!');
};
window._LoadState = (object_name,state_name) => {
	console.log('TODO: _LoadState not yet implemented!');
};
window._SaveState = (object_name,state_name) => {
	console.log('TODO: _SaveState not yet implemented!');
};
window._SetVisible = (object_name) => {
	//console.log('_SetVisible');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	asset_node.scenemedianode.setEnabled(true);
};
window._SetInvisible = (object_name) => {
	//console.log('_SetInvisible');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	asset_node.scenemedianode.setEnabled(false);
};
window._ShowPage = (pageurl,title) => {
	console.log('_ShowPage deprecated, not implemented anymore!');
};
window._SetDetached = (isdetached) => {
	console.log('_SetDetached deprecated, not implemented anymore!');
};
window._InitExtendedTracking = (target_name) => {
	console.log('_InitExtendedTracking deprecated, not implemented anymore!');
};
window._SpecialScale = (object_name,scalex,scaley,scalez) => {
	console.log('_SpecialScale deprecated, not implemented anymore!');
};
window._Log = (txt) => {
	console.log(txt);
};
window.__SetVideoChromakey = (object_name) => {
	//console.log('__SetVideoChromakey');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	asset_node.scenemedia.meshes[0].material.setInt("enableChroma", 1);
};
window.__SetChromakeyColor = (object_name,R,G,B) => {
	//console.log('__SetChromakeyColor');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	asset_node.scenemedia.meshes[0].material.setVector4("chromaKey", new Vector4(R/255.0, G/255.0, B/255.0, 1.0));
};
window._SetSceneElementProperty = (object_name,property,value) => {
	console.log('TODO: _SetSceneElementProperty not yet implemented!');
	let asset_node = findSceneNode(object_name, window.main_scene.sceneNodes);
	if(asset_node==undefined || asset_node.scenemedianode==undefined) return;
	//"onlyonce" "loop" "restartondetection"
};
window._isSceneFloating = () => {
	//console.log('_isSceneFloating');
	return false;
};


class ArWeb extends React.Component {

    constructor(props) {
        super(props);

        this.babylonLogTag = "BABYLON_JS"
        this.TARGET_URL = "./img/ar_resources/target.png";
		this.PLAY_URL = "./img/ar_resources/play.png";
		this.REPLAY_URL = "./img/ar_resources/replay.png";
        this.logfps = false;
        this.main_scene = null;
        this.xrViewerReferenceSpace = null;

        this.arId = this.props.arId;
        this.arType = this.props.arType;

        this.camera = undefined;
		
		this.playTexture = null;
		this.replayTexture = null;
		
		

        this.state = {
            fetched: false,
            loaded: false,
            planeFound: false,
            toLoad: null,
            media: [],
            currentStatus: STATUS.UNSET,
            scenePlaced: false,
            immersiveArSupported: false,
            webXRExperience: null,
            webXRState: WebXRState.NOT_IN_XR,
			sceneScript: null,
			customScript: null,
			sceneScriptLoaded: false,
        };
    }
	
	/**
	 * scene script managing
	 */
	loadScript = (callback) => {
		const script_name = `scene_script_${this.props.sceneId}`;
		const existingScript = document.getElementById(script_name);
		if (!existingScript) {
			const url = PkArViewInvolve.getSceneScriptUrl(this.props.sceneId);
			const script = document.createElement('script');
			script.src = url;
			//script.src = './js/scenescripttest.js';
			script.id = script_name;
			document.body.appendChild(script);
			script.onload = () => { 
				if (callback) callback();
			};
		}
		if (existingScript && callback) callback();
	};
	
	initSceneScript = () => {
		window.main_scene = this.main_scene;
		window.main_scene_media = this.state.media;
		window.main_scene.sceneNodes = [];
		window.main_scene.sceneNodesRoot = undefined;
		window.immersiveArSupported = this.state.immersiveArSupported;
		window.Init(); 
		this.setState({
						sceneScriptLoaded: true,
					});
		if (this.state.immersiveArSupported==false) {
			this.visualizeScene(this.main_scene);
			this.setState({ planeFound: true });
			this.placeScene(this.main_scene);
		}
	}
	
	updateSceneScript = (millis) => {
		if(this.state.sceneScriptLoaded) {
			window.Update(millis);
		}
	}
	
	resetSceneScript = () => {
		if(this.state.sceneScriptLoaded) {
			window.Reset();
		}
	}
	
	resetDetachedSceneScript = () => {
		if(this.state.sceneScriptLoaded) {
			window.ResetDetached();
		}
	}
	
	clickObjectSceneScript = (object_name,x,y,z) => {
		//Click(object_name,x,y,z)
		if(this.state.sceneScriptLoaded) {
			window.Click(object_name,x,y,z);
		}
	}
	
	doubleClickObjectSceneScript = (object_name,x,y,z) => {
		//DoubleClick(object_name,x,y,z)
		if(this.state.sceneScriptLoaded) {
			window.DoubleClick(object_name,x,y,z);
		}
	}
	
	dragSceneScript = (x,y) => {
		//Drag(x,y)
		if(this.state.sceneScriptLoaded) {
			window.Drag(x,y);
		}
	}
	
	zoomSceneScript = (scale_factor) => {
		//Zoom(scale_factor)
		if(this.state.sceneScriptLoaded) {
			window.Zoom(scale_factor);
		}
	}
	
	registerVideoElements = (scene) => {
		Effect.ShadersStore["videoVertexShader"]= "\r\n"+
				"// Attributes\r\n"+
				"attribute vec3 position;\r\n"+
				"attribute vec2 uv;\r\n"+
				"// Uniforms\r\n"+
				"uniform mat4 worldViewProjection;\r\n"+
				"// Varying\r\n"+
				"varying vec2 vUV;\r\n"+
				"void main(void) {\r\n"+
				"    gl_Position = worldViewProjection * vec4(position, 1.0);\r\n"+
				"    vec2 tUV = uv;\r\n"+
				"    vUV = tUV;\r\n"+
				"}\r\n";
		Effect.ShadersStore["videoFragmentShader"]= "\r\n"+
				"varying vec2 vUV;\r\n"+
				"uniform sampler2D videoSampler;\r\n"+
				"void main(void) {\r\n"+
				"    gl_FragColor = texture2D(videoSampler, vUV);\r\n"+
				"}\r\n";
		
		Effect.ShadersStore["chromaVideoVertexShader"]= "\r\n"+
				"// Attributes\r\n"+
				"attribute vec3 position;\r\n"+
				"attribute vec2 uv;\r\n"+
				"// Uniforms\r\n"+
				"uniform mat4 worldViewProjection;\r\n"+
				"// Varying\r\n"+
				"varying vec2 vUV;\r\n"+
				"void main(void) {\r\n"+
				"    gl_Position = worldViewProjection * vec4(position, 1.0);\r\n"+
				"    vec2 tUV = uv;\r\n"+
				"    vUV = tUV;\r\n"+
				"}\r\n";
		Effect.ShadersStore["chromaVideoFragmentShader"]= "\r\n"+
				"varying vec2 vUV;\r\n"+
				"uniform sampler2D videoSampler;\r\n"+
				"float th = 8.0;\r\n"+
				"float a2 = 1.2;\r\n"+
				"float spill = 1.0;\r\n"+
				"float getAlpha(vec4 c){\r\n"+
				"	return 1.0 - th*(c.g-a2*(max(c.r,c.b)));\r\n"+
				"}\r\n"+
				"vec4 despill(vec4 c){\r\n"+
				"	float sub = max(c.g - mix(c.b, c.r, 0.45), 0.0);\r\n"+
				"	c.g -= sub;\r\n"+
				"	c.a -= smoothstep(0.25, 0.5, sub*c.a);\r\n"+
				"	float luma = dot(c.rgb, vec3(0.350, 0.587,0.164));\r\n"+
				"	c.r += sub*c.r*2.0*.350/luma;\r\n"+
				"	c.g += sub*c.g*2.0*.587/luma;\r\n"+
				"	c.b += sub*c.b*2.0*.164/luma;\r\n"+
				"	return c;\r\n"+
				"}\r\n"+
				"void main(void) {\r\n"+
				"	 vec4 video_color = texture2D(videoSampler, vUV);\r\n"+
				"	 video_color.a = clamp(getAlpha(video_color), 0.0, 1.0);\r\n"+
				"	 video_color = despill(video_color);\r\n"+
				"    gl_FragColor = video_color;\r\n"+
				"}\r\n";
		
		Effect.ShadersStore["selectableChromaVideoVertexShader"]= "\r\n"+
				"// Attributes\r\n"+
				"attribute vec3 position;\r\n"+
				"attribute vec2 uv;\r\n"+
				"// Uniforms\r\n"+
				"uniform mat4 worldViewProjection;\r\n"+
				"// Varying\r\n"+
				"varying vec2 vUV;\r\n"+
				"void main(void) {\r\n"+
				"    gl_Position = worldViewProjection * vec4(position, 1.0);\r\n"+
				"    vec2 tUV = uv;\r\n"+
				"    vUV = tUV;\r\n"+
				"}\r\n";
		Effect.ShadersStore["selectableChromaVideoFragmentShader"]= "\r\n"+
				"varying vec2 vUV;\r\n"+
				"uniform sampler2D videoSampler;\r\n"+
				"uniform vec4 chromaKey;\r\n"+
				"mat4 RGBtoYUV = mat4(0.257,  0.439, -0.148, 0.0,\r\n"+
				"					  0.504, -0.368, -0.291, 0.0,\r\n"+
				"					  0.098, -0.071,  0.439, 0.0,\r\n"+
				"					  0.0625, 0.500,  0.500, 1.0);\r\n"+
				"vec2 maskRange = vec2(0.1, 0.16);\r\n"+
				"float colorclose(vec3 yuv, vec3 keyYuv, vec2 tol) {\r\n"+
				"	float tmp = sqrt(pow(keyYuv.g - yuv.g, 2.0) + pow(keyYuv.b - yuv.b, 2.0));\r\n"+
				"	if (tmp < tol.x)\r\n"+
				"		return 0.0;\r\n"+
				"	else if (tmp < tol.y)\r\n"+
				"		return (tmp - tol.x)/(tol.y - tol.x);\r\n"+
				"	else\r\n"+
				"		return 1.0;\r\n"+
				"}\r\n"+
				"void main(void) {\r\n"+
				"	 vec4 video_color = texture2D(videoSampler, vUV);\r\n"+
				"	 vec4 keyYUV =  RGBtoYUV * chromaKey;\r\n"+
				"	 vec4 yuv = RGBtoYUV * video_color;\r\n"+
				"	 video_color.a = colorclose(yuv.rgb, keyYUV.rgb, maskRange);\r\n"+
				"    gl_FragColor = video_color;\r\n"+
				"}\r\n";
		Effect.ShadersStore["selectableCustomChromaVideoVertexShader"]= "\r\n"+
				"// Attributes\r\n"+
				"attribute vec3 position;\r\n"+
				"attribute vec2 uv;\r\n"+
				"// Uniforms\r\n"+
				"uniform mat4 worldViewProjection;\r\n"+
				"// Varying\r\n"+
				"varying vec2 vUV;\r\n"+
				"void main(void) {\r\n"+
				"    gl_Position = worldViewProjection * vec4(position, 1.0);\r\n"+
				"    vec2 tUV = uv;\r\n"+
				"    vUV = tUV;\r\n"+
				"}\r\n";
		Effect.ShadersStore["selectableCustomChromaVideoFragmentShader"]= "\r\n"+
				"varying vec2 vUV;\r\n"+
				"uniform sampler2D videoSampler;\r\n"+
				"uniform vec4 chromaKey;\r\n"+
				"uniform bool enableChroma;\r\n"+
				"mat4 RGBtoYUV = mat4(0.257,  0.439, -0.148, 0.0,\r\n"+
				"					  0.504, -0.368, -0.291, 0.0,\r\n"+
				"					  0.098, -0.071,  0.439, 0.0,\r\n"+
				"					  0.0625, 0.500,  0.500, 1.0);\r\n"+
				"vec2 maskRange = vec2(0.1, 0.16);\r\n"+
				"float colorclose(vec3 yuv, vec3 keyYuv, vec2 tol) {\r\n"+
				"	float tmp = sqrt(pow(keyYuv.g - yuv.g, 2.0) + pow(keyYuv.b - yuv.b, 2.0));\r\n"+
				"	if (tmp < tol.x)\r\n"+
				"		return 0.0;\r\n"+
				"	else if (tmp < tol.y)\r\n"+
				"		return (tmp - tol.x)/(tol.y - tol.x);\r\n"+
				"	else\r\n"+
				"		return 1.0;\r\n"+
				"}\r\n"+
				"void main(void) {\r\n"+
				"	 vec4 video_color = texture2D(videoSampler, vUV);\r\n"+
				"	 if(enableChroma==true) {\r\r"+
				"	 	vec4 keyYUV =  RGBtoYUV * chromaKey;\r\n"+
				"	 	vec4 yuv = RGBtoYUV * video_color;\r\n"+
				"	 	video_color.a = colorclose(yuv.rgb, keyYUV.rgb, maskRange);\r\n"+
				"	 }\r\n"+
				"    gl_FragColor = video_color;\r\n"+
				"}\r\n";

		//inizializzazione texture
		this.playTexture = new Texture(
			this.PLAY_URL,
            scene,
			false,
			true,
			Texture.BILINEAR_SAMPLINGMODE,
			() => {},
			(message, error) => {
                this.playTexture.dispose();
				console.log("PLAY TEXTURE ERROR");
			}
		);
		this.playTexture.hasAlpha = true;

		this.replayTexture = new Texture(
			this.REPLAY_URL,
            scene,
			false,
			true,
			Texture.BILINEAR_SAMPLINGMODE,
			() => {},
			(message, error) => {
                this.replayTexture.dispose();
				console.log("REPLAY TEXTURE ERROR");
			}
		);
		this.replayTexture.hasAlpha = true;
	}
	createVideoMaterial = (scene) => {
		var videoMaterial = new ShaderMaterial("videoMaterial", scene, {vertex: "video", fragment: "video",}, {attributes: ["position","uv"],uniforms: ["worldViewProjection"], samplers:['videoSampler']});
		return videoMaterial;
	}
	createGreenScreenVideoMaterial = (scene) => {
		var videoMaterial = new ShaderMaterial("videoMaterial", scene, {vertex: "chromaVideo", fragment: "chromaVideo",}, {attributes: ["position","uv"], uniforms: ["worldViewProjection"], samplers:['videoSampler'], needAlphaBlending: true});
		videoMaterial.transparencyMode = "Alpha Blend"
		videoMaterial.useAlphaFromDiffuseTexture = true;
		return videoMaterial;
	}
	createSelecteableChromakeyVideoMaterial = (scene, chromaR, chromaG, chromaB) => {
		var videoMaterial = new ShaderMaterial("videoMaterial", scene, {vertex: "selectableChromaVideo", fragment: "selectableChromaVideo",}, {attributes: ["position","uv"], uniforms: ["worldViewProjection", "chromaKey" ], samplers:['videoSampler'], needAlphaBlending: true});
		videoMaterial.setVector4("chromaKey", new Vector4(chromaR/255.0, chromaG/255.0, chromaB/255.0, 1.0));
		videoMaterial.transparencyMode = "Alpha Blend"
		videoMaterial.useAlphaFromDiffuseTexture = true;
		return videoMaterial;
	}
	createSelecteableCustomChromakeyVideoMaterial = (scene, chromaR, chromaG, chromaB, enableChroma) => {
		var videoMaterial = new ShaderMaterial("videoMaterial", scene, {vertex: "selectableCustomChromaVideo", fragment: "selectableCustomChromaVideo",}, {attributes: ["position","uv"], uniforms: ["worldViewProjection", "chromaKey" ], samplers:['videoSampler'], needAlphaBlending: true});
		videoMaterial.setVector4("chromaKey", new Vector4(chromaR/255.0, chromaG/255.0, chromaB/255.0, 1.0));
		videoMaterial.setInt("enableChroma", enableChroma?1:0);
		videoMaterial.transparencyMode = "Alpha Blend"
		videoMaterial.useAlphaFromDiffuseTexture = true;
		return videoMaterial;
	}
	setVideoControlVisibility = (videoMesh, visibility) => {
		let meshes = videoMesh.getChildMeshes(true, (mesh) => {
			if(mesh.name == "controlImagePlane"){
				return true
			}
			return false
		});
		//BABYLON.Tools.Log("setVideoControlVisibility "+visibility+ " nChild "+meshes.length);
		//meshes[0].visibility = visibility;
		if(meshes!=null && meshes!=undefined && meshes.length>0) {
			meshes[0].scaling = new Vector3(visibility,visibility,visibility);
		}
	}
	
	/** Create basic scene stuff
	*/
	createARScene = (scene) => {
		this.registerVideoElements(scene);
		
		// creates a light
        const light = new PointLight("light", new Vector3(0.0, 0.0, -10.0), this.main_scene);
        light.diffuse = new Color3(1.0, 1.0, 1.0);
        light.specular = new Color3(0.0, 0.0, 0.0);

        // creates another light
        const light1 = new HemisphericLight("light1", new Vector3(Math.PI, Math.PI, 0.0), this.main_scene);
        light1.intensity = 3.0;
		
        //set initial scene state
        scene.state = STATUS.INITIALIZING;
        //register custom materials
        // RegisterDBXElements(scene);
        scene.place3D = false;
        scene.scenePositionX = 0.0;
        scene.scenePositionY = 0.0;
        scene.scenePositionZ = 0.0;
        //scene buffering
        scene.arSceneNode = new TransformNode("sceneNode");
		scene.arSceneNode.ignoreNonUniformScaling = true;
		//if (this.state.immersiveArSupported)
		//	scene.arSceneNode.scaling = new Vector3(0.0, 0.0, 0.0);
		//else
			scene.arSceneNode.scaling = new Vector3(1.0, 1.0, 1.0);

        return scene;
    }
	
	/**
	 * add audio mediaelement
	 */
	addAudioMediaelement = (scene, media, index) => {
		const self = this;
		// don't add mesh for audio type just remove from media to load count +'?.mp3'
		const music = new Sound(
								media.name, 
								media.fileUrl, 
								scene, 
								() => {
									self.setState((s) => ({
									toLoad: s.toLoad - 1,
									...(s.toLoad === 1 && { loaded: true })
								}));
								}, 
								{
								  loop: media.mediaAction.loop,
								  autoplay: false,
								  volume: 1,
								  skipCodecCheck: true,
								});
		music.internal_id = index;
		media.meshes.push(music);
		media.node = new TransformNode(`sceneNode_${media.id}`);
		media.node.ignoreNonUniformScaling = true;
        music.parent = media.node;							
	}
	/**
	 * add gltf mesh mediaelement
	 */
	addMeshMediaelement = (scene, media, index) => {
		const self = this;
		// load 3D file
		SceneLoader.ImportMeshAsync(
			'',
			media.fileUrl,
			'',
			scene,
			function () {},
			'.glb').then(
						function (newMeshes) {
							for (var i = 0; i < newMeshes.meshes.length; i++) {
								//set mesh id to ar model id
								newMeshes.meshes[i].internal_id = index;
								//media.meshes.push(newMeshes.meshes[i]);
							}
							media.meshes.push(newMeshes);
							// setup new 3D mesh
							const new3DMesh = newMeshes.meshes[0];
							// remove a count from items to load on mesh render
							new3DMesh.onMeshReadyObservable.add(() => {
								self.setState((s) => ({
									toLoad: s.toLoad - 1,
									...(s.toLoad === 1 && { loaded: true })
								}));
							});
							// position
							media.node = new TransformNode(`sceneNode_${media.id}`);
							media.node.ignoreNonUniformScaling = true;
							
							media.internode = new TransformNode(`sceneInternodeNode_${media.id}`);
							media.internode.ignoreNonUniformScaling = true;
							media.internode.setParent(media.node, false);
							
							//newMeshes.transformNodes[0].parent = media.node;
							newMeshes.meshes[0].setParent(media.internode, false);
							media.internode.scaling =  new Vector3(-1.0,1.0,1.0);
						
						});
	}
	/**
	 * add video mediaelement
	 */
	addVideoMediaelement = (scene, media, index) => {
		const self = this;
		const marker = MeshBuilder.CreatePlane(
											index, 
											{
												height: media.height / media.width,
												width: 1,
											}, 
											scene);
		// remove a count from items to load on mesh render
		marker.onMeshReadyObservable.add(() => {
			self.setState((s) => ({
				toLoad: s.toLoad - 1,
				...(s.toLoad === 1 && { loaded: true })
			}));
		});

		// setup item marker texture
		//const markerMat = new StandardMaterial('markerMat', scene);
		let chromaColor = [0.0,0.0,0.0];
		if(media.video_chromaKeyRgb && media.video_chromaKeyRgb.length==3) {
			chromaColor=media.video_chromaKeyRgb;
		}
		let markerMat = this.createSelecteableCustomChromakeyVideoMaterial(scene, chromaColor[0], chromaColor[1], chromaColor[2], media.video_greenScreen);
		//89 149 41 +- 1
		let diffuseTexture =  new VideoTexture(
											media.fileUrl, 
											media.fileUrl, 
											scene, 
											false, 
											false, 
											VideoTexture.TRILINEAR_SAMPLINGMODE, 
											{
												loop: media.mediaAction.loop,
												autoPlay:false,
												autoUpdateTexture:true,
											});
		markerMat.setTexture("videoSampler", diffuseTexture);
		markerMat.diffuseTexture = diffuseTexture;
		markerMat.roughness = 1;
		markerMat.backFaceCulling = false;
		markerMat.emissiveColor = Color3.White();
		marker.material = markerMat;
		marker.mediaAction = null;
		marker.type = MEDIA_TYPE_VIDEO;
				
		//marker.renderingGroupId = 1;
		//control buttons
		let controlsPlane = MeshBuilder.CreatePlane("controlImagePlane", {height: .3, width: .3}, scene);
		controlsPlane.type = MEDIA_TYPE_CONTROL;
		let controlsPlaneMat = new StandardMaterial("controlImagePlaneMat", scene);
		controlsPlaneMat.diffuseTexture = this.playTexture;
		controlsPlaneMat.useAlphaFromDiffuseTexture = true;
		controlsPlaneMat.backFaceCulling = false;
		controlsPlane.material = controlsPlaneMat;
		controlsPlane.mediaAction = null;
		controlsPlane.setParent(marker);
		controlsPlane.position = new Vector3(
			controlsPlane.position.x,
			controlsPlane.position.y,
			controlsPlane.position.z-0.01
		);
		this.setVideoControlVisibility(marker, 0);
				
		controlsPlane.internal_id = index;
		marker.internal_id = index;
		
		media.meshes.push(marker);
		media.meshes.push(controlsPlane);
		
		// position item marker
		media.node = new TransformNode(`sceneNode_${media.id}`);
		media.node.ignoreNonUniformScaling = true;
		marker.setParent(media.node);
	}
	/**
	 * add gltf mesh mediaelement
	 */
	addImageMediaElement = (scene, media, index) => {
		const self = this;
		let isButton = media.mediaType === MEDIA_TYPE_BUTTON;
		let img = new Image();
		img.onload = () => {
			// setup item marker
			const marker = MeshBuilder.CreatePlane(index, 
												{
													height: img.height / img.width,
													width: 1,
												}, 
												scene);

			// remove a count from items to load on mesh render
			marker.onMeshReadyObservable.add(() => {
				self.setState((s) => ({
					toLoad: s.toLoad - 1,
					...(s.toLoad === 1 && { loaded: true })
				}));
			});

			// setup item marker texture
			const markerMat = new StandardMaterial('markerMat', scene);
			markerMat.diffuseTexture = new Texture(media.editorPreviewUrl, scene);
			markerMat.diffuseTexture.hasAlpha = true;
			markerMat.backFaceCulling = false;
			marker.material = markerMat;
			marker.mediaAction = null;
			if (isButton) {
				marker.type = MEDIA_TYPE_BUTTON;
			}
			else {
				marker.type = MEDIA_TYPE_IMAGE;
			}

			if (media.mediaAction) {
				marker.mediaAction = media.mediaAction;
			}

			//ar indexes for handling touch input
			marker.internal_id = index;
			media.meshes.push(marker);

			// position item marker
			media.node = new TransformNode(`sceneNode_${media.id}`);
			media.node.ignoreNonUniformScaling = true;
			marker.setParent(media.node);
		};
		img.src = media.editorPreviewUrl;
	}
	/**
     *  add mediaelement to scene based on type
     */
    addMediaElement = (scene, media, index) => {	
        switch (media.mediaType) {
            case MEDIA_TYPE_AUDIO:
                this.addAudioMediaelement(scene, media, index);
                break;
            case MEDIA_TYPE_3D:
                this.addMeshMediaelement(scene, media, index);
                break;
            case MEDIA_TYPE_VIDEO:
				this.addVideoMediaelement(scene, media, index);
                break;
            case MEDIA_TYPE_BUTTON:
            case MEDIA_TYPE_IMAGE:
                this.addImageMediaElement(scene, media, index);
                break;
            default:
                break;
        }
    }

    
    /**
     * component lifecycle events
	 * call cms api to get scene media elements after componentmount
     */
    componentDidMount = () => {
		const self = this;
		
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.AR_WEB);
        if (navigator.xr) {
			console.log('CHECKING AR');
            let immersiveArSupported = false;
            // Checks to ensure that 'immersive-ar' mode is available
            navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
                immersiveArSupported = supported;
            }).catch(() => {
                immersiveArSupported = false;
            }).finally(() => {
                self.setState({ immersiveArSupported: immersiveArSupported });
            });
        }

        // query scene media from api and add to state
        PkArViewInvolve.getSceneMedia(self.props.sceneId, self.props.subSceneId)
            .then((response) => {
                const { result, data } = response;
                if (result.success) {
                    if (Array.isArray(data) && data.length) {
                        // create sceneMedia objects array
                        const sceneMediaArray = data.map(m => new SceneMedia({ ...m }));
                        // update state with elements to load
                        self.setState({
                            fetched: true,
                            media: sceneMediaArray,
                            toLoad: data.length,
                        });
                    } else {
                        // no media elements to load so just fire the loaded state
                        self.setState({
                            fetched: true,
                            loaded: true,
                            toLoad: 0,
                        });
                    }
                } else {
                    PkLog.error(response);
                }
            });
			
		PkArViewInvolve.getSceneScript(self.props.sceneId, self.props.subSceneId)
			.then((response) => {
				const { result, data } = response;
				if (result.success) {
					self.setState({
						sceneScript: data[0].sceneScript,
						customScript: data[0].customScript,
					});
				} else {
				PkLog.error(response);
               }
			});
    }
	/**
     * component lifecycle events
	 * call cms api to get scene media elements after componentmount
     */
	componentDidUpdate = (prevProps, prevState, snapshot) => {
		const self = this;
		if(this.state.toLoad==0 && prevState.toLoad==1) {
			this.loadScript(() => {
				self.initSceneScript();
			});
		}
	}
	
	componentWillUnmount = () => {
		window.main_scene=undefined;
		window.main_scene_media=undefined;
	}

    /**
     * create the default xr experience
     * then if it was created enable the hit-test feature and launch the ar camera view
     * when it finds the hit-tests it moves the scene target icon to where you are aiming
     * and if it has been given the command to place the scene it sets the last coordinates to those of the scene
     * @param {*} scene 
     */
    createArExperience = async (scene) => {
		this.state.media.forEach((media, index) => {
                this.addMediaElement(this.main_scene, media, index);
        });
		
        const xr = await scene.createDefaultXRExperienceAsync({
            uiOptions: {
                sessionMode: "immersive-ar",
                referenceSpaceType: "local-floor"
            },
            optionalFeatures: true
        });
        const fm = xr.baseExperience.featuresManager;

        if (xr) {
            this.setState({ webXRExperience: xr })

            //#region hitTest
            const xrHitTestModule = fm.enableFeature(WebXRHitTest.Name, "latest",
                {
                    offsetRay: {
                        origin: { x: 0, y: 0, z: 0 },
                        direction: { x: 0, y: 0, z: -1 }
                    },
                    entityTypes: ["mesh"]
                });
            xrHitTestModule.autoCloneTransformation = true;
            xrHitTestModule.onHitTestResultObservable.add(hitTests => {
                let _target = scene.getNodeByName("target");

                if (scene.state === STATUS.SCENE_PLACED) {
                    this.hidePlaneTarget(_target);
                    return;
                }
                if (hitTests != null && hitTests.length > 0) {
                    this.statusUpdateCommand(scene, STATUS.SCENE_PLANE_FOUND);

                    //se non è ancora stato settato nessuno status o lo status non è scene_plane_found
                    if (!this.state.currentStatus ||
                        STATUS.SCENE_PLANE_FOUND !== this.state.currentStatus) {
                        this.setState({ planeFound: true });
                    }

                    var plane = null;
                    var mindist = 9999.9;
                    for (var i = 0; i < hitTests.length; i++) {
                        var dx = scene.activeCamera.position.x - hitTests[i].position.x;
                        var dy = scene.activeCamera.position.y - hitTests[i].position.y;
                        var dz = scene.activeCamera.position.z - hitTests[i].position.z;
                        var dist = dx * dx + dy * dy + dz * dz;
                        if (dist < mindist) {
                            mindist = dist;
                            plane = hitTests[i];
                        }
                    }
                    if (plane != null) {
                        scene.state = STATUS.FOUND;

                        this.showPlaneTarget(_target, plane.position.x, plane.position.y, plane.position.z);

                        if (scene.place3D && scene.state !== STATUS.SCENE_PLACED && this.state.media != null) {
                            scene.state = STATUS.SCENE_PLACED;
                            this.hidePlaneTarget(_target);
                            scene.scenePositionX = plane.position.x;
                            scene.scenePositionY = plane.position.y;
                            scene.scenePositionZ = plane.position.z;

                            this.placeScene(scene);
                        }
                    }
                    else {
                        this.hidePlaneTarget(_target);
                        this.setState({ planeFound: false });
                    }
                }
                else {
                    scene.state = STATUS.SEARCHING;
                    this.statusUpdateCommand(scene, STATUS.SEARCHING);
                    this.hidePlaneTarget(_target);
                    this.setState({ planeFound: false });
                }
            });
            //#endregion

            

            /**
             * Per poter disegnare a schermo tramite dell'html
             */
            const xrOptions = {
                requiredFeatures: ['hit-test', 'anchors'],
                optionalFeatures: ["dom-overlay"],
                domOverlay: {
                    root: document.getElementById("overlay")
                }
            }

            //avvia webXR con la camera del telefono
            console.log("Entering XRA sync");
            this.launchWebXR(xr, xrOptions, scene);

            //permette di disegnare a schermo direttamente tramite l'html
            //capire se basta solo uno dei due
            const domOverlayFeature = fm.enableFeature(WebXRDomOverlay, "latest",
                { element: ".dom-overlay-container" }, undefined, false);

            //riceve gli update sullo stato di webXR
            xr.baseExperience.onStateChangedObservable.add((webXRState) => {

                //TODO: quando ritorna da fuori chiama questo
                switch (webXRState) {
                    case WebXRState.ENTERING_XR:
                        console.log('webXRState', 'entering_xr');
                        break;
                    case WebXRState.IN_XR:
                        console.log('webXRState', 'in_xr');
                        // domOverlayType will be null when not supported.
                        console.log("overlay type:", domOverlayFeature.domOverlayType);
                        break;
                    case WebXRState.EXITING_XR:
                        // xr exit request was made. not yet done.
                        console.log('webXRState', 'not_entering');
						break;
                    case WebXRState.NOT_IN_XR:
                        // self explanatory - either out or not yet in XR
                        console.log('webXRState', 'not_in_xr');
						break;
                    default:
                        console.log('webXRState', "default");

                        if (this.state.webXRState === WebXRState.IN_XR) {
                            this.launchWebXR(xr, xrOptions, scene);
                        }
                }


                this.setState({ webXRState: webXRState });
            });
        }
    }

	/**
	 * helper function to launch webxr experience
	 */
    launchWebXR = (xr, xrOptions, scene) => {
        xr.baseExperience.enterXRAsync("immersive-ar", "unbounded", xr.renderTarget, xrOptions).then((xrSessionManager) => {
            console.log("Entered XRA sync")

            this.createPlaneTargetAsync(scene);

            scene.state = STATUS.INITIALIZED;
            this.statusUpdateCommand(scene, STATUS.INITIALIZED);
        }).catch((error) => {
            //start standard 3d visual
			console.log(`errors while renentering ${error}`);
			this.setState({ immersiveArSupported: false })
			//this.showNonImmersiveEditor(scene);
        });
    }

	/**
	 * exit webxr experience
	 */
    exitFromWebXr = () => {
        if (this.state.webXRExperience)
            this.state.webXRExperience.baseExperience.exitXRAsync()
    }

	/**
	 * show nonxr scene in a 3d view
	 */
    createStandardExperience = (scene) => {
        const self = this;
        /**
         * Per provare il plugin di fisica di havok
         */
        // this.main_scene = this.createPhysicSensitiveScene(scene);
        //this.main_scene.enablePhysics();

        // creates and positions a camera
        this.camera = new ArcRotateCamera("Camera", Math.PI / 2.0, 1.0 * (Math.PI / 4.0), 2.0, new Vector3(0.0, 0.0, 0.0), this.main_scene);
        this.camera.minZ = 0.1;
		this.camera.maxZ = 100.0;
        this.camera.wheelPrecision = 100.0;
		this.camera.upVector = new Vector3(0.0, 0.0, 1.0);

        // targets the camera to scene origin
        this.camera.setTarget(Vector3.Zero());

        // attaches the camera to the canvas
        const canvas = this.main_scene.getEngine().getRenderingCanvas();
        this.camera.attachControl(canvas, true);

        // set background color
        this.main_scene.clearColor = new Color3(0.6, 0.6, 0.6);

        // add ground grid
        const gridMaterial = new GridMaterial("gridMaterial", this.main_scene);
        if (!this.props.markerUrl) { // markerless editor
            gridMaterial.mainColor = new Color3(0.9, 0.9, 0.9);
            gridMaterial.lineColor = new Color3(0.8, 0.8, 0.8);
            gridMaterial.opacity = 1;
        } else {
            gridMaterial.mainColor = new Color3(0, 0, 0);
            gridMaterial.lineColor = new Color3(0.3, 0.3, 0.3);
            gridMaterial.opacity = 0.1;
        }
        gridMaterial.gridRatio = 0.1;
        gridMaterial.majorUnitFrequency = 1.0;
        gridMaterial.minorUnitVisibility = 0.1;
        gridMaterial.gridOffset = new Vector3(0.0, 0.0, 0.0);
        gridMaterial.backFaceCulling = false;

        const grid = MeshBuilder.CreateGround("grid", {
            width: 5,
            height: 5,
            subdivisions: 2,
        }, this.main_scene);
        grid.rotation.x = Math.PI / 2;
        grid.position.z = -0.001;
        grid.material = gridMaterial;

        // // add each media to scene
        this.state.media.forEach((media, index) => {
            this.addMediaElement(self.main_scene, media, index);
        });

        
    }

    /**
     * Place and visualize the ar scene (called internally)
     */
    placeScene = (scene) => {
        //posizionamento della scena
        scene.arSceneNode.position.x = scene.scenePositionX;
        scene.arSceneNode.position.y = scene.scenePositionY;
        scene.arSceneNode.position.z = scene.scenePositionZ;

        var pointToRotateTo = scene.activeCamera.position;
        var dv = pointToRotateTo.subtract(scene.arSceneNode.position);
        var yaw = -Math.atan2(dv.z, dv.x) - Math.PI / 2;
        scene.arSceneNode.rotationQuaternion = Quaternion.RotationYawPitchRoll(yaw, 0, 0);

        if (this.state.immersiveArSupported)
			this.visualizeScene(scene);

        this.statusUpdateCommand(scene, STATUS.SCENE_PLACED);
        this.setState({ scenePlaced: true })
    }

    /**
     * tell webxr that the scene can be placed as soon as webxr find a hittest with the ground plane
     */
    setPlaceScene = (scene) => {
        if (this.state.media != null && scene.state === STATUS.FOUND) {
            scene.place3D = true;
        }
    }

	/**
	 * tell webxr that the user want to replace the scene, hide it and show plane target again
	 */
    replaceScene = (scene) => {
        //console.log("ReplaceScene");
        // scene.arSceneNode.scaling = new Vector3(0.0, 0.0, 0.0);
		//if (this.state.immersiveArSupported)
		//	this.animateScaling(scene.arSceneNode, false);

        //pause degli audio e video
        this.state.media.forEach(mediaelement => {
            if(mediaelement.mediaType === MEDIA_TYPE_AUDIO) {
				mediaelement.meshes[0].pause();
			}
			if(mediaelement.mediaType === MEDIA_TYPE_VIDEO) {
				mediaelement.meshes[0].material.diffuseTexture.video.pause();
			}
        });

        scene.state = STATUS.SEARCHING;
        scene.place3D = false;
    }

    /**
     * make the scene visible and play audio/videos
     */
    visualizeScene = (scene) => {
        //scaling della scena
		const self = this;
		//if (this.state.immersiveArSupported)
		//	this.animateScaling(scene.arSceneNode, true);
		
		this.state.media.forEach(mediaelement => {
            if(mediaelement.mediaType === MEDIA_TYPE_AUDIO && mediaelement.autostart) {
				mediaelement.meshes[0].play();
			}
			if(mediaelement.mediaType === MEDIA_TYPE_VIDEO) {
				if(mediaelement.mediaAction.alwaysRestart)
					mediaelement.meshes[0].material.diffuseTexture.video.currentTime=0;
				mediaelement.meshes[0].material.diffuseTexture.video.play();
				this.setVideoControlVisibility(mediaelement.meshes[0], 0);
			}
        });		
    }


    /**
     * creates the crosshair element to be moved on planes to place the scene
     */
    createPlaneTargetAsync = (scene) => {
        const target = new TransformNode("target");
		target.ignoreNonUniformScaling = true;

        let imagePlane = MeshBuilder.CreatePlane("targetImagePlane", { height: .1, width: .1 }, scene);
        imagePlane.rotate(Axis.X, Math.PI / 2.0, Space.LOCAL);
        imagePlane.type = TYPE.TARGET;

        let imagePlaneMat = new StandardMaterial("targetImagePlaneMat", scene);
        let texture = new Texture(
            this.TARGET_URL,
            scene,
            false,
            true,
            Texture.BILINEAR_SAMPLINGMODE,
            () => {
                Tools.Log(this.babylonLogTag, 'imagePlaneMat onLoad');
            },
            (message, error) => {
                texture.dispose();
                Tools.Log(this.babylonLogTag, 'target texture error');
                // showLoadMediaErrorCommand(scene)
            }
        )

        imagePlaneMat.diffuseTexture = texture;
        imagePlaneMat.diffuseTexture.hasAlpha = true;
        imagePlaneMat.useAlphaFromDiffuseTexture = true;
        imagePlane.material = imagePlaneMat;
        imagePlane.mediaAction = null;

        imagePlane.setParent(target);
        imagePlane.internal_id = -1;
    }

	/**
     * hide the plane crosshair icon
     */
    hidePlaneTarget = (target) => {
        target.scaling = new Vector3(0.0, 0.0, 0.0);
    }

	/**
     * show the plane crosshair icon
     */
    showPlaneTarget = (target, x, y, z) => {
        target.position.x = x;
        target.position.y = y;
        target.position.z = z;
        target.scaling = new Vector3(1.0, 1.0, 1.0);
    }

    /**
     * change the currentStatus of the ar process
     */
    statusUpdateCommand = (scene, status) => {
        if (status !== this.state.currentStatus) {
            this.setState({
                currentStatus: status,
            }, () => {
                let commandType = "STATUS_UPDATE";
                let command = {};
                command.data = status;
                let jsonCommand = JSON.stringify(command);
                Tools.Log("statusUpdateCommand " + jsonCommand);
				//TODO
                if (scene.commands) {
                    scene.commands.command(commandType, jsonCommand);
                }
            });
        }
    }

    /**
     * it is called by the SceneComponent component when the scene is ready
     */
    onSceneReady = (scene) => {
        Tools.Log("START scene");

        this.main_scene = null;

        this.main_scene = this.createARScene(scene, null/*TODO: fare i commands*/);
		
		//#region Drag 'n' drop
		this.handleUserInputs(this.main_scene);
		//#endregion
		
        if (this.state.immersiveArSupported) {
            if (this.logfps) {
                var logFpsLoop = function () {
                    Tools.Log("FPS: " + Math.round(scene.getEngine().getFps()));
                    window.setTimeout(logFpsLoop, 1000);
                };

                window.setTimeout(logFpsLoop, 3000);
            }
            this.createArExperience(this.main_scene);
        }
        else {
            this.createStandardExperience(this.main_scene);
        }
    }

    /**
     * show or hide the entire ar node with an animation
     * for a purely aesthetic reason
     * @param {*} nodeToScale 
     * @param {*} show 
     */
    animateScaling = (nodeToScale, show) => {
        const growRating = 0.02;
        if (show) {
            let i = 0;
            var intervalShow = setInterval(() => {
                i += growRating;
                nodeToScale.scaling = new Vector3(i, i, i);
				nodeToScale.markAsDirty();
                if (i >= 1) {
                    nodeToScale.scaling = new Vector3(1.0, 1.0, 1.0);
					nodeToScale.markAsDirty();
                    clearInterval(intervalShow);
                }
            }, 1);
        }
        else {
            //hide
            let i = 1;
            var intervalHide = setInterval(() => {
                i -= growRating;
                nodeToScale.scaling = new Vector3(i, i, i);
				nodeToScale.markAsDirty();
                if (i <= 0) {
                    nodeToScale.scaling = new Vector3(0.0, 0.0, 0.0);
					nodeToScale.markAsDirty();
                    clearInterval(intervalHide);
                }
            }, 1);


        }
    }

    //#region HANDLE INPUTS
    /**
     * Gestisce il movimento del dito sugli oggetti
     * quando tocca un oggetto controlla che sia di tipo oggetto 3D
     * e in quel caso permette di ruotarlo
     * @param {*} scene 
     */
    handleUserInputs = (scene) => {
        let currentMesh;
        let lastTouchPosition;
		let startTouchPosition= new Vector3();
        let touchPosition = new Vector3();

        const pointerDown = (mesh, rayOrigin) => {
            currentMesh = mesh;

            touchPosition.copyFromFloats(rayOrigin.x, rayOrigin.y, 0);
			
			startTouchPosition.copyFromFloats(rayOrigin.x, rayOrigin.y, 0);

            lastTouchPosition = touchPosition.clone();
			
			const ar_id = currentMesh.internal_id;
			const arModel = this.state.media[ar_id];
			if ((this.state.immersiveArSupported == false) && mesh!=null && mesh.name!=='grid' && ar_id>=0 && arModel.mediaType==MEDIA_TYPE_3D) {
				this.camera.angularSensibilityX = 500000;
				this.camera.angularSensibilityY = 500000;
				this.camera.panningSensibility = 0;
			}
        }

        const pointerUp = () => {
            if (currentMesh) {
                const ar_id = currentMesh.internal_id;
				if(ar_id>=0) {
					const arModel = this.state.media[ar_id];
					if (arModel && arModel.meshes && arModel.meshes.length > 0) {
						const arModelMesh = arModel.meshes[0];
						if (arModelMesh && ar_id >= 0) {
							let dist = 0.0;
							switch (arModel.mediaType) {
								case MEDIA_TYPE_3D:
									break;
								case MEDIA_TYPE_VIDEO:
									dist = (lastTouchPosition.x - startTouchPosition.x)*(lastTouchPosition.x - startTouchPosition.x)+(lastTouchPosition.y - startTouchPosition.y)*(lastTouchPosition.y - startTouchPosition.y)+(lastTouchPosition.z - startTouchPosition.z)*(lastTouchPosition.z - startTouchPosition.z);
									if(dist<0.03) {
										if(arModelMesh.material.diffuseTexture.video.paused) {
											arModelMesh.material.diffuseTexture.video.play();
											this.setVideoControlVisibility(arModelMesh, 0);
										}
										else {
											arModelMesh.material.diffuseTexture.video.pause();
											this.setVideoControlVisibility(arModelMesh, 1);
										}
									}
									break;
								case MEDIA_TYPE_BUTTON:
								case MEDIA_TYPE_IMAGE:
									dist = (lastTouchPosition.x - startTouchPosition.x)*(lastTouchPosition.x - startTouchPosition.x)+(lastTouchPosition.y - startTouchPosition.y)*(lastTouchPosition.y - startTouchPosition.y)+(lastTouchPosition.z - startTouchPosition.z)*(lastTouchPosition.z - startTouchPosition.z);
									//console.log(dist);
									if (currentMesh.mediaAction && dist<0.03) { this.executeMediaActionCommand(currentMesh.mediaAction); }
									break;
								default:
									break;
							}
						}
					}
				}
				
				if ((this.state.immersiveArSupported == false) && currentMesh.name!=='grid') {
					this.camera.angularSensibilityX = 1000;
					this.camera.angularSensibilityY = 1000;
					this.camera.panningSensibility = 1000;
				}
            }

            lastTouchPosition = null;
            currentMesh = null;
        }

        const pointerMove = (rayOrigin) => {
            touchPosition.copyFromFloats(rayOrigin.x, rayOrigin.y, 0);
            if (lastTouchPosition && currentMesh) {
                const ar_id = currentMesh.internal_id;
				if(ar_id>=0) {
					const arModel = this.state.media[ar_id]
					if (arModel && arModel.node) {
						const arModelNode = arModel.node;
						switch (arModel.mediaType) {
							case MEDIA_TYPE_3D:
								const rotationAngle = -(lastTouchPosition.x - touchPosition.x) * 100;
								//console.log('rotationAngle', rotationAngle);
								if (this.state.immersiveArSupported == false) {
									arModelNode.rotate(Axis.Z, rotationAngle, Space.WORLD);
								} else {
									arModelNode.rotate(Axis.Y, rotationAngle, Space.WORLD);
								}
								break;
							default:
								break;
						}

						
					}
				}
            }

            lastTouchPosition = touchPosition.clone();
        }

        scene.onPointerObservable.add((pointerInfo) => {
            const rayOrigin = pointerInfo.pickInfo.ray.origin;
            switch (pointerInfo.type) {
                case PointerEventTypes.POINTERDOWN:
                    if (pointerInfo.pickInfo.hit /*&& pointerInfo.pickInfo.pickedMesh !== ground*/) {
                        pointerDown(pointerInfo.pickInfo.pickedMesh, rayOrigin)
                    }
                    break;
                case PointerEventTypes.POINTERUP:
                    pointerUp();
                    break;
                case PointerEventTypes.POINTERMOVE:
                    pointerMove(rayOrigin);
                    break;
				case PointerEventTypes.POINTERTAP:
					//console.log('POINTERTAP');
					break;
				case PointerEventTypes.POINTERDOUBLETAP:
					//console.log('POINTERDOUBLETAP');
					break;
				case PointerEventTypes.POINTERPICK:
					//console.log('POINTERPICK');
					break;
				case PointerEventTypes.POINTERWHEEL:
					//console.log('POINTERWHEEL');
					break;
                default:
                    lastTouchPosition = null;
                    break;
            }
        });
    }

    executeMediaActionCommand = (mediaAction) => {
        /*{
            "loop": false,
            "alwaysRestart": false,
            "autoReplay": false,
            "actionType": "URL", || "TEL" || "NONE"
            "actionValue": "https://www.parmareggio.it/home"
        }*/

        this.exitFromWebXr();

        switch (mediaAction.actionType) {
            case MEDIA_ACTION_TYPE_URL:
                console.log('clicked', 'url ' + mediaAction.actionValue);
                window.open(encodeURI(mediaAction.actionValue), '_blank')
                break;
            case MEDIA_ACTION_TYPE_TELEPHONE:
                console.log('clicked', 'telephone ' + mediaAction.actionValue);
                window.open(encodeURI("tel:" + mediaAction.actionValue), '_system')
                break;
            case MEDIA_ACTION_TYPE_NONE:
                if (mediaAction.actionValue) {
                    console.log('clicked', 'email ' + mediaAction.actionValue);
                    window.open(encodeURI("mailto:" + mediaAction.actionValue), '_system')
                }
                break;
            default:
                break;
        }
    }
    //#endregion

    //#region GUI
    onBackButtonClicked = () => {
        console.log('onBackButtonClicked');
    }

    onScreenshotButtonCLicked = () => {
        //TODO: da una xrSessionManager si può ottenere un XRFrame da cui prendere lo screen
        //da provare



        // const canvas = document.getElementById('scene-canvas');

        // if (canvas) {
        //     // save canvas image as data url (png format by default)
        //     const dataURL = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        //     // here is the most important part because if you dont replace you will get a DOM 18 exception.

        //     const link = document.createElement("a");
        //     link.href = dataURL;

        //     const date = new Date();
        //     const dateString =
        //         "_" +
        //         date.getFullYear() + "" +
        //         date.getMonth() + "" +
        //         date.getDate() + "" +
        //         date.getHours() + "" +
        //         date.getSeconds() + "" +
        //         date.getMilliseconds();


        //     link.download = this.props.sceneName.split(' ').join('_') + dateString + '.png';
        //     link.click();
        // }
    }

    onConfirmPlacementSceneButtonClicked = () => {
        this.setPlaceScene(this.main_scene)
    }

    onRepositionSceneButtonClicked = () => {
        this.setState({ planeFound: false, scenePlaced: false })
        // this.main_scene.state = STATUS.INITIALIZING;
        this.statusUpdateCommand(this.main_scene, STATUS.INITIALIZING);

        this.replaceScene(this.main_scene);
    }
    //#endregion
	
	onRender = (scene) => {
		//console.log(params);
		let deltatime = scene.deltaTime;
		this.updateSceneScript(deltatime);
	}

    render() {
        const { fetched, loaded, toLoad, media, planeFound, scenePlaced } = this.state;
        const loadingProgress = (fetched && media.length)
            ? ((media.length - toLoad) * 100) / media.length
            : (fetched ? 100 : 0);

        return (
            <Page>

                <div id="overlay" className="dom-overlay-container">
                    <ArWebGUI
                        planeFound={planeFound}
                        scenePlaced={scenePlaced}
                        onBackButtonClicked={this.onBackButtonClicked}
                        onScreenshotButtonClicked={this.onScreenshotButtonCLicked}
                        onConfirmPlacementSceneButtonClicked={this.onConfirmPlacementSceneButtonClicked}
                        onRepositionSceneButtonClicked={this.onRepositionSceneButtonClicked} />

                    <ArWebLoading loaded={loaded} loadingProgress={loadingProgress} />
                    <ArWebIntro planeFound={planeFound} />
                </div>
                {fetched &&
                    <SceneComponent
                        className={styles.canvas}
                        antialias
                        observeCanvasResize={true}
                        adaptToDeviceRatio={true}
                        onSceneReady={this.onSceneReady}
                        onRender={this.onRender}
                        id="scene-canvas">
                    </SceneComponent>
                }
            </Page>
        )
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {}
};
// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ArWeb);

/*
	//TEST physics scene
    createPhysicSensitiveScene = (scene) => {

        // This creates and positions a free camera (non-mesh)
        var camera = new FreeCamera("camera1", new Vector3(0, 5, -10), scene);

        // This targets the camera to scene origin
        camera.setTarget(Vector3.Zero());

        // This attaches the camera to the canvas
        const canvas = this.main_scene.getEngine().getRenderingCanvas();
        camera.attachControl(canvas, true);

        // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
        var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);

        // Default intensity is 1. Let's dim the light a small amount
        light.intensity = 0.7;

        // Our built-in 'sphere' shape.
        var sphere = MeshBuilder.CreateSphere("sphere", { diameter: 2, segments: 32 }, scene);

        // Move the sphere upward at 4 units
        sphere.position.y = 4;

        // Our built-in 'ground' shape.
        var ground = MeshBuilder.CreateGround("ground", { width: 10, height: 10 }, scene);

        // initialize plugin
        //non funziona l'import per un problema di compatibilità con es6
        // HavokPhysics().then((havok) => {
        //     // enable physics in the scene with a gravity
        //     scene.enablePhysics(new Vector3(0, -9.8, 0), havok);

        //     // Create a sphere shape and the associated body. Size will be determined automatically.
        //     var sphereAggregate = new PhysicsAggregate(sphere, PhysicsShapeType.SPHERE, { mass: 1, restitution: 0.75 }, scene);

        //     // Create a static box shape.
        //     var groundAggregate = new PhysicsAggregate(ground, PhysicsShapeType.BOX, { mass: 0 }, scene);
        // });

        return scene;
    }

*/