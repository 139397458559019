import React, { useEffect, useState } from "react";
import { Page } from "framework7-react";
import ReactPlayer from "react-player";

import localeStrings from "./chat-local";
import { isBrowser, isIOS } from "react-device-detect";

export default (props) => {
    const [muted, setMuted] = useState(true);
    const [videoPlaying, setVideoPlaying] = useState(true);
    const [videoPlayer, setVideoPlayer] = useState(null);

    const toggleMute = () => {
        setMuted(!muted);
    };

    const playVideo = (event) => {
        toggleMute();
    }

    const skip = (event) => {
        props.f7router.navigate("/ai-chat", {
            reloadAll: true,
        });
    };

    const videoEnded = () => {
        skip();
    };

    useEffect(() => {
        if (!muted) {
            setVideoPlaying(true);
        }
    }, [muted]);

    return (
        <Page name="videoSplash" className="omg_splashPage">
            <div className="omg_splashCanvas">
                <ReactPlayer
                    // url={"video/gioconda.mp4"}
                    url={localeStrings.url_video}
                    playing={videoPlaying}
                    muted={muted}
                    playsinline={isIOS}
                    onEnded={videoEnded}
                    width="100%"
                    height="100%"
                    onReady={(player) => {
                        if (!videoPlayer)
                            setVideoPlayer(player);
                    }}
                    onStart={() => {
                        console.log('onStart')
                        setTimeout(() => {
                            if (videoPlayer) {
                                setVideoPlaying(false);
                            }
                        }, 150);
                    }}
                    onPlay={() => {
                        console.log('onPlay')

                    }}
                />


                {/* <div className="omg_sfx">
                    <a href="#" onClick={toggleMute}>
                        <img src={`skin/sfx_${muted ? "off" : "on"}.png`} />
                    </a>
                </div> */}
                <div className={`omg_play ${videoPlaying ? "removed" : ""}`} >
                    <a href="#" onClick={playVideo}>
                        <img src={`skin/sfx_play.png`} />
                    </a>
                </div>
                <div className="omg_skip">
                    <a href="#" onClick={skip}>
                        skip
                    </a>
                </div>
            </div>
            <div className="omg_splashBottom">
                <img src={`skin/logo.png`} />
            </div>
        </Page>
    );
};
