import React from 'react';
import { connect } from 'react-redux';
import { f7, Toggle, ListInput, BlockHeader, Navbar, Page, List, ListItem } from 'framework7-react';
import localeStrings from './profilo-local';
import { PkCordova, PkCordovaNotifications } from 'pikkart-cordova';
import { AnalyticsRegister, ANALYTICS_EVENT, ANALYTICS_SCREEN } from '../../analytics-register'
import Commons from '../../commons'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';

class Profilo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notificationEnabled: false,
            profileRole: 'tourist',
            birthDate: '1994-07-16'
        };
    }

    render() {
        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);

        const jsonStringProfileData = localStorage.getItem('profile_data');
        let birthDate = this.state.birthDate;
        let profileRole = this.state.profileRole;

        if (jsonStringProfileData) {
            const profileData = JSON.parse(jsonStringProfileData);
            birthDate = profileData.birthDate;
            profileRole = profileData.profileRole;
        }

        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false}
                        showMenu={Commons.isSideMenuAllowed(f7)} />
                </Navbar>
                <BlockHeader>{localeStrings.who}</BlockHeader>
                <List>
                    <ListItem
                        radio
                        title={localeStrings.tourist}
                        value="tourist"
                        name="radio1"
                        defaultChecked={profileRole === 'tourist' ? true : false}
                        onChange={() => this.radioChanged('tourist')}
                    ></ListItem>
                    <ListItem
                        radio
                        title={localeStrings.citizen}
                        value="citizen"
                        name="radio1"
                        defaultChecked={profileRole === 'citizen' ? true : false}
                        onChange={() => this.radioChanged('citizen')}
                    ></ListItem>
                </List>
                <BlockHeader>{localeStrings.birth_title}</BlockHeader>
                <List simpleList>
                    <ListInput
                        placeholder={localeStrings.birth_placeholder}
                        type="date"
                        defaultValue={birthDate}
                        onInput={(e) => this.calendarChanged(e.target.value)}
                    />
                </List>
                <BlockHeader>{localeStrings.notifications_title}</BlockHeader>

                <List simpleList>
                    <ListItem>
                        <span>{localeStrings.notifications_label}</span>
                        <Toggle
                            checked={this.state.notificationEnabled} onToggleChange={(e) => { this.onToggleChange(e) }} />
                    </ListItem>
                </List>
            </Page>)
    }

    radioChanged = (radioValue) => {
        const self = this;
        this.openConfirm(() => {
            self.setState({ profileRole: radioValue }, () => {
                self.saveProfileData(self.state.notificationEnabled, radioValue, self.state.birthDate);
            })
        });
    }

    calendarChanged = (calendarValue) => {
        const self = this;
        this.openConfirm(() => {
            self.setState({ birthDate: calendarValue }, () => {
                self.changeLocalProfileData(self.state.notificationEnabled, self.state.profileRole, calendarValue);
            })
        });
    }

    onToggleChange = (toggleValue) => {

        const onResume = () => {
            const resultFn = (notificationsEnabled) => {
                this.setState({ notificationEnabled: notificationsEnabled }, () => {
                    //self.changeLocalProfileData(notificationsEnabled, self.state.profileRole, self.state.birthDate);
                })
            }

            PkCordovaNotifications.areNotificationsEnabled().then(() => {
                resultFn(true);
            }).catch((error) => {
                resultFn(false);
            });

        }

        if (PkCordova.isCordova()) {
            PkCordovaNotifications.goToNotificationSettings(onResume);
        }
    }

    changeLocalProfileData = (notificationEnabled, profileRole, birthDate) => {
        localStorage.setItem('profile_data', JSON.stringify({
            notificationEnabled: notificationEnabled,
            profileRole: profileRole,
            birthDate: birthDate
        }));
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        /*notificationEnabled: false,
            profileRole: 'tourist',
            birthDate: '1994-07-16'*/
        if (this.state.notificationEnabled !== prevState.notificationEnabled ||
            this.state.profileRole !== prevState.profileRole ||
            this.state.birthDate !== prevState.birthDate) {

        }
    }

    saveProfileData = (notificationEnabled, profileRole, birthDate) => {
        this.changeLocalProfileData(notificationEnabled, profileRole, birthDate);

        this.registerAnalyticsEvent(ANALYTICS_EVENT.SAVE_PROFILE_DATA, this.state.profileRole, this.state.birthDate);
    }

    registerAnalyticsEvent = (eventName, profileRole, birthDate) => {
        AnalyticsRegister.logEvent(eventName, { ProfileRole: profileRole, BirthDate: birthDate });
    }

    openConfirm(callback) {
        const app = this.$f7;
        app.dialog.confirm(localeStrings.confirm_data, () => {
            callback();
        });
    }

    componentDidMount = () => {
        AnalyticsRegister.logEvent(ANALYTICS_SCREEN.PROFILE);

        const jsonStringProfileData = localStorage.getItem('profile_data');

        if (jsonStringProfileData) {
            const profileData = JSON.parse(jsonStringProfileData);
            this.setState({
                notificationEnabled: false,
                profileRole: profileData.profileRole,
                birthDate: profileData.birthDate
            })
        }

        const resultFn = (notificationsEnabled) => {
            this.setState({ notificationEnabled: notificationsEnabled })
        }

        if (PkCordova.isCordova()) {
            PkCordovaNotifications.areNotificationsEnabled().then(() => {
                resultFn(true);
            }).catch((error) => {
                resultFn(false);
            });
        }
        /*else {
            PkNotifications.checkServiceRegistration().then(function (successResult) {
                if (successResult === true)
                    resultFn(true);
                else {
                    resultFn(false);
                }
            })
                .catch(function (errorMessage) {
                    resultFn(false);
                });
        }*/
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Profilo);