import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, BlockTitle } from 'framework7-react';
import localeStrings from './checklist-results-local';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

class ChecklistResult extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const item = this.props.checklistResult
        const imageJson = item ? JSON.parse(item.data).image : '';
        const workstationName = item ? item.workstationName : '';
        const resultTextColor = item ? item.result.compliant ? '' : '#d51900' : '';
        const resultText = item ? item.result.message : '';

        const image = (
            <div
                id={'card-image-' + workstationName}
                className={"closed-card-width"}
                style={{ height: '300px', textAlign: 'center' }}>
                <img
                    alt={workstationName}
                    src={imageJson}
                    style={{ height: '100%', objectFit: 'contain' }} />
            </div>)
        const imageOptions = {
            buttons: {
                showAutoplayButton: false,
                showCloseButton: true,
                showDownloadButton: false,
                showFullscreenButton: false,
                showThumbnailsButton: false,
            },
            thumbnails: {
                showThumbnails: false,
            }
        }
        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={workstationName}
                        logo='./img/logo/logo.png'
                        showNavRight={true}
                        showArrowAsNavRight={true} />
                </Navbar>

                <div id="content-detail-view">
                    <div>
                        <SimpleReactLightbox>
                            <SRLWrapper options={imageOptions}>
                                {image}
                            </SRLWrapper>
                        </SimpleReactLightbox>
                        <p />

                        <BlockTitle
                            style={{ textAlign: 'center' }}
                            large>{localeStrings.result_block_title}
                        </BlockTitle>
                        <Block strong>
                            <p style={{
                                textAlignLast: 'center',
                                textAlign: 'center',
                                color: resultTextColor,
                                fontWeight: 'bolder',
                                fontSize: 'xx-large'
                            }}>
                                {resultText}
                            </p>
                        </Block>
                    </div>
                </div>
            </Page >
        )
    }

    componentDidMount = () => {
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistResult);