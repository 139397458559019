import React from 'react';
import { connect } from 'react-redux';
import { isTablet, isBrowser, isIOS } from "react-device-detect";
import { Card, CardContent, CardHeader, Link, SkeletonBlock } from 'framework7-react';

class ContentListCategoryItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            effect: 'blink',
        };
    }

    render() {

        let item = this.props.contentData;

        let customTitleClass;
        if (process.env.REACT_APP_CODE === 'unique') {
            if (item.content.catId === 18569) {
                customTitleClass = 'unique-product-title'
            }
        }

        const cardHeader = (item.content.area ? <CardHeader className="categoria" style={{ borderBottomColor: item.content.areaColor }}>
            <span style={{ backgroundColor: item.content.areaColor }}>{item.content.area}</span>
        </CardHeader> : null);
        // const contentDescr = (item.content.descr) ? (<CardContent style={{ padding: '16', margin: '0' }}>
        //     <Block style={{ padding: '0', margin: '0' }} textAlign={'center'}>
        //         <p>
        //             {item.content.descr.length < 100 ? item.content.descr : item.content.descr.substring(0, 100) + '...'}
        //         </p>
        //     </Block>
        // </CardContent >) : null;


        const skel = (this.state.loading) ? `skeleton-text skeleton-effect-${this.state.effect}` : "";

        // if (this.props.menu) {
        //     const menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
        //     centerTitleClass += 'card-title-center';
        // }

        let image = () => {

            // const titleTextColor = IconHelper.getCategoryTitleColor(this.props.contentData.content.code);
            if (this.props.isFavourites)
                return (
                    <figure className="lazy lazy-fadeIn" style={{
                        backgroundImage: 'url(' + item.content.imageUrl + ')',
                        alignContent: 'center',
                        objectFit: 'center/cover',
                        maskPosition: 'no-repeat',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>
                        <h2 className="title card-content-padding category-item-title" /*style={{ color: titleTextColor }}*/>{item.content.title}</h2>
                    </figure>
                );
            return (
                <LazyBackgroundImage
                    item={item}
                    imageUrl={item.content.imageUrl}
                    component={this}
                    isContentEditable={this.props.isContentEditable}
                    customTitleClass={customTitleClass}>
                    {this.state.loading ? < SkeletonBlock style={{ width: '40px', height: '40px', 'border-radius': '50%' }} slot="media" /> : null}
                </LazyBackgroundImage>);
        }

        return (
            <Card noShadow={true} noBorder={true}>
                {cardHeader}
                <CardContent className={skel} style={{ padding: '0' }}>
                    <span onClick={(e) => { this.onCardClick(item) }}>

                        {image()}

                        {/* <figure className="lazy lazy-fadeIn" style={{ backgroundImage: 'url(' + item.content.imageUrl + ')', alignContent: 'center', objectFit: 'center/cover', maskPosition: 'no-repeat' }}>
                            <h2 className="title card-content-padding">{item.content.title}</h2>
                        </figure> */}
                    </span>
                </CardContent>
                {/* {contentDescr} */}
            </Card>
        )
    }

    onCardClick = (item) => {
        if (this.props.onCardClickHandler) {
            this.props.onCardClickHandler(item);
        }
    }

    componentDidMount = () => {

    }

    imageLoaded = () => {
        console.log('image loaded');
        this.setState({ loading: false });
    }
};


const LazyBackgroundImage = ({ item, component, isContentEditable, customTitleClass }) => {
    const [source, setSource] = React.useState("preload.jpg")

    React.useEffect(() => {
        const img = new Image()
        img.src = item.content.imageUrl
        img.onload = () => {
            setSource(item.content.imageUrl)
            component.imageLoaded();
        }
    }, [component, item.content.imageUrl])

    const backgroundrepeat = (isTablet || isBrowser) ? 'inherit' : 'no-repeat';


    let editButton = "";
    if (isContentEditable) {
        editButton = (
            <div className={'edit-icon button card-prevent-open'}>
                <Link onClick={(e) => {
                    component.props.onEditContentButtonClick(item.content);
                }} iconF7="pencil_circle_fill" />
            </div>)
    }

    let titleClass = "title card-content-padding ";
    if (component.props.centerTitle && JSON.parse(process.env.REACT_APP_ENABLE_CENTERED_TITLE_FOR_CATEGORIES)) {
        if (isIOS) {
            titleClass += 'card-title-center-ios';
        }
        else {
            titleClass += 'card-title-center';
        }
    }
    if (customTitleClass)
        titleClass += customTitleClass

    return (
        <figure style={{
            backgroundImage: 'url(' + source + ')',
            alignContent: 'center',
            objectFit: 'center/cover',
            maskPosition: 'no-repeat',
            backgroundRepeat: backgroundrepeat,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }}>
            <div className="content-title-container">
                {editButton}
                <h2 className={titleClass} /*style={{ color: titleTextColor }}*/>{item.content.title}</h2>
            </div>
        </figure>
    )
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentListCategoryItem);
