import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        tutorial_toggle_label: "Do not show again",
        tutorial_button_label: "Start augmented reality",
        download_title: "Download",
        download_text: "Loading markers",
        loading_markers_complete: "Loading markers 100 %",
        loading_markers_percentage: "Loading markers {0} %",
        loading_markers_error: "Download markers error",
        ar_error_title: "Error",
        ar_error_text: "The augmented reality section cannot be started, please try again later or contact technical support",
        tutorial_1: "Point the camera towards the items and follow the instructions in the description",
        tutorial_2: "Enjoy the AR content",
    },
    it: {
        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Avvia la realtà aumentata",
        download_title: "Download",
        download_text: "Loading markers",
        loading_markers_complete: "Caricamento markers 100 %",
        loading_markers_percentage: "Caricamento markers {0} %",
        loading_markers_error: "Errore download markers",
        ar_error_title: "Errore",
        ar_error_text: "Non è possibile avviare la sezione di realtà aumentata, riprova più tardi o contatta il supporto tecnico",
        tutorial_1: "Inquadra gli elementi indicati e segui le istruzioni contenute nella descrizione",
        tutorial_2: "Scopri tutti i contenuti aggiuntivi in AR",
    }
});

export default localeStrings;