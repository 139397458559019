import React from 'react';
import { Icon } from 'framework7-react';

export class IconHelper {
    static getMenuIcon = (code) => {
        let icon = "";
        switch (code) {
            case "/ar":
                icon = (<Icon slot="media" ios="f7:eye" aurora="f7:eye" md="f7:eye" />);
                break;
            case "/arscenes-nootp":
                icon = (<Icon slot="media" icon="f7:cube" aurora="f7:cube" md="f7:cube" />);
                break;
            case "/arscenes":
                icon = (<Icon slot="media" ios="f7:cube" aurora="f7:cube" md="f7:cube" />);
                break;
            case process.env.REACT_APP_DEFAULT_CODE_PAGE:
                icon = (<Icon slot="media" ios="f7:house_alt" aurora="f7:house_alt" md="f7:house_alt" />);
                break;
            case "/info-news":
                icon = (<Icon slot="media" ios="f7:book" aurora="f7:book" md="f7:book" />);
                break;
            case "/tutorial":
                icon = (<Icon slot="media" ios="f7:question_circle" aurora="f7:question_circle" md="f7:question_circle" />);
                break;
            case "/pre-tutorial":
                icon = (<Icon slot="media" ios="f7:question_circle" aurora="f7:question_circle" md="f7:question_circle" />);
                break;
            case "/credits":
                icon = (<Icon slot="media" ios="f7:info_circle" aurora="f7:info_circle" md="f7:info_circle" />);
                break;
            case "/contact":
                icon = (<Icon slot="media" ios="f7:pencil_circle" aurora="f7:pencil_circle" md="f7:pencil_circle" />);
                break;
            case "/result":
                icon = (<Icon slot="media" ios="f7:viewfinder_circle" aurora="f7:viewfinder_circle" md="f7:viewfinder_circle" />);
                break;
            case "/asset-recognition":
                icon = (<Icon slot="media" ios="f7:viewfinder_circle" aurora="f7:viewfinder_circle" md="f7:viewfinder_circle" />);
                break;
            case "/discover":
                icon = (<Icon slot="media" ios="f7:largecircle_fill_circle" aurora="f7:largecircle_fill_circle" md="f7:largecircle_fill_circle" />);
                break;
            default:
                break;
        }
        return icon;
    }

    static getMenuIconString = (code) => {
        let icon = "";
        switch (code) {
            case "/ar":
                icon = "f7:eye"
                break;
            case "/arscenes-nootp":
                icon = "f7:cube"
                break;
            case "/arscenes":
                icon = "f7:cube"
                break;
            case process.env.REACT_APP_DEFAULT_CODE_PAGE:
                icon = "f7:house_alt"
                break;
            case "/info-news":
                icon = "f7:book"
                break;
            case "/tutorial":
                icon = "f7:question_circle"
                break;
            case "/pre-tutorial":
                icon = "f7:question_circle"
                break;
            case "/credits":
                icon = "f7:info_circle"
                break;
            case "/contact":
                icon = "f7:pencil_circle"
                break;
            case "/result":
                icon = "f7:viewfinder_circle"
                break;
            case "/asset-recognition":
                icon = "f7:viewfinder_circle"
                break;
            case "/discover":
                icon = "f7:largecircle_fill_circle"
                break;
            case "/members-area":
                icon = "f7:person_circle"
                break;
            default:
                icon = "f7:largecircle_fill_circle"
                break;
        }
        return icon;
    }
}

export default IconHelper;