import React from "react";
import Commons from "../commons";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import { f7 } from 'framework7-react';
import { Link, Toolbar } from "framework7-react";
import {
    isIOS,
    isMacOs,
    isWindows,
} from "react-device-detect";
import IconHelper from "../helpers/icon-helper";

export const getLinkId = (pageId) => {
    return "tablink-" + pageId;
};

class MenuToolbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeCodePage: null
        };
    }

    render() {
        let menuItems = this.props.menu ? this.props.menu : [];
        let bottomMenu = null;

        if (!Commons.isSideMenuAllowed(f7)) {
            const ret = menuItems.map((item, index) => {
                const itemIcon = IconHelper.getMenuIconString(item.code);
                return (
                    <Link
                        id={item.title + '-' + item.id}
                        className="tab-link flex-direction-column"
                        text={
                            this.showMenuLabels() ? item.title : ""
                        }
                        iconIos={itemIcon}
                        iconAurora={itemIcon}
                        iconMd={itemIcon}
                        tabLinkActive={this.isActivePage(item.code)}
                        onClick={(e) => {
                            console.log('active ', e);
                            this.setState({
                                activeCodePage: item.code
                            })
                            this.props.setActiveCodePage(item.code);
                        }}
                        href={Commons.getMenuItemUrl(item)}
                        key={item.title + '-' + item.id}
                    ></Link>
                )
            })

            bottomMenu = (
                <Toolbar
                    tabbar
                    labels
                    position="bottom"
                    data-testid="main-toolbar"
                    className={"mainToolBar" + (isIOS ? " mainToolBarIos" : "")}>
                    {ret}
                </Toolbar>
            )
        }

        return bottomMenu;
    }

    isActivePage = (codePage) => {
        if (this.state.activeCodePage) {
            return codePage === this.state.activeCodePage
        }
        else {
            return codePage === process.env.REACT_APP_DEFAULT_CODE_PAGE;
        }
    }

    showMenuLabels = () => {
        return isWindows || isMacOs || isIOS || JSON.parse(process.env.REACT_APP_USE_TOOLBARMENU_ONLY);
    };

    setPage = (page) => {
        // this.props.setMainPageIndex(pageIndex);
        f7.router.navigate(page, { reloadAll: true });
    };

    componentDidUpdate = () => {
        if (this.props.activeCodePage && !this.isActivePage(this.props.activeCodePage)) {
            this.setState({
                activeCodePage: this.props.activeCodePage
            })
        }
    }
}

const mapStateToProps = (state) => {
    return {
        menu: state.app.menu,
        activeCodePage: state.app.activeCodePage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMainPageIndex: (mainPageIndex) => dispatch(actions.setMainPageIndex(mainPageIndex)),
        setActiveCodePage: (activeCodePage) => dispatch(actions.setActiveCodePage(activeCodePage)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuToolbar);
