import React from 'react';
import Commons from '../../commons'
import { f7, Page, PageContent } from 'framework7-react';
import store from '../../redux/store'
import { PkCordova, PkCordovaUi, PkCordovaAuthentication } from 'pikkart-cordova';

import { Pk, PLATFORM_TYPE } from '../../pikkart-cms/index';
import { PkLog, LOG_TYPE } from '../../pikkart-cms/log';
import { PkCrypto } from '../../pikkart-cms/crypto/crypto';
import { PkArDiscover } from '../../pikkart-cms/ar/discover';
import { PkContents } from '../../pikkart-cms/contents';

import { setReceivedNotifications } from '../../redux/actions';

import { connect } from 'react-redux';

import { isMobile, isTablet } from 'react-device-detect';
import PkNotifications from '../../pikkart-cms/notifications';

class Landing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };

    this.defaultConfig = {
      appCode: process.env.REACT_APP_CODE,
      customerCode: process.env.REACT_APP_CUSTOMER_CODE,
      apiUrl: process.env.REACT_APP_CMS_API_URL,
      apiKey: process.env.REACT_APP_CMS_API_KEY,
      deviceId: 'browserweb',
      platformType: PLATFORM_TYPE.WEB,
      callsEnabled: process.env.REACT_APP_VIDEOCALL_ENABLED === "true",
      cryptoSchema: PkCrypto.CRYPTOSCHEMA_JSON_V1,
      firebaseKey: process.env.FIREBASE_WEB_KEY,
      openCvUrl: '/js/opencv.js',
      /*webRtcAdapterUrl: './js/webrtc-adapter.js',*/
      logType: LOG_TYPE.VERBOSE,
    }
  }

  render() {
    return (<Page><PageContent><div></div></PageContent></Page>);
  }

  componentDidMount = () => {
    console.log('landing componentdidmount');

    this.initCordovaOnDevice();
    this.initPk();

    if (!PkCordova.isCordova()) {
      this.loadMenu();
    }
  }

  loadMenu = () => {
    console.log('loadMenu');
    const contentSuccess = (content) => {
      this.props.f7router.navigate(Commons.getRequestedContentDetailUrl(content.data.content), { props: { contentItem: content.data } });
    }

    const goToSplash = () => {
      setTimeout(() => {
        let path = (this.props.returnPath) ? this.props.returnPath : '/splash/';
        this.props.f7router.navigate(path, { reloadAll: true });
      }, 300);
    }

    PkLog.log("f7route " + JSON.stringify(this.props.f7route));
    PkLog.log("f7route.query " + JSON.stringify(this.props.f7route.query));
    PkLog.log("f7route.query.keypointid " + JSON.stringify(this.props.f7route.query.keypointid));

    if (this.props.f7route && this.props.f7route.query) {
      if (this.props.f7route.query.keypointid) {
        setTimeout(() => {
          PkArDiscover.getKeypointContentData(this.props.f7route.query.keypointid)
            .then((response) => {
              contentSuccess(response);
              // this.props.f7router.navigate(Commons.getContentDetailUrl(response.data.content), { props: {contentItem: response.data }});
            })
            .catch((error) => {
              console.error(error);
              this.props.f7router.navigate(process.env.REACT_APP_DEFAULT_CODE_PAGE, { reloadAll: true });
            });
        }, 300);
      } else if (this.props.f7route.query.contentid) {
        setTimeout(() => {
          PkContents.getInfoContentData(this.props.f7route.query.contentid)
            .then((response) => {
              contentSuccess(response);
              // this.props.f7router.navigate(Commons.getContentDetailUrl(response.data.content), { props: {contentItem: response.data }});
            })
            .catch((error) => {
              console.error(error);
              this.props.f7router.navigate(process.env.REACT_APP_DEFAULT_CODE_PAGE, { reloadAll: true });
            });
        }, 300);
      }
      else {
        goToSplash();
      }

    } else {
      goToSplash();
    }
  }

  initCordovaOnDevice = () => {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  }

  initPk = () => {
    PkLog.log('INIT');
    console.log('api url', process.env.REACT_APP_CMS_API_URL)

    if (!PkCordova.isCordova()) {
      Pk.init(this.defaultConfig);
    }
  }

  onDeviceReady = () => {
    if (window.screen.orientation) {
      if (isTablet) {
        PkCordovaUi.lockOrientationInLandscape();
      }
      else if (isMobile) {
        PkCordovaUi.lockOrientationInPortrait();
      }
    }
    //store.dispatch(PkReduxActions.setCordovaReady(true));
    PkCordova.init_cordova();

    const enablePushNotifications = JSON.parse(process.env.REACT_APP_ENABLE_PUSH_NOTIFICATIONS);

    if (JSON.parse(process.env.REACT_APP_IS_STANDARD_APP)) {

      if (PkCordovaAuthentication.getDeviceId().then((deviceId) => {

        this.defaultConfig.deviceId = deviceId;
        const platform = PkCordova.getPlatform();

        switch (platform) {
          case 'android':
            this.defaultConfig.platformType = PLATFORM_TYPE.ANDROID;
            break;
          case 'ios':
            this.defaultConfig.platformType = PLATFORM_TYPE.IOS;
            break;
          default:
            this.defaultConfig.platformType = PLATFORM_TYPE.WEB;
            break;
        }

        Pk.init(this.defaultConfig);

        console.log('REACT_APP_ENABLE_PUSH_NOTIFICATIONS', 'initCordovaNotifications ' + enablePushNotifications);
        if (enablePushNotifications) {
          PkNotifications.initCordovaNotifications(onMessageReceived);
        }

        //aspetto di aver ottenuto il deviceId prima di scaricare il menu
        //in modo che la splash venga caricata solo dopo
        this.loadMenu();
      }));
    }
    else {
      //IL DEVICE ID NON VIENE PASSATO COSì
      //DA PASSARE
      Pk.init(this.defaultConfig);
      this.loadMenu();
    }

    const onMessageReceived = (message) => {
      PkLog.log("onMessageReceived", message);
      PkNotifications.getReceivedMessages().then(function (messages) {
        store.dispatch(setReceivedNotifications(messages));
      });
    }

    if (enablePushNotifications) {
      PkNotifications.getReceivedMessages().then(function (messages) {
        store.dispatch(setReceivedNotifications(messages));
      });
    }

    this.processingBackbuttonRequest = false;
    const self = this;
    document.addEventListener("backbutton", () => {

      const resetProcessingBackbuttonRequest = () => {
        setTimeout(() => {
          self.processingBackbuttonRequest = false;
        }, 100);
      }

      PkLog.log('backButton', 'back button pressed');

      //gestisco un semaforo perché questo componente è utilizzato da tutte le pagine, quindi questo evento viene scatenato n volte contemporaneamente
      //non posso fare diversamente perché l'oggetto router è accessibile solo dalle pagine dichiarate nel router, e questo mi impedisce di fare redirect in AppBoot, Landing o altre pagine centralizzate
      if (self.processingBackbuttonRequest === false) {
        self.processingBackbuttonRequest = true;

        if (f7.panel.get().opened) {
          resetProcessingBackbuttonRequest();
          f7.panel.close();
        }
        else if (f7.sheet.get()) {
          resetProcessingBackbuttonRequest();
          f7.sheet.get().close();
        }
        else if (f7.popup.get()) {
          resetProcessingBackbuttonRequest();
          f7.popup.get().close();
        }
        else if (document.getElementsByClassName("card-opened").length > 0) {
          resetProcessingBackbuttonRequest();
          f7.card.close();
        }
        else if (f7.views.main.router.url.includes(process.env.REACT_APP_DEFAULT_CODE_PAGE)) {
          resetProcessingBackbuttonRequest();
          navigator.app.exitApp();
        } else {
          resetProcessingBackbuttonRequest();
          self.props.f7router.back();
        }
        /*
               else if (!this.props.currentRoute.url.includes(simpleDefaultRoute)) {
                 this.props.back();
                 resetProcessingBackbuttonRequest();
               }
               else {
                 navigator.app.exitApp();
                 resetProcessingBackbuttonRequest();
               }*/
      }

    }, false);
  }
};

// #region Redux
const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Landing);