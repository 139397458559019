import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        add_from_gallery_fab_button_text: "Aggiungi dalla gallerya",
        adjust_tolerance_fab_button_text: "Regola la tolleranza",
        set_plate_name_fab_button_title: "Note",
        set_plate_name_fab_button_text: "Indica il nome del prodotto che stai per inquadrare (es. white calacatta)",
        change_camera_settings_fab_button_text: "Modifica il livello di esposizione",
        plate_name_not_valid: "Codice lastra non valido",
        change_resolution_more_text: "Aumenta la risoluzione",
        change_resolution_less_text: "Diminuisci la risoluzione",
        permission_request_text: "Devi concedere i permessi per poter avviare il riconoscimento",
        permission_request_title: "Permessi",
        loading_text: "Caricamento...",
        image_loading_text_title: "Prodotto non riconosciuto",
        image_loading_text_error: "Prodotto non riconosciuto. Cercalo nei Prodotti o riprova",
        error_dialog_cancel_button_text: "Riprova",
        error_dialog_redirect_button_text: "Prodotti",
        max_resolution_reached_text: "Sei già alla risoluzione massima",
        min_resolution_reached_text: "Sei già alla risoluzione minima",
        download_image_error_text: "Nessuna immagine da scaricare",
        upload_image_error_text: "Il file caricato non è un'immagine",
        upload_image_from_pc_error_text: "Fallito caricamento immagine",
        bad_quality_image_error: "L'immagine non ha una qualità abbastanza alta",
        opening_image_error_text: "Errore apertura immagine",
        sending_image_to_server_error_text: "Errore caricamento immagine, riprova",
        no_data_found_error: "Non sono stati trovati elementi relativi all'immagine scattata",
        slider_sheet_title_text: "Esposizione",
        slider_sheet_description_text: "Utilizza lo slider per modificare l'esposizione",
        change_mode_sheet_description_text: "Cambia la modalità di riconoscimento",
        mode_texture: "Texture",
        mode_color_1: "Colore 1",
        mode_color_2: "Colore 2",
        camera_manager_plugin_generic_error_text: "C'è stato un errore nell'elaborazione della foto",
        camera_manager_plugin_generic_error_title: "Errore",

        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Avvia la fotocamera",
        tutorial_1: "Inquadra la lastra. Se vuoi usa le opzioni per regolare la luminosità, abilitare il riconoscimento per colore e scrivere delle note su ciò che stai inquadrando",
        tutorial_2: "Scatta una foto e scopri le superfici più simili a ciò che hai inquadrato",

        search_in_the_catalog: "Non è il cosmetico che cercavi? Cercalo nei Prodotti",
        code_not_inserted: "Codice non inserito su database",
        familiy_title: "Famiglia",
        results_page_title: "RISULTATI",

        informative_tutorial_text: "Inquadra completamente il prodotto e scatta una foto",
    },
    it: {
        add_from_gallery_fab_button_text: "Aggiungi dalla gallerya",
        adjust_tolerance_fab_button_text: "Regola la tolleranza",
        set_plate_name_fab_button_title: "Note",
        set_plate_name_fab_button_text: "Indica il nome del prodotto che stai per inquadrare (es. white calacatta)",
        change_camera_settings_fab_button_text: "Modifica il livello di esposizione",
        plate_name_not_valid: "Codice lastra non valido",
        change_resolution_more_text: "Aumenta la risoluzione",
        change_resolution_less_text: "Diminuisci la risoluzione",
        permission_request_text: "Devi concedere i permessi per poter avviare il riconoscimento",
        permission_request_title: "Permessi",
        loading_text: "Caricamento...",
        image_loading_text_title: "Prodotto non riconosciuto",
        image_loading_text_error: "Prodotto non riconosciuto. Cercalo nei Prodotti o riprova",
        error_dialog_cancel_button_text: "Riprova",
        error_dialog_redirect_button_text: "Prodotti",
        max_resolution_reached_text: "Sei già alla risoluzione massima",
        min_resolution_reached_text: "Sei già alla risoluzione minima",
        download_image_error_text: "Nessuna immagine da scaricare",
        upload_image_error_text: "Il file caricato non è un'immagine",
        upload_image_from_pc_error_text: "Fallito caricamento immagine",
        bad_quality_image_error: "L'immagine non ha una qualità abbastanza alta",
        opening_image_error_text: "Errore apertura immagine",
        sending_image_to_server_error_text: "Errore caricamento immagine, riprova",
        no_data_found_error: "Non sono stati trovati elementi relativi all'immagine scattata",
        slider_sheet_title_text: "Esposizione",
        slider_sheet_description_text: "Utilizza lo slider per modificare l'esposizione",
        change_mode_sheet_description_text: "Cambia la modalità di riconoscimento",
        mode_texture: "Texture",
        mode_color_1: "Colore 1",
        mode_color_2: "Colore 2",
        camera_manager_plugin_generic_error_text: "C'è stato un errore nell'elaborazione della foto",
        camera_manager_plugin_generic_error_title: "Errore",

        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Avvia la fotocamera",
        tutorial_1: "Inquadra la lastra. Se vuoi usa le opzioni per regolare la luminosità, abilitare il riconoscimento per colore e scrivere delle note su ciò che stai inquadrando",
        tutorial_2: "Scatta una foto e scopri le superfici più simili a ciò che hai inquadrato",

        search_in_the_catalog: "Non è il cosmetico che cercavi? Cercalo nei Prodotti",
        code_not_inserted: "Codice non inserito su database",
        familiy_title: "Famiglia",
        results_page_title: "RISULTATI",

        informative_tutorial_text: "Inquadra completamente il prodotto e scatta una foto",


    }
});

export default localeStrings;