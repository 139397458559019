import { Configuration, OpenAIApi } from "openai";
import { Pk } from "../index";
import PkApiNet from "../api/net";

const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

export class OpenAiGenerate {
    static createChatCompletion = (prompt) => {
        return new Promise((resolve, reject) => {
            const model = "gpt-3.5-turbo";
            // const messages = JSON.parse('[{"role": "user", "content": "Hello!"}]');
            const messages = prompt;
            const temperature = 0.6;

            openai
                .createChatCompletion({
                    // model: "text-davinci-003",
                    model: model,
                    messages: messages,
                    temperature: temperature,
                })
                .then((response) => {
                    if (response.status === 200) {
                        resolve({
                            chatGptMessage: response.data.choices[0],
                            usage: response.data.usage,
                        });
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    static createAudioTranscription = (audio) => {
        return new Promise((resolve, reject) => {
            const model = "whisper-1";
            const temperature = 0.6;

            openai
                .createTranscription({
                    // model: "text-davinci-003",
                    file: audio,
                    model: model,
                    temperature: temperature,
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log("response audio", audio);
                        // resolve({
                        //   chatGptMessage: response.data.choices[0],
                        //   usage: response.data.usage,
                        // });
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    static retrieveFileContent = (fileId) => {
        return new Promise((resolve, reject) => {
            openai
                .downloadFile(fileId)
                .then((response) => {
                    console.log(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        });
        // https://api.openai.com/v1/files/{file_id}/content
    };
    static uploadFile = (fileId) => {
        return new Promise((resolve, reject) => {
            openai
                .createFile()
                .then((response) => {
                    console.log(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    };

    static createChatCompletionAxios = (axiosParams) => {
        return new Promise((resolve, reject) => {
            const prompt = axiosParams.prompt;
            const model = "gpt-3.5-turbo";
            const messages = '[{"role": "user", "content": "Hello!"}]';
            const temperature = 0.6;

            const bodyFormData = new FormData();
            bodyFormData.set("model", model);
            bodyFormData.set("messages", messages);
            bodyFormData.set("temperature", temperature);

            PkApiNet.callApi({
                method: "post",
                url: "https://api.openai.com/v1/chat/completions",
                data: bodyFormData,
                // config: {
                //   headers: {},
                // },
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };

    static createCmsChatCompletion = (prompt) => {
        return new Promise((resolve, reject) => {
            const model = "gpt-3.5-turbo";
            const messages = prompt;
            const temperature = 0.6;

            PkApiNet.callApi({
                method: 'post',
                url: Pk.getConfig().apiUrl + '/api/openai?customerCode=' + process.env.REACT_APP_CUSTOMER_CODE,
                url_path: '/api/openai',
                data: {
                    model: model,
                    messages: messages,
                    temperature: temperature
                },
                config: {
                    headers: {
                        'Content-Type': "application/json",
                        'OpenAI-url': 'https://api.openai.com/v1/chat/completions',
                        'OpenAI-method': 'POST',
                    }
                }
            })
                .then((response) => {
                    if (response.result.success === true) {
                        resolve({
                            chatGptMessage: JSON.parse(response.data).choices[0],
                            usage: response.data.usage,
                        });
                    } else {
                        reject(response.statusText);
                    }

                })
                .catch(function (response) {
                    reject(response);
                });
        });
    };
}

export default OpenAiGenerate;
