export class appActionTypes {
    static SET_ORIENTATION = 'SET_ORIENTATION';
    static SET_NOTIFICATION_SERVICE_ERROR = 'SET_NOTIFICATION_SERVICE_ERROR';
    static SET_MENU = 'SET_MENU';
    static SET_SKIPPED_AR_LOGO_TUTORIAL = 'SET_SKIPPED_AR_LOGO_TUTORIAL';
    static SET_SKIPPED_DISCOVER_TUTORIAL = 'SET_SKIPPED_DISCOVER_TUTORIAL';
    static SET_SKIPPED_AR_SCENE_TUTORIAL = 'SET_SKIPPED_AR_SCENE_TUTORIAL';
    static SET_SKIPPED_PLATE_SEARCH_TUTORIAL = 'SET_SKIPPED_PLATE_SEARCH_TUTORIAL';
    static SET_ACCEPTED_TERMS_OF_USE = 'SET_ACCEPTED_TERMS_OF_USE';
    static SET_RECEIVED_NOTIFICATIONS = 'SET_RECEIVED_NOTIFICATIONS';
    static SET_FAVORITE_CONTENTS = 'SET_FAVORITE_CONTENTS';
    static SET_IMAGE_CROPPING_COMPLETED = 'SET_IMAGE_CROPPING_COMPLETED';
    static SET_FOCUS_QUALITY_TOLERCANCE = 'SET_FOCUS_QUALITY_TOLERCANCE';
    static SET_CHOSEN_RESOLUTION_INDEX = 'SET_CHOSEN_RESOLUTION_INDEX';
    static SET_ACTIVITY_PAUSED = 'SET_ACTIVITY_PAUSED';
    static SET_CURRENT_FILTERED_CUSTOMER = 'SET_CURRENT_FILTERED_CUSTOMER';
    static SET_ONLY_PRIVATE_CONTENTS_VISIBLE = 'SET_ONLY_PRIVATE_CONTENTS_VISIBLE';
    static SET_SUB_MENU_TO_OPEN = 'SET_SUB_MENU_TO_OPEN';
    static SET_HAS_TO_REACTIVATE_CAMERA = 'SET_HAS_TO_REACTIVATE_CAMERA';
    static SET_WORKSTATIONS = 'SET_WORKSTATIONS';
    static SET_MENU_VISIBLE = 'SET_MENU_VISIBLE';
    static SET_ACTIVE_CODE_PAGE = 'SET_ACTIVE_CODE_PAGE';
    static SPLASH_REQUESTED_MESSAGE = "SPLASH_REQUESTED_MESSAGE";
    static IS_SPLASH_REQUEST_MESSAGING_PENDING = "IS_SPLASH_REQUEST_MESSAGING_PENDING";
}
export default appActionTypes;