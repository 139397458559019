import React from 'react';
import { connect } from 'react-redux';
import { f7, Block, Page, Navbar, PageContent } from 'framework7-react';
import Commons from '../../commons'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import appLocaleStrings from '../../app-local'
import ChecklistListItem from './checklist-list-item';

class ChecklistResultList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            checklistData: localStorage.getItem('checklist_results_list'),
        };
    }

    render() {
        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false}
                        showMenu={Commons.isSideMenuAllowed(f7)} />
                </Navbar>
                <div>
                    {this.getChecklists()}
                </div>

            </Page>
        )
    }

    getChecklists = () => {
        const checklistData = JSON.parse(this.state.checklistData);
        console.log('checklists', checklistData);
        if (checklistData === null ||
            checklistData === 0) {
            if (this.state.errorMessage) {
                return (
                    <Block className='error-block'>
                        {String(this.state.errorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }



        const ret = checklistData.map((item, index) => {
            return (
                <ChecklistListItem
                    checkListElement={item}
                    onCardClickHandler={(checklist) => {
                        this.onCardClick(checklist);
                    }}
                />
            )
        });

        return (
            <Block style={{ padding: '0', margin: '0' }}>
                {/* <div className="demo-expandable-cards">{ret}</div> */}
                <PageContent style={{ padding: '0' }}>{ret}</PageContent>
            </Block>)
    }

    onCardClick = (checklist) => {
        this.props.f7router.navigate('/checklist-result/', { props: { checklistResult: checklist }, animate: true });
    }

    componentDidMount = () => {
        if (!this.state.checklistData) {
            this.setState({ errorMessage: appLocaleStrings.no_contents })
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistResultList);