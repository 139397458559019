import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        tutorial_toggle_label: "Do not show again",
        tutorial_button_label: "Start",
        tutorial_1: "Point the camera at artifacs inside the museum",
        tutorial_2: "You will access exclusive digital content: videos, image galleries, information cards and much more ...!",
        tutorial_3: "Interact with the product and its added content",
        loading_text: "Loading scene",
        download_model_text: "Loading model",
        download_contents_text: "Loading contents",
        download_title: "Download",
        scene_loading_error_message: "Error loading scene",
        media_loading_error_message: "Error loading media",
        loading_model_complete: "Loading model 100 %",
        loading_model_percentage: "Loading model {0} %",
        loading_model_error: "Download model error",
        ar_error_title: "Error",
        ar_error_text: "The augmented reality section cannot be started, please try again later or contact technical support",
    },
    it: {
        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Avvia",
        tutorial_1: "Inquadra gli artefatti all'interno del museo",
        tutorial_2: "Accederai a contenuti digitali esclusivi: video, gallerie di immagini, schede informative e molto altro...!",
        tutorial_3: "Interagisci con il prodotto e i suoi contenuti aggiuntivi",
        loading_text: "Caricamento scena",
        download_model_text: "Caricamento modello",
        download_contents_text: "Caricamento contenuti",
        download_title: "Download",
        scene_loading_error_message: "Errore caricamento scena",
        media_loading_error_message: "Errore caricamento media",
        loading_model_complete: "Caricamento modello 100 %",
        loading_model_percentage: "Caricamento modello {0} %",
        loading_model_error: "Errore download modello",
        ar_error_title: "Errore",
        ar_error_text: "Non è possibile avviare la sezione di realtà aumentata, riprova più tardi o contatta il supporto tecnico",
    }
});

export default localeStrings;