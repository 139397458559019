import LocalizedStrings from "react-localization";

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    url_video: "video/gioconda_eng.mp4",
    preview_info: "Free Research Preview.",
    fake_bot_response:
      "Hello!\nWhat a pleasure to have you here, in OhMyGuide! , the first tourist guide that you can use all over the world, wherever you are, to discover and fully enjoy the places you visit!\nTo use TourGuide it is essential to activate the gps on your device. To do so, click on the link. Your position is only needed to give you the information on the right place, and will never be stored.\nEverything else is very easy!! really. Let's start!!\nCome on!!",
    entry_message: `
    Hello assistant! I want you to be my tour guide. I will write you my location and you will suggest me the best places (of the kind I will show you) to visit near my location based on what you know. The response should be a list of up to three items with only the names of the places. I'll give you a correct example of the answer to the question "I'm in Bologna, what are the best restaurants near me?": "Here is a list of the best restaurants near your location:
    1. I Portici restaurant Bologna
    2. Trattoria Da Me
    3. Valsellustra restaurant"
    Furthermore, the result you will provide must be formatted in HTML, putting the names of the places you will find inside <li></li> tags.
    Right now I'm at {1}, what are the best {0} I can go to?
    `,
    choice_message: `
    Now provide some additional information about {0}. Use a maximum of 130 words and, if I ask you, introduce yourself as Lisa
    `,
    after_reset: `
    Here I am, now you can ask me what you want about places near you or about anything else that interests you!
    `, // Here I am, now you can ask me what you want on {0} or anything else of interest to you.
    user_chat_message: `My position is {0}. {1}. Use a maximum of 130 words`,
    no_geolocation_entry_message: `
    Hi!
    In order to use OhMyGuide! you must turn on the gps on your device and allow it to be used for this app. I just need your location to give you more accurate information based on where you are.
    After enabling it on your device , please reload the page. Thank you.
    If you need help on how to do this please ask me.
    `,
    tourist_sites: "tourist sites",
    restaurants: "restaurants",
    hotels: "hotels",
    message_bar_placeholder: "Write a message",
    attachment_bar_text_placeholder: "Add a comment or send",
    is_typing: " is typing...",
    options_label: "Change subject",
    tourism_option_item: "Tourist sites",
    restaurants_option_item: "Restaurants",
    hotels_option_item: "Hotels",
    toast_message: "Processing request error",
    reset_label: "To ask me what you want click here!",
    info: "Info",
    disclaimer: "Disclaimer",
  },
  it: {
    url_video: "video/gioconda_ita.mp4",
    preview_info: "Free Research Preview.",
    fake_bot_response:
      "Ciao!\nChe piacere averti qui, in OhMyGuide! , la prima guida turistica che puoi usare in tutto il mondo, ovunque ti trovi, per scoprire e godere appieno dei luoghi che visiti!\nPer usare TourGuide è  indispensabile attivare il gps sul tuo dispositivo. Per farlo clicca sul link. La tua posizione ci serve solo per darti le informazioni sul luogo giusto, e non verrà mai memorizzata.\nTutto il resto e’ facilissimo!! davvero. Iniziamo!!\nDai!!",
    // entry_message: "Ciao assistente! quali sono i tre principali {0} di {1}",
    entry_message: `
    Ciao assistente! Voglio che tu mi faccia da guida turistica. Ti scriverò la mia posizione e tu mi suggerirai i migliori posti (del tipo che ti indicherò) da visitare vicino alla mia posizione in base a quella che è tua conoscenza.  La risposta dovrà essere un elenco con massimo tre elementi con solo i nomi dei posti. Ti fornisco un esempio corretto di risposta alla domanda "Mi trovo a Bologna, quali sono i migliori ristoranti vicini a me?":  "Ecco un elenco dei migliori ristoranti vicini alla tua posizione:
    1. Ristorante I Portici Bologna
    2. Trattoria Da Me
    3. Ristorante Valsellustra"
    Inoltre, il risultato che fornirai dovrà essere formattato in HTML, mettendo i nomi dei posti che troverai dentro dei tag <li></li>.
    In questo momento mi trovo a {1}, quali sono i migliori {0} in cui posso andare?
    `,
    choice_message: `
    Fornisci qualche informazione aggiuntiva su {0}. Usa non più di 130 parole.
    `,
    after_reset: `
    Eccomi, ora puoi chiedermi ciò che vuoi sui luoghi vicino a te oppure su altro di tuo interesse!
    `, // Eccomi, ora puoi chiedermi ciò che vuoi su {0} oppure su altro di tuo interesse.
    user_chat_message: `
    La mia posizione è {0}. {1}. Usa non più di 130 parole
    `,
    no_geolocation_entry_message: `
    Ciao!
    Per usare OhMyGuide! è indispensabile attivare il gps sul tuo dispositivo e consentirne l’uso per questa app. La tua posizione mi serve solo per darti informazioni più accurate in base a dove ti trovi.
    Dopo averla abilitata sul dispositivo , per favore ricarica la pagina. Grazie.
    Se ti serve aiuto su come fare chiedimi pure.
    `,
    tourist_sites: "siti turistici",
    restaurants: "ristoranti",
    hotels: "hotel",
    message_bar_placeholder: "Scrivi un messaggio",
    attachment_bar_text_placeholder: "Aggiungi un commento o invia",
    is_typing: " sta scrivendo...",
    options_label: "Cambia argomento",
    tourism_option_item: "Siti turistici",
    restaurants_option_item: "Ristoranti",
    hotels_option_item: "Alberghi",
    toast_message: "Errore nel processare la richiesta",
    reset_label: "Per chiedermi ciò che vuoi clicca qui!",
    info: "Info",
    disclaimer: "Disclaimer",
  },
});

export const mockData = {
  first_message: (subject) => ({
    "chatGptMessage": {
      "message": {
        "role": "assistant",
        "content": `Ecco un elenco dei migliori ${subject} vicini alla tua posizione:\n<ul>\n<li>Cattedrale di Reggio Emilia</li>\n<li>Palazzo dei Musei</li>\n<li>Basilica della Ghiara</li>\n<li>Teatro Municipale Romolo Valli</li>\n<li>Piazza Prampolini</li>\n</ul>`,
        "list": [
          "Cattedrale di Reggio Emilia",
          "Palazzo dei Musei",
          "Basilica della Ghiara",
          "Teatro Municipale Romolo Valli",
          "Piazza Prampolini"
        ]
      },
      "finish_reason": "stop",
      "index": 0
    },
    "usage": {
      "prompt_tokens": 268,
      "completion_tokens": 78,
      "total_tokens": 346
    }
  })
}

export default localeStrings;
