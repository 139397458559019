import React, { Fragment } from 'react';
import { SwiperSlide, Swiper, Toggle, Button } from 'framework7-react';
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';
import * as actions from '../redux/actions';

class PikkartTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const swiperSlides = this.props.slides.map((item, index) => {
            return (
                <SwiperSlide key={index}>
                    <div style={{ textAlign: 'center' }}>
                        <img src={item.image} alt={'tutorial-' + index + '-page'} className="swiper-slide-image" />
                    </div>
                    <div style={{ textAlign: 'center', margin: '15px' }}>
                        {item.text}
                    </div>
                </SwiperSlide>
            );
        });

        const getToggle = () => {
            if (this.props.showToggle) {
                // const checked = JSON.parse(localStorage.getItem('skip_ar_logo_tutorial')) !== null ? ;
                return (
                    <div style={{ textAlign: 'right' }}>
                        <span>{this.props.toggleText}</span>
                        <Toggle
                            // checked={JSON.parse(localStorage.getItem('skip_ar_logo_tutorial')) !== null ? JSON.parse(localStorage.getItem('skip_ar_logo_tutorial')) : null}
                            defaultChecked={JSON.parse(process.env.REACT_APP_START_WITH_TUTORIAL_ALREADY_CHECKED) && JSON.parse(localStorage.getItem('skip_ar_logo_tutorial')) === null}
                            onToggleChange={(event) => {
                                this.props.toggleClicked(event)
                            }}
                            init
                            style={{ margin: "10px" }} />
                    </div>
                )
            }
            else
                return null;
        };

        const getButton = () => {
            if (this.props.showButton)
                return (
                    <Button onClick={(e) => {
                        if (JSON.parse(process.env.REACT_APP_START_WITH_TUTORIAL_ALREADY_CHECKED) && JSON.parse(localStorage.getItem('skip_ar_logo_tutorial')) === null) {
                            this.props.toggleClicked(true)
                        }
                        this.props.buttonClicked()
                    }} className="start-ar-button" large fill> {this.props.buttonText}</Button >
                )
            else
                return null;
        };

        return (
            <Fragment>
                <Swiper
                    observer={true}
                    observeParents={true}
                    pagination
                    className='tutorial-pagination-swiper'
                // style={{ height: "75%" }}
                >
                    {swiperSlides}
                </Swiper>

                {getToggle()}

                {getButton()}
            </Fragment>
        );
    }

};

// #region Redux
const mapStateToProps = state => {
    return {
        skippedArLogoTutorial: state.app.skippedArLogoTutorial,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(PikkartTutorial);