import MediaServices from './media-services';

import {
  DEFAULT_SIZE_FACTOR,
  DEFAULT_IMAGE_COMPRESSION,
  DEFAULT_IMAGE_MIRROR
} from './constants';

import { PkCordova } from 'pikkart-cordova';

const DEFAULT_IMAGE_TYPE = MediaServices.IMAGE_TYPES.PNG;

class CameraPhoto {
  constructor() {
    this.videoElement = document.createElement("video");
    this.stream = null;
    this.numberOfMaxResolutionTry = 1;
    this.settings = null;

    // Set the right object depending on the browser.
    this.windowURL = MediaServices.getWindowURL();
    this.mediaDevices = MediaServices.getNavigatorMediaDevices();

    this.onStreamReady = null;
  }

  static getInstance() {
    if (CameraPhoto._cameraPhotoInstance === null) {
      CameraPhoto._cameraPhotoInstance = new CameraPhoto();
    }

    return CameraPhoto._cameraPhotoInstance;
  }

  static _cameraPhotoInstance = null;

  getVideoElement() {
    return this.videoElement;
  }

  _getStreamDevice(idealFacingMode, idealResolution) {
    return new Promise((resolve, reject) => {
      let constraints =
        MediaServices.getIdealConstraints(idealFacingMode, idealResolution);

      this.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          this._gotStream(stream);
          resolve(stream);
        })
        .catch((error) => {
          // let {name, constraint, message} = error;
          // window.alert(name + ' ' + constraint + ' ' + message);
          reject(error);
        });
    });
  }

  _getStreamDeviceMaxResolution(idealFacingMode) {



    const supportedConstraints = MediaServices.getNavigatorMediaDevices().getSupportedConstraints();
    console.log('supportedConstraints ', supportedConstraints.exposureMode + " " + supportedConstraints.whiteBalanceMode)
    let constraints = MediaServices.getMaxResolutionConstraints(idealFacingMode, this.numberOfMaxResolutionTry);

    // all the trying is done...
    if (constraints == null) {
      let idealResolution = {};
      return this._getStreamDevice(idealFacingMode, idealResolution);
    }

    return new Promise((resolve, reject) => {
      this.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          this._gotStream(stream);
          resolve(stream);
        })
        .catch((error) => {
          // let {name, constraint, message} = error;
          // console.log(name + ' ' + constraint + ' ' + message);
          // retry...
          setTimeout(() => {
            this.numberOfMaxResolutionTry += 1;
            this._getStreamDeviceMaxResolution(idealFacingMode)
              .catch(() => {
                reject(error);
              });
          }, 20);
        });
    });
  }

  _setVideoSrc(stream) {
    if ('srcObject' in this.videoElement) {
      this.videoElement.srcObject = stream;
    } else {
      // using URL.createObjectURL() as fallback for old browsers
      let videoSrc = this.windowURL.createObjectURL(stream);
      this.videoElement.src = videoSrc;
    }


    this.videoElement.setAttribute("class", "video");
    /*object-fit: cover;
    object-position: 50% 50%;
    width: 100vW;
    height: 100vh;*/

    this.videoElement.setAttribute('autoplay', '');
    this.videoElement.setAttribute('muted', '');
    // this.videoElement.setAttribute('playsinline', '');


    this.videoElement.setAttribute("playsinline", true);
    this.videoElement.setAttribute("controls", true);
    setTimeout(() => {
      this.videoElement.removeAttribute("controls");
    });


    this.videoElement.setAttribute('style', 'height: 100vh;');
  }

  _setSettings(stream) {

    // default setting is null
    this.settings = null;
    const tracks = (stream && stream.getTracks)
      ? stream.getTracks()
      : [];

    if (tracks.length > 0 && tracks[0].getSettings) {
      const track = tracks[0];
      this.settings = track.getSettings();
    }
  }

  _gotStream(stream) {
    this.stream = stream;
    this._setSettings(stream);
    this._setVideoSrc(stream);

    this._onStreamReady();
  }

  _onStreamReady() {
    if (this.onStreamReady) {
      this.onStreamReady();
    }
  }

  getCameraSettings() {
    return this.settings;
  }

  startCamera(idealFacingMode, idealResolution) {
    // stop the stream before playing it.
    return this.stopCamera()
      .then(() => { })
      .catch(() => { })
      // Always called (when the promise is done)
      .then(() => {
        return this._getStreamDevice(idealFacingMode, idealResolution);
      });
  }

  startCameraMaxResolution(idealFacingMode = {}) {
    // stop the stream before playing it.
    return this.stopCamera()
      .then(() => { })
      .catch(() => { })
      // Always called (when the promise is done)
      .then(() => {
        return this._getStreamDeviceMaxResolution(idealFacingMode);
      });
  }

  startCameraAtResolution = (idealFacingMode, idealResolution) => {
    return this.stopCamera()
      .then(() => { })
      .catch(() => { })
      // Always called (when the promise is done)
      .then(() => {
        return this._getStreamDevice(idealFacingMode, idealResolution);
      });
  }

  getDataUri(userConfig) {
    let config = {
      sizeFactor: userConfig.sizeFactor === undefined ? DEFAULT_SIZE_FACTOR : userConfig.sizeFactor,
      imageType: userConfig.imageType === undefined ? DEFAULT_IMAGE_TYPE : userConfig.imageType,
      imageCompression: userConfig.imageCompression === undefined ? DEFAULT_IMAGE_COMPRESSION : userConfig.imageCompression,
      isImageMirror: userConfig.isImageMirror === undefined ? DEFAULT_IMAGE_MIRROR : userConfig.isImageMirror,
      portionToGetWidth: userConfig.portionToGetWidth,
      portionToGetHeigth: userConfig.portionToGetHeight,
    };

    let dataUri = MediaServices.getDataUri(this.videoElement, config);
    return dataUri;
  }

  getFullResolutionDataUri(userConfig) {
    let config = {
      sizeFactor: userConfig.sizeFactor === undefined ? DEFAULT_SIZE_FACTOR : userConfig.sizeFactor,
      imageType: userConfig.imageType === undefined ? DEFAULT_IMAGE_TYPE : userConfig.imageType,
      imageCompression: userConfig.imageCompression === undefined ? DEFAULT_IMAGE_COMPRESSION : userConfig.imageCompression,
      isImageMirror: userConfig.isImageMirror === undefined ? DEFAULT_IMAGE_MIRROR : userConfig.isImageMirror,
      portionToGetWidth: userConfig.portionToGetWidth,
      portionToGetHeigth: userConfig.portionToGetHeight,
    };

    let dataUri = MediaServices.getFullResolutionDataUri(this.videoElement, config);
    return dataUri;
  }

  stopCamera() {
    return new Promise((resolve, reject) => {
      if (this.stream) {
        this.stream.getTracks().forEach(function (track) {
          track.stop();
        });
        this.videoElement.src = '';
        this.stream = null;
        this._setSettings(null);
        resolve();
      }
      reject(Error('no stream to stop!'));
    });
  }

  getCurrentInputDevices = () => {
    return new Promise((resolve, reject) => {
      try {
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            let video = [];
            let audio = [];
            devices.forEach(function (device) {
              let newDevice = { label: device.label, deviceId: device.deviceId, constraint: { deviceId: { exact: device.deviceId } } };
              if (device.kind === 'videoinput') {
                video.push(newDevice);
              }
              else if (device.kind === 'audioinput') {
                audio.push(newDevice);
              }
            });

            if (PkCordova.isCordova()) {
              video = [];
              video.push({
                label: 'Front', deviceId: 'user', constraint: {
                  facingMode: { exact: "user" }
                }
              });
              video.push({
                label: 'Back', deviceId: 'environment', constraint: {
                  facingMode: { exact: "environment" }
                }
              });
            }

            resolve({ video: video, audio: audio });

          })
          .catch(error => {
            reject(error);
          });

      } catch (error) {
        reject(new Error(error));
      }
    });
  }
}

export const FACING_MODES = MediaServices.FACING_MODES;
export const IMAGE_TYPES = MediaServices.IMAGE_TYPES;

export default CameraPhoto;
