export class MessageUtils {
  static people = [
    {
      name: "Lisa",
      avatar: "./icons/lisa_red_flag.png",
      // "https://www.innovup.net/wp-content/uploads/2022/08/pikkart_pmi_innovativa_innovup.jpg",
    },
    // {
    //   name: "Blue Ninja",
    //   avatar: "https://cdn.framework7.io/placeholder/people-100x100-7.jpg",
    // },
  ];

  static getBotPerson = () => {
    return MessageUtils.people[0];
  };
  //lista di persone nella chat per ora solo una (Ai4BOT)
  //ma la chat è già predisposta per essere di gruppo e
  //avere altri utenti all'interno
  static showTypingMessage = (show, showTypingMessageCallback) => {
    if (show) {
      const person = MessageUtils.people[0]; //pikkart
      showTypingMessageCallback({
        name: person.name,
        avatar: person.avatar,
      });
    } else {
      showTypingMessageCallback(null);
    }
  };
}

export default MessageUtils;
