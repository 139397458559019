import React, { useEffect } from 'react';
import styles from './ar-web.module.css';

function ArWebIntro(props) {
    const [isIntroVisible, setIsIntroVisible] = React.useState(true);
    const [isIntroFading, setIsIntroFading] = React.useState(false);

    useEffect(() => {
        if (props.planeFound) {
            setTimeout(() => {
                setIsIntroFading(true);
            }, 10);

            setTimeout(() => {
                setIsIntroVisible(false);
            }, 300);
        }
        else {
            setTimeout(() => {
                setIsIntroFading(false);
            }, 10);

            setTimeout(() => {
                setIsIntroVisible(true);
            }, 300);
        }
    }, [props.planeFound]);

    return (
        <div>
            {(isIntroVisible) &&
                <div className={`${styles.introContainer} ${(isIntroFading && styles.introFading)}`}>
                    <div className={styles.introIcon}>
                        <img src="./img/ar_resources/plane_search.png" alt='searching_plane' />
                    </div>
                </div>
            }
        </div>
    );
}

export default ArWebIntro;
