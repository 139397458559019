import React from 'react';
import { Link, Block, Card, BlockTitle } from 'framework7-react';
import MapView from '../content-views/content-elements/map-view';
import ContactList from '../content-views/content-elements/contact-list';
import ImageGallery from '../content-views/content-elements/image-gallery';

class InfoContentView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        const content = this.props.content;

        const shareLink = (content.googleUrl && content.googleUrl !== "" ?
            <Link
                className='material-icons'
                iconIos="material:share"
                iconMd="material:share"
                color='black'
                onClick={() => { this.props.shareContentHandler(content.googleUrl) }} /> :
            "");

        let contentDescr = "";
        contentDescr = content.descr.split("\n").map(function (item, idx) {
            return (
                <span key={idx}>
                    {item}
                    <br />
                </span>
            )
        })

        let note = "";

        note = content.note.split("\n").map(function (item, idx) {
            return (
                <div style={{ textAlign: 'left' }} key={idx}>
                    {item}
                    <br />
                </div>
            )
        })

        note = content.note ? (
            <div>
                <BlockTitle
                    className='note-field-card-title'>
                    {process.env.REACT_APP_NOTE_FIELD_TITLE}
                </BlockTitle>
                {note}
            </div>
        ) : "";

        note = content.note ? (
            <Card
                className='note-field-card'
                content={note} >
                {process.env.REACT_APP_NOTE_FIELD_ICON ?
                    <img
                        src={'./img/logo/' + process.env.REACT_APP_NOTE_FIELD_ICON}
                        alt="logo_pikkart"
                        width="18"
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            margin: '6px'
                        }}
                    />
                    : ""}
            </Card>) : "";

        const video = content.videoUrl ?
            this.getVideoPlayer(content) :
            "";

        return (
            <Block className='content-text-block' style={{ marginTop: 0 }}>

                <div className="video-container">

                    <div style={{ textAlign: 'right' }}>
                        {shareLink}
                    </div>
                </div>

                <div>
                    <p>{contentDescr}</p>

                    {video}

                    {note}

                    {((content.lat !== 0 && content.long !== 0) ? <MapView content={content} /> : null)}

                    <ContactList content={content} />

                </div>


                <ImageGallery
                    imageArray={content.imageUrls}
                    galleryTitle={this.props.galleryTitle} />
            </Block>
        )
    }

    getVideoPlayer = (content) => {
        return (
            <div className="video-container" style={{ marginTop: "0" }}>
                <div>
                    <video className="video-fullscreen" controls>
                        <source src={content.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {/* <div className="titolo-video">{content.title}</div> */}
                </div>
            </div>
        );
    };
};

export default InfoContentView;